export const ERROR_GET_STATIONS = {
  pt: "Erro ao buscar as estações",
  es: "Error al buscar las estaciones",
  en: "Error searching the stations",
};

export const ALL_RIGHT = {
  pt: "Tudo certo!",
  es: "¡Muy bien!",
  en: "All set!",
};

export const VISIBILITY_CREATED_SUCCESS = {
  pt: "Visibilidade criada com sucesso!",
  es: "¡Visibilidad creada con éxito!",
  en: "Visibility successfully created!",
};

export const WENT_WONG = {
  pt: "Algo deu errado!",
  es: "¡Algo salió mal!",
  en: "Something went wrong!",
};

export const NEW_VISIBILITY_GROUP = {
  pt: "Novo grupo de visibilidade",
  es: "Nuevo grupo de visibilidad",
  en: "New visibility group",
};

export const CREATE_VISIBILITY_GROUP = {
  pt: "Crie um grupo de visibilidade",
  es: "Crear un grupo de visibilidad",
  en: "Create a visibility group",
};

export const GROUP_NAME = {
  pt: "Nome do grupo",
  es: "Nombre del grupo",
  en: "Group name",
};

export const REQUIRED_FIELD = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};

export const GROUP_NAME_EX = {
  pt: "Ex.: Condomínio Abc",
  es: "p.ej.: Condominio Abc",
  en: "Example: Condominium Abc",
};

export const EMAIL_DOMAIN = {
  pt: "Domínio do email",
  es: "Dominio del correo electrónico",
  en: "Email domain",
};

export const EMAIL_EX = {
  pt: "Ex.: tupinambaenergia.com.br",
  es: "p.ej.: tupinambaenergia.com.br",
  en: "Example: tupinambaenergia.com.br",
};

export const STATIONS = {
  pt: "Estações",
  es: "Estaciones",
  en: "Stations",
};

export const CHARGING = {
  pt: "Carregando...",
  es: "Cargando...",
  en: "Charging...",
};

export const SELECT_STATIONS = {
  pt: "Selecione as estações",
  es: "Seleccione las estaciones",
  en: "Select the stations",
};

export const CANCEL = {
  pt: "Cancelar",
  es: "Cancelar",
  en: "Cancel",
};

export const SAVE = {
  pt: "Salvar",
  es: "Guardar",
  en: "Save",
};

export const ADMIN = {
  pt: "Admin",
  es: "Administración",
  en: "Admin",
};

export const GROUPS = {
  pt: "Grupos",
  es: "Grupos",
  en: "Groups",
};

export const LISTING_VISIBILITY_GROUP = {
  pt: "Listagem - Grupos de visibilidade",
  es: "Lista - Grupos de visibilidad",
  en: "Listing - Visibility groups",
};

export const SEARCH_BY_GROUP_NAME = {
  pt: "Pesquise pelo nome do grupo...",
  es: "Buscar por el nombre del grupo...",
  en: "Search by group name...",
};

export const SEARCH = {
  pt: "Pesquisar",
  es: "Buscar en",
  en: "Search",
};

export const NEW = {
  pt: "Novo",
  es: "Nuevo",
  en: "New",
};

export const CLEAR = {
  pt: "Limpar",
  es: "Borrar",
  en: "Clear",
};

export const FILTER = {
  pt: "Filtrar",
  es: "Filtrar",
  en: "Filter",
};

export const OOPS = {
  pt: "Ops...",
  es: "Epa...",
  en: "Ops...",
};

export const ERROR_GET_VISIBILITY_GROUPS = {
  pt: "Erro ao buscar grupos de visibilidade",
  es: "Error al buscar grupos de visibilidad",
  en: "Error searching visibility groups",
};

export const ONLY_CSV_ALLOWED = {
  pt: "Só é permitido arquivos .csv",
  es: "Sólo se permiten archivos .csv",
  en: "Only .csv files are allowed",
};

export const ERROR_EDIT_GROUPS = {
  pt: "Erro ao editar grupo!",
  es: "¡Error al editar el grupo!",
  en: "Error editing group!",
};

export const CLICK_OR_DRAG_HERE = {
  pt: "Clique ou arraste a planilha até aqui",
  es: "Haga clic o arrastre hasta aquí la hoja de cálculo",
  en: "Click or drag the spreadsheet here",
};

export const GROUP_EDITED_SUCCESS = {
  pt: "Grupo editado com sucesso!",
  es: "¡Grupo editado con éxito!",
  en: "Group successfully edited!",
};

export const GROUP = {
  pt: "Grupo",
  es: "Grupo",
  en: "Group",
};

export const EMAIL = {
  pt: "Email",
  es: "Correo electrónico",
  en: "Email",
};

export const ACTIONS = {
  pt: "Ações",
  es: "Acciones",
  en: "Actions",
};

export const EDIT = {
  pt: "Editar",
  es: "Editar",
  en: "Editar",
};

export const DELETE = {
  pt: "Deletar",
  es: "Borrar",
  en: "Delete",
};

export const CONFIRM_DELETE = {
  pt: "Deseja realmente DELETAR?",
  es: "¿Realmente quieres BORRAR?",
  en: "Do you really want to DELETE?",
};

export const DELETE_ALL_USERS = {
  pt: "Deletar todos usuários",
  es: "Borrar todos los usuarios",
  en: "Delete all users",
};

export const NAME = {
  pt: "Nome",
  es: "Nombre",
  en: "Name",
};

export const YES = {
  pt: "Sim",
  es: "Sí",
  en: "Yes",
};

export const FROM = {
  pt: "Origem",
  es: "Fuente",
  en: "Origin",
};

export const NO = {
  pt: "Não",
  es: "No",
  en: "No",
};

export const ERROR_ADD_USERS = {
  pt: "Error ao tentar adicionar usuários!",
  es: "¡Error al intentar agregar usuarios!",
  en: "Error trying to add users!",
};

export const USER_ADD = {
  pt: "Usuário adicionado!",
  es: "¡Usuario añadido!",
  en: "User added!",
};

export const USERS_ALREADY_VISIBILITY = {
  pt: "Usuario já tem visibilidade ou não encontrado",
  es: "El usuario ya tiene visibilidad o no se encuentra",
  en: "User already with visibility or not found",
};

export const USER_VISIBILITY_DELETED = {
  pt: "Visibilidade do usuario deletada!",
  es: "¡Visibilidad de los usuarios borrada!",
  en: "User's visibility deleted!",
};

export const ERROR = {
  pt: "Erro!",
  es: "¡Error!",
  en: "Error!",
};

export const ALL_USER_VISIBILITY_DELETED = {
  pt: "Visibilidade de todos usuários foram deletadas!",
  es: "Se han borrado la visibilidad de todos los usuarios!",
  en: "Visibility of all users was deleted!",
};

export const EDIT_GROUP = {
  pt: "Editar Grupo",
  es: "Editar Grupo",
  en: "Edit Group",
};

export const ADD_BY_DOMAIN = {
  pt: "Adicionar por domínio",
  es: "Añadir por dominio",
  en: "Add by domain",
};

export const ADD_USER_BY_QRCODE = {
  pt: "Adicionar usuario ao iniciar carga por QRcode?",
  es: "¿Agregar un usuario al iniciar carga por QRcode?",
  en: "Add user when starting the charge by QRcode?",
};

export const METHOD_ADD_USERS = {
  pt: "Método de adicionar usuários",
  es: "Añadir método de usuario",
  en: "Method of adding users",
};

export const SHEET = {
  pt: "Planilha",
  es: "Hoja de cálculo",
  en: "Spreadsheet",
};

export const SELECT_SHEET = {
  pt: "Selecionar planilha",
  es: "Seleccionar la hoja de cálculo",
  en: "Select spreadsheet",
};

export const CSV_FILE = {
  pt: "listaUsuariosNaoEncontrados.csv",
  es: "listaDeUsuariosNoEncontrados.csv",
  en: "listUsersNotFound.csv",
};

export const ADD_EMAILS_FOUNDED = {
  pt: "Adicionar emails encontrados",
  es: "Añadir correo electrónico encontrado",
  en: "Add found emails",
};

export const ADD_EMAIL = {
  pt: "Adicionar email",
  es: "Añadir correo electrónico",
  en: "Add email",
};

export const DOWNLOAD_CSV_FILE = {
  pt: "Baixa em excel Usuários não encontrados",
  es: "Descarga en excel Usuarios no encontrados",
  en: "Download in excel Users not found",
};

export const OCPI_APPS = {
  pt: 'Aplicativos (usado apenas para OCPI)',
  es: 'Aplicaciones (solo para OCPI)',
  en: 'Apps (used only for OCPI)',
}

export const ADD_OCPI_APP = {
  pt: 'Adicionar (vazio permite para todos)',
  es: 'Añadir (vacío permite para todas)',
  en: 'Add (empty allows all apps)',
}

export const OCPI_PARTNERS = {
  pt: 'Parceiros OCPI',
  es: 'Socios OCPI',
  en: 'OCPI partners',
}

export const ADD_OCPI_PARTNER = {
  pt: 'Adicionar parceiro OCPI...',
  es: 'Añadir socio OCPI...',
  en: 'Add OCPI partner...',
}

export const MORE_STATIONS = (number) => ({
  pt: `... mais ${number} estações`,
  es: `... más estaciones ${number}`,
  en: `... more ${number} stations`,
});

export const USERS_VISIBILITY_ADD_RESPONSE = (found, adds) => ({
  pt: `${found} Usuários encontrados / ${adds} Usuário adicionados`,
  es: `${found} Usuarios encontrados / ${adds} Usuarios añadidos`,
  en: `${found} Users found / ${adds} Users added`,
});

export const USERS_FOUND = (number) => ({
  pt: `${number} Usuários encontrados`,
  es: `${number} Usuarios encontrados`,
  en: `${number} Users found`,
});

export const USERS_NOT_FOUND = (number) => ({
  pt: `${number} Usuários não encontrados`,
  es: `${number} Usuarios no encontrados`,
  en: `${number} Users not found`,
});

export const USERS_ADDS = (number) => ({
  pt: `${number} Usuários adicionados`,
  es: `${number} Usuarios añadidos`,
  en: `${number} Users added`,
});

export const EMAILS_FOUND = (number) => ({
  pt: `${number} Emails encontrados`,
  es: `${number} Correos electrónicos encontrados`,
  en: `${number} Emails found`,
});

export const SELECT_METOD = {
  pt: "Selecione um metodo",
};

export const VISIBLE_TO_EVERYONE = {
  pt: "Visível para todos (usado apenas para OCPI)",
  es: "Visible para todos (solo para OCPI)",
  en: "Visible to everyone (used only for OCPI)",
}