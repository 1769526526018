import React from "react";

export const Discount = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.48334 21.9907L4.20336 19.7106C3.27336 18.7806 3.27336 17.2506 4.20336 16.3206L6.48334 14.0406C6.87334 13.6506 7.18834 12.8856 7.18834 12.3456V9.12053C7.18834 7.80053 8.26835 6.72057 9.58835 6.72057H12.8133C13.3533 6.72057 14.1184 6.40562 14.5084 6.01562L16.7883 3.73559C17.7183 2.80559 19.2484 2.80559 20.1784 3.73559L22.4584 6.01562C22.8484 6.40562 23.6133 6.72057 24.1533 6.72057H27.3784C28.6984 6.72057 29.7783 7.80053 29.7783 9.12053V12.3456C29.7783 12.8856 30.0933 13.6506 30.4833 14.0406L32.7634 16.3206C33.6934 17.2506 33.6934 18.7806 32.7634 19.7106L30.4833 21.9907C30.0933 22.3807 29.7783 23.1457 29.7783 23.6857V26.9105C29.7783 28.2305 28.6984 29.3107 27.3784 29.3107H24.1533C23.6133 29.3107 22.8484 29.6256 22.4584 30.0156L20.1784 32.2956C19.2484 33.2256 17.7183 33.2256 16.7883 32.2956L14.5084 30.0156C14.1184 29.6256 13.3533 29.3107 12.8133 29.3107H9.58835C8.26835 29.3107 7.18834 28.2305 7.18834 26.9105V23.6857C7.18834 23.1307 6.87334 22.3657 6.48334 21.9907Z"
      stroke="currentColor"
      strokeWidth="2.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 22.5L23 13.5"
      stroke="currentColor"
      strokeWidth="2.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.2418 21.75H22.2552"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7418 14.25H14.7552"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
