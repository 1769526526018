const MenuConfig = (matchUrl) => {
  return [
    {
      type: "submenu",
      permission: "menu-stations",
      key: "stations",
      icon: "environment",
      title: "MENU_STATION",
      submenus: [
        {
          permission: "menu-stations-not-connected",
          key: "list_stations",
          linkTo: `${matchUrl}/list_stations`,
          icon: "unordered-list",
          title: "LISTING",
        },
        {
          permission: "menu-stations-connected",
          key: "connected_stations",
          linkTo: `${matchUrl}/connected_stations`,
          icon: "global",
          title: "CONNECTED",
        },
        {
          permission: "menu-locations",
          key: "list_locations",
          linkTo: `${matchUrl}/list_locations`,
          icon: "compass",
          title: "MENU_LOCATION",
        },
        {
          permission: "menu-stations-audit",
          key: "audit_stations",
          linkTo: `${matchUrl}/audit_stations`,
          icon: "audit",
          title: "AUDIT",
        },
        {
          permission: "batch-operation",
          key: "batch-operation",
          linkTo: `${matchUrl}/batch-operation`,
          icon: "file-done",
          title: "BATCH_OPERATION",
        },
        {
          permission: "cpms-view-monitoring",
          key: "cpms-monitoring",
          redirect: `${process.env.REACT_APP_CPMS_URL}/stations/monitoring`,
          title: "MENU_CPMS_MONITORING",
          tag: "BETA",
        },
      ],
    },
    {
      type: "menu",
      permission: "menu-visibility",
      key: "visibility",
      icon: "eye",
      title: "VISIBILITY",
      linkTo: `${matchUrl}/visibility_group`,
    },
    {
      type: "submenu",
      permission: "menu-dashboard",
      key: "dashboard",
      icon: "bar-chart",
      title: "DASHBOARD",
      submenus: [
        {
          permission: "menu-dashboard",
          key: "dashboard",
          linkTo: `/dashboard`,
          icon: "bar-chart",
          title: "DASHBOARD",
        },
        {
          permission: "cpms-view-overview",
          key: "cpms-overview",
          redirect: `${process.env.REACT_APP_CPMS_URL}/overview`,
          title: "MENU_CPMS_OVERVIEW",
          tag: "BETA",
        },
      ],
    },
    {
      type: "menu",
      permission: "menu-users",
      key: "users",
      linkTo: `${matchUrl}/users`,
      icon: "user",
      title: "USERS",
    },
    {
      type: "menu",
      permission: "menu-advertising",
      key: "advertising",
      linkTo: `${matchUrl}/advertising`,
      icon: "shop",
      title: "ADVERTISING",
    },
    {
      type: "submenu",
      permission: "menu-admin",
      key: "admin",
      icon: "lock",
      title: "MANAGEMENT",
      submenus: [
        {
          permission: "menu-admin-users",
          key: "admin_users",
          linkTo: `${matchUrl}/admin_users`,
          icon: "user",
          title: "USERS",
        },
        {
          permission: "menu-admin-companies",
          key: "companys",
          linkTo: `${matchUrl}/companys`,
          icon: "bank",
          title: "COMPANIES",
        },
        {
          permission: "menu-admin-recipients",
          key: "recipients",
          linkTo: `${matchUrl}/recipients`,
          icon: "download",
          title: "RECIPIENTS",
        },
        {
          permission: "menu-admin-plugs",
          key: "plugs",
          linkTo: `${matchUrl}/plugs`,
          icon: "api",
          title: "PLUGS",
        },
        {
          permission: "menu-admin-okr-powerbi",
          key: "okr-powerbi",
          linkTo: `${matchUrl}/okr-powerbi`,
          icon: "bar-chart",
          title: "OKR_POWER_BI",
        },
        {
          permission: "menu-admin-permissions",
          key: "permission_groups",
          linkTo: `${matchUrl}/permission_groups`,
          icon: "bar-chart",
          title: "PERMISSIONS",
        },
        {
          permission: "cpms-view-financial",
          key: "cpms-financial",
          redirect: `${process.env.REACT_APP_CPMS_URL}/financial/summary`,
          title: "MENU_CPMS_FINANCIAL",
          tag: "BETA",
        },
      ],
    },
    {
      type: "menu",
      permission: "menu-reports",
      key: "report",
      icon: "file-sync",
      title: "REPORTS",
      linkTo: `${matchUrl}/report_groups`,
    },
    {
      type: "submenu",
      permission: "menu-histories",
      key: "history",
      icon: "history",
      title: "HISTORIES",
      submenus: [
        {
          permission: "menu-histories-charges",
          key: "charges_history",
          linkTo: `${matchUrl}/charges_history`,
          icon: "thunderbolt",
          title: "CHARGES",
        },
        {
          permission: "menu-histories-payments",
          key: "payment_history",
          linkTo: `${matchUrl}/payment_history`,
          icon: "dollar",
          title: "PAYMENTS",
        },
      ],
    },
    {
      type: "menu",
      permission: "menu-coupons",
      key: "coupons",
      linkTo: `${matchUrl}/coupons`,
      icon: "gift",
      title: "COUPONS",
    },
    {
      type: "menu",
      permission: "menu-tags-rfid",
      key: "rfid-campaigns",
      linkTo: `${matchUrl}/rfid-campaigns`,
      icon: "tags",
      title: "RFID",
    },
    {
      type: "submenu",
      permission: "menu-availability",
      key: "availability",
      icon: "clock-circle",
      title: "AVAILABILITY",
      submenus: [
        {
          permission: "menu-stations-availability",
          key: "availability_stations",
          linkTo: `${matchUrl}/availability_stations`,
          icon: "cloud-sync",
          title: "MENU_STATION_AVAILABILITY_24H",
        },
        {
          permission: "menu-stations-availability",
          key: "availability_stations_real",
          linkTo: `${matchUrl}/availability_stations_real`,
          icon: "cloud",
          title: "MENU_STATION_AVAILABILITY_REAL",
        },
        {
          permission: "menu-system-availability",
          key: "availability_system",
          linkTo: `${matchUrl}/availability_system`,
          icon: "cloud-server",
          title: "SYSTEM",
        },
      ],
    },
    {
      type: "submenu",
      permission: "menu-ocpp",
      key: "ocpp",
      icon: "api",
      title: "OCPP",
      submenus: [
        {
          permission: "menu-ocpp-stations",
          key: "stations",
          linkTo: `${matchUrl}/ocpp/stations`,
          icon: "environment",
          title: "MENU_STATION",
        },
        {
          permission: "menu-ocpp-charges",
          key: "transactions",
          linkTo: `${matchUrl}/ocpp/transactions`,
          icon: "thunderbolt",
          title: "CHARGES",
        },
        {
          permission: "menu-ocpp-operations",
          key: "actions",
          linkTo: `${matchUrl}/ocpp/actions`,
          icon: "control",
          title: "OPERATION",
        },
        {
          permission: "menu-ocpp-alert-groups",
          key: "alert_groups",
          linkTo: `${matchUrl}/ocpp/alert_groups`,
          icon: "alert",
          title: "ALERTS",
        },
        {
          permission: "menu-ocpp-tags",
          key: "tags",
          linkTo: `${matchUrl}/ocpp/tags`,
          icon: "tags",
          title: "TAGS",
        },
        {
          permission: "menu-ocpp-logs",
          key: "logs",
          linkTo: `${matchUrl}/ocpp/logs`,
          icon: "unordered-list",
          title: "LOGS",
        },
      ],
    },
    {
      type: "menu",
      permission: "menu-docs",
      key: "menu-docs",
      linkTo: `${matchUrl}/docs`,
      icon: "file-text",
      title: "MENU_DOCS",
    },
    {
      type: "menu",
      permission: "menu-proposals",
      key: "menu-proposal",
      linkTo: `${matchUrl}/proposal_list`,
      icon: "file",
      title: "PROPOSAL",
    },
  ];
};
export default MenuConfig;
