import React, { useContext } from "react";
import { Button, Col, Form, Icon, Input, InputNumber, Row, Switch } from "antd";
import { FreireContext } from "../../utils/freireContext";
import {
  ACTIVATE_ACTIVATION_FEE,
  BUTTON_KEEP,
  BUTTON_CHANGE,
  YES,
  NO,
  ACTIVATION_FEE_KWH_VALUE_EXEMPTION,
  ACTIVATION_FEE_CHARGE_VALUE_EXEMPTION,
  ACTIVATION_FEE_VALUE_WITH_CURRENCY,
  CONSUMPTION_KWH_LABEL,
  EXEMPT_ACTIVATION_FEE_BY_CONSUMPTION,
  EXEMPT_ACTIVATION_FEE_BY_CONSUMPTION_TOOLTIP,
  WARNING_ACTIVATION_FEE_BATCH_OPERATION,
} from "../../locales";
import { FormComponentProps } from "antd/lib/form";
import { formatarMoeda } from "../FormatingUtils";
import TextToolTip from "../TextToolTip";

const enabled_activation_fee: TActivationFeeSettings = {
  activation_fee: {
    enabled: true,
    value: 0,
    exemption_time_limit_seconds: 0,
    exemption_lower_charge_value: 100,
    exemption_lower_stop_value: 1,
    exemption_by_consumption_enabled: false,
    exemption_by_consumption_value: 0,
  },
};

export const disabled_activation_fee: TActivationFeeSettings = {
  activation_fee: {
    enabled: false,
    value: 0,
    exemption_time_limit_seconds: 0,
    exemption_lower_charge_value: 100,
    exemption_lower_stop_value: 1,
    exemption_by_consumption_enabled: false,
    exemption_by_consumption_value: 0,
  },
};

type TActivationFee = {
  enabled: boolean;
  value: number;
  exemption_time_limit_seconds: number;
  exemption_lower_charge_value: number;
  exemption_lower_stop_value: number;
  exemption_by_consumption_enabled: boolean;
  exemption_by_consumption_value: number;
};

type TActivationFeeSettings = {
  activation_fee: TActivationFee;
};

type TActivationFeeProps = {
  form: FormComponentProps["form"];
  loading: boolean;
  bacthOperation?: boolean;
  selectedCompanyCurrency: string;
  activation_fee: TActivationFee;
  keepActivationFeeValue: boolean;
  handleActivationFeeChange: (keep: boolean) => void;
};
export const ActivationFee = ({
  form,
  loading,
  bacthOperation = false,
  selectedCompanyCurrency,
  activation_fee,
  keepActivationFeeValue = true,
  handleActivationFeeChange,
}: TActivationFeeProps) => {
  const { freire } = useContext(FreireContext);
  const { getFieldDecorator, getFieldValue } = form;

  const isActivationFeeEnabled = getFieldValue("activation_fee.enabled");
  const isExemptionByConsumptionEnabled = getFieldValue(
    "activation_fee.exemption_by_consumption_enabled"
  );

  return (
    <div className={bacthOperation ? "margin-container-style" : ""}>
      {!keepActivationFeeValue && (
        <span className="update-confirm">
          <Icon
            type="warning"
            style={{
              color: "#FCD429",
              fontSize: 20,
              paddingLeft: 5,
            }}
          />
          {freire(WARNING_ACTIVATION_FEE_BATCH_OPERATION)}
        </span>
      )}
      <div className={bacthOperation ? "container-contents" : ""}>
        <span style={{ color: "#434341", fontWeight: "bold" }}>
          {freire(ACTIVATE_ACTIVATION_FEE)}
        </span>
        {bacthOperation && (
          <div className="keep-btn-change-btn">
            <Button
              type={keepActivationFeeValue ? "primary" : "default"}
              style={{
                fontWeight: "bold",
                color: keepActivationFeeValue ? "#FFFFFF" : "#747277",
              }}
              onClick={() => handleActivationFeeChange(true)}
            >
              {freire(BUTTON_KEEP)}
            </Button>
            <Button
              type={!keepActivationFeeValue ? "primary" : "default"}
              style={{
                fontWeight: "bold",
                color: !keepActivationFeeValue ? "#FFFFFF" : "#747277",
              }}
              onClick={() => handleActivationFeeChange(false)}
            >
              {freire(BUTTON_CHANGE)}
            </Button>
          </div>
        )}
      </div>
      <div
        style={
          bacthOperation
            ? {
                display: !keepActivationFeeValue ? "block" : "none",
              }
            : {}
        }
      >
        <Row gutter={14} style={{ marginTop: 8 }}>
          <Col span={8}>
            <Form.Item>
              {getFieldDecorator("activation_fee.enabled", {
                valuePropName: "checked",
              })(
                <Switch
                  disabled={loading}
                  checkedChildren={freire(YES)}
                  unCheckedChildren={freire(NO)}
                  onChange={(event) => {
                    if (!event) {
                      form.setFieldsValue(disabled_activation_fee);
                    } else {
                      form.setFieldsValue(enabled_activation_fee);
                    }
                  }}
                />
              )}
              <span style={{ marginLeft: 10 }}>
                {freire(ACTIVATE_ACTIVATION_FEE)}
              </span>
            </Form.Item>
          </Col>
          <Col
            span={6}
            style={activation_fee?.enabled ? {} : { display: "none" }}
          >
            <Form.Item
              label={freire(
                ACTIVATION_FEE_VALUE_WITH_CURRENCY(selectedCompanyCurrency)
              )}
            >
              {getFieldDecorator("activation_fee.value", {
                normalize: (input: string) => {
                  return input ? formatarMoeda(input) : "0,00";
                },
              })(<Input disabled={loading} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row style={isActivationFeeEnabled ? {} : { display: "none" }}>
          <Col span={14}>
            <Form.Item label={freire(ACTIVATION_FEE_KWH_VALUE_EXEMPTION)}>
              {getFieldDecorator("activation_fee.exemption_lower_stop_value")(
                <InputNumber disabled={loading} />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row style={isActivationFeeEnabled ? {} : { display: "none" }}>
          <Col span={12}>
            <Form.Item
              label={freire(
                ACTIVATION_FEE_CHARGE_VALUE_EXEMPTION(selectedCompanyCurrency)
              )}
            >
              <Col span={5}>
                {getFieldDecorator(
                  "activation_fee.exemption_lower_charge_value",
                  {
                    normalize: (input: string) => {
                      return input && formatarMoeda(String(input));
                    },
                  }
                )(<Input disabled={loading} />)}
              </Col>
            </Form.Item>
          </Col>
          <Row style={{ display: "none" }}>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator(
                  "activation_fee.exemption_time_limit_seconds"
                )(<InputNumber disabled={loading} />)}
              </Form.Item>
            </Col>
          </Row>
        </Row>
        <Row style={isActivationFeeEnabled ? {} : { display: "none" }}>
          <Col span={10}>
            <Form.Item>
              {getFieldDecorator(
                "activation_fee.exemption_by_consumption_enabled",
                {
                  valuePropName: "checked",
                }
              )(
                <Switch
                  disabled={loading}
                  checkedChildren={freire(YES)}
                  unCheckedChildren={freire(NO)}
                  onChange={(value) => {
                    if (!value) {
                      form.setFieldsValue({
                        activation_fee: {
                          ...activation_fee,
                          exemption_by_consumption_enabled: false,
                          exemption_by_consumption_value: 0,
                        },
                      });
                    }
                  }}
                />
              )}
              <span style={{ marginLeft: 10 }}>
                {freire(EXEMPT_ACTIVATION_FEE_BY_CONSUMPTION)}
              </span>
              <TextToolTip
                icon="question-circle-o"
                title={freire(EXEMPT_ACTIVATION_FEE_BY_CONSUMPTION)}
                info={[
                  {
                    info: freire(EXEMPT_ACTIVATION_FEE_BY_CONSUMPTION_TOOLTIP),
                  },
                ]}
              />
            </Form.Item>
          </Col>

          <Col
            span={6}
            style={isExemptionByConsumptionEnabled ? {} : { display: "none" }}
          >
            <Form.Item label={freire(CONSUMPTION_KWH_LABEL)}>
              {getFieldDecorator(
                "activation_fee.exemption_by_consumption_value"
              )(<InputNumber placeholder="0" min={0} />)}
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  );
};
