export const NEW_LOCATION = {
  pt: "Nova Localização",
  es: "Nueva Ubicación",
  en: "New Location",
};

export const ADMIN = {
  pt: "Admin",
  es: "Admin",
  en: "Admin",
};

export const LOCATIONS = {
  pt: "Locais",
  es: "Lugares",
  en: "Locations",
};

export const LISTING = {
  pt: "Listagem",
  es: "Listado",
  en: "Listing",
};

export const NAME = {
  pt: "Nome",
  es: "Nombre",
  en: "Name",
};

export const ADDRESS = {
  pt: "Endereço",
  es: "Dirección",
  en: "Address",
};

export const COUNTRY = {
  pt: "País",
  es: "País",
  en: "Country",
};

export const COMPANY = {
  pt: "Empresa",
  es: "Empresa",
  en: "Company",
};

export const OPTIONS = {
  pt: "Opções",
  es: "Opiciones",
  in: "Options",
};

export const DELETE = {
  pt: "Deletar",
  es: "Borrar",
  en: "Delete",
};

export const OOPS = {
  pt: "Ops...",
  es: "Epa...",
  en: "Ops...",
};

export const ERROR_GET_LOCATION = {
  pt: "Não foi possível carregar as informações atualizadas do local",
  es: "No fue posible cargar los datos actualizados del lugar",
  en: "It was not possible to load the updated location data",
};

export const ERROR_LIST_COMPANIES = {
  pt: "Não foi possível carregar a lista de empresas",
  es: "No fue posible cargar la lista de empresas",
  en: "It was not possible to load the list of companies",
};

export const ERROR_LIST_COUNTRIES = {
  pt: "Não foi possível carregar a lista de países",
  es: "No fue posible cargar la lista de paises",
  en: "It was not possible to load the list of countries",
};

export const ERROR_LIST_LOCATIONS = {
  pt: "Não foi possível carregar a lista de locais",
  es: "No fue posible cargar la lista de lugares",
  en: "It was not possible to load the list of locations",
};

export const SEARCH_BY_NAME = {
  pt: "Pesquise pelo nome",
  es: "Buscar por nombre",
  en: "Search by name",
};

export const SELECT_COMPANY = {
  pt: "Selecione a empresa",
  es: "Seleccione la empresa",
  en: "Select company",
};

export const REQUIRED_FIELD = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};

export const ADD_LOCATION = {
  pt: "Adicionar Local",
  es: "Agregar Lugar",
  en: "Add Location",
};

export const LATITUDE = {
  pt: "Latitude",
  es: "Latitud",
  en: "Latitude",
};

export const LONGITUDE = {
  pt: "Longitude",
  es: "Longitud",
  en: "Longitude",
};

export const LATITUDE_EX = {
  pt: "Ex.: -00.0000",
  es: "p.ej.: -00.0000",
  en: "Example: -00.0000",
};

export const PHONE = {
  pt: "Telefone",
  es: "Teléfono",
  en: "Phone",
};

export const SAVE = {
  pt: "Salvar",
  es: "Guardar",
  en: "Save",
};

export const WENT_WRONG = {
  pt: "Algo deu errado!",
  es: "¡Algo ha salido mal!",
  en: "Something went wrong!",
};

export const ALL_RIGHT = {
  pt: "Tudo certo!",
  es: "¡De acuerdo!",
  en: "All right!",
};

export const LOCATION_ADD_SUCCESS = {
  pt: "Local adicionado com sucesso!",
  es: "¡Lugar añadido con éxito!",
  en: "Location added successfully!",
};

export const PRIVATE_LOCATION_QUESTION = {
  pt: "Localização privada",
  es: "Ubicación privada",
  en: "Private location",
};

export const YES = {
  pt: "Sim",
  es: "SÍ",
  en: "Yes",
};

export const NO = {
  pt: "Não",
  es: "No",
  en: "No",
};

export const FREE_PARKING_QUESTION = {
  pt: "Estacionamento Grátis",
  es: "Estacionamiento Gratis",
  en: "Free parking",
};

export const SELECT_COMPANY_WARN = {
  pt: "Só será possível selecionar empresas que estão no país já selecionado",
  en: "Only companies from the selected country can be selected",
  es: "Solo será posible seleccionar empresas que estén en el país ya seleccionado",
};

export const STATIONS = {
  pt: "Estações",
  es: "Estaciones",
  en: "Station",
};

export const ACTIONS = {
  pt: "Ações",
  es: "Acciones",
  en: "Actions",
};

export const SEE_STATIONS = {
  pt: "Ver Estações",
  es: "Ver Estaciones",
  en: "See Stations",
};

export const EDIT_LOCATION = {
  pt: "Editar Local",
  es: "Editar Lugar",
  en: "Edit Location",
};

export const DELETE_LOCATION = {
  pt: "Apagar Local",
  es: "Eliminar Lugar",
  en: "Delete Location",
};

export const STATIONS_LIST_TITLE = {
  pt: "Estações vinculadas ao local",
  es: "Estaciones vinculadas al lugar",
  en: "Stations linked to the location",
};

export const CANCEL = {
  pt: "Cancelar",
  es: "Cancelar",
  en: "Cancel",
};

export const LOCATION_EDITED_SUCCESS = {
  pt: "Local editado com sucesso!",
  es: "¡Lugar editado con éxito!",
  en: "Location edited successfully!",
};

export const ERROR_EDIT_LOCATION = {
  pt: "Erro ao editar local",
  es: "Error al editar el lugar",
  en: "Error editing location",
};

export const LOCATION_DELETED = {
  pt: "Local apagado.",
  es: "Lugar eliminado.",
  en: "Location deleted.",
};

export const QUESTION_DELETE = {
  pt: "Deseja realmente DELETAR?",
  es: "¿Desea realmente BORRAR?",
  en: "Do you really want to DELETE?",
};

export const WITHOUT_COMPANY = {
  pt: "Nenhuma empresa",
  es: "Sin compañía",
  en: "Without company",
};

export const NO_STATIONS = {
  pt: "Nenhuma estação vinculada a esse local",
  es: "Ninguna estación vinculada a este lugar",
  en: "No stations linked to this location",
};

export const ERROR_STATE_CITY = {
  pt: "Estado ou cidade não condizem com país selecionado",
  es: "El estado o la ciudad no coinciden con el país seleccionado",
  en: "State or city do not match the selected country",
};

export const ADDRESS_TOOLTIP_TEXT = {
  pt: "Certifique-se que cada local possua um endereço único. Caso precise registrar mais de um local no mesmo endereço, adicione um complemento (ex: andar, portão, coluna) ou qualquer outra informação que os diferencie.",
  es: "Asegúrese de que cada ubicación tiene una dirección única. Si necesita registrar más de una ubicación en la misma dirección, añada un complemento (por ejemplo, planta, puerta, columna) o cualquier otra información que las diferencie.",
  en: "Make sure that each location has a unique address. If you need to register more than one location at the same address, add a complement (e.g. floor, gate, column) or any other information that differentiates them.",
};
