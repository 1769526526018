export const ERROR_GET_HISTORIC = {
  pt: "Erro ao buscar histórico",
  es: "Error al buscar historial",
  en: "Error searching for history",
};

export const ERROR_GET_PAYMENT = {
  pt: "Erro ao buscar pagamentos",
  es: "Error al buscar pagos",
  en: "Error searching for payments",
};

export const ERROR_DOWNLOAD_FILE = {
  pt: "Não foi possível fazer o download do arquivo",
  es: "No fue posible descargar el archivo",
  en: "It could not download the file",
};

export const NO_DATA_TO_DOWNLOAD = {
  pt: "Não foi possível fazer o download do arquivo",
  es: "No fue posible descargar el archivo",
  en: "It could not download the file",
};

export const ERROR_GENERATE_FILE = {
  pt: "Não foi possível gerar o arquivo para download",
  es: "No se puede generar el archivo para descargar",
  en: "It could not generate the file for download",
};

export const ERROR_LOAD_USER = {
  pt: "Não foi possível carregar as informações do usuário",
  es: "No fue posible cargar las informaciones del usuario",
  en: "It could not load the user's information",
};

export const TRY_AGAIN_MORE = {
  pt: "Tente novamente dentro de alguns instantes",
};

export const FINISH_CHARGE_ERROR = {
  pt: "Erro ao finalizar carga",
};
export const SEND = {
  pt: "Enviar",
};

export const ADMIN = {
  pt: "Admin",
  es: "Admin",
  en: "Admin",
};

export const CHARGES_HISTORY = {
  pt: "Histórico de cargas",
  es: "Historial de carga",
  en: "Charges history",
};

export const LISTING = {
  pt: "Listagem",
  es: "Listado",
  en: "List",
};

export const STATION = {
  pt: "Estação",
  es: "Estación",
  en: "Station",
};

export const LOADING = {
  pt: "Carregando...",
  es: "Cargando...",
  en: "Loading...",
};

export const SEARCH_BY_ID_OR_NAME_STATION = {
  pt: "Pesquise por ID ou nome da estação",
  es: "Buscar por ID o nombre de la estación",
  en: "Search by ID or station name",
};

export const PERIOD = {
  pt: "Período",
  es: "Período",
  en: "Period",
};

export const INITIAL_DATE = {
  pt: "Data inicial",
  es: "Fecha de inicio",
  en: "Initial date",
};

export const FINAL_DATE = {
  pt: "Data final",
  es: "Fecha final",
  en: "End date",
};
export const FINISHED_CHARGE = {
  pt: "Finalizar carga",
};

export const WANT_COMPLETE_CHARGER = {
  pt: "Você realmente deseja FINALIZAR essa carga?",
};
export const FORCE_FINISH_CHARGE = {
  pt: "Você realmente deseja FORÇAR a finalização dessa carga?",
};
export const GROUP_NAME_EX = {
  pt: "Ex.: Condomínio Abc",
  es: "p.ej.: Condominio Abc",
  en: "Example: Condomínio Abc",
};

export const USER_LOWER_CASE = {
  pt: "usuario",
  es: "usuario",
  en: "user",
};

export const STATION_TO_OCPP_CONNECT = (stationID) => ({
  pt: `A estação ${stationID} está conectada no OCPP no momento`,
});

export const STATION_TO_OCPP_DESCONNECT = (stationID) => ({
  pt: `A estação ${stationID} está desconectada do OCPP no momento`,
});

export const CLEAN = {
  pt: "Limpar",
  es: "Limpiar",
  en: "Clear",
};

export const FILTER = {
  pt: "Filtrar",
  es: "Filtrar",
  en: "Filter",
};

export const DOWNLOAD_CSV = {
  pt: "Baixar em Excel",
  es: "Descargar en Excel",
  en: "Excel download",
};

export const SEE_LOAD = {
  pt: "Ver carga",
  es: "Ver carga",
  en: "See load",
};

export const NAME = {
  pt: "Nome",
  es: "Nombre",
  en: "Name",
};

export const URL = {
  pt: "URL",
  es: "URL",
  en: "URL",
};

export const CANCEL = {
  pt: "Cancelar",
  es: "Cancelar",
  en: "Cancel",
};

export const VIEW_USER_DATA = {
  pt: "Ver dados do usuário",
  es: "Ver datos de usuario",
  en: "See user's data",
};

export const USER_DATA = {
  pt: "Dados do usuário:",
  es: "Datos del usuario:",
  en: "User's data",
};

export const SEE_DATA = {
  pt: "Ver dados",
  es: "Ver datos",
  en: "See data",
};

export const EMAIL = {
  pt: "Email",
  es: "Correo electrónico",
  en: "Email",
};

export const DOCUMENT = {
  pt: "CPF",
  es: "RUC (CPF)",
  en: "CPF",
};

export const ZIP_CODE = {
  pt: "CEP",
  es: "CEP (Código Postal Brasileño)",
  en: "Zip Code",
};

export const PUBLIC_PLACE = {
  pt: "Logradouro",
  es: "Calle",
  en: "Public place",
};

export const NUMBER = {
  pt: "Numero",
  es: "Número",
  en: "Number",
};

export const NEIGHBORHOOD = {
  pt: "Bairro",
};

export const COMPLEMENT = {
  pt: "Complemento",
  es: "Complemento",
  en: "Complement",
};

export const CITY = {
  pt: "Cidade",
  es: "Ciudad",
  en: "City",
};

export const STATE = {
  pt: "Estado",
  es: "Estado",
  en: "State",
};

export const CITY_ID = {
  pt: "ID cidade",
  es: "ID ciudad",
  en: "City ID",
};

export const STATE_ID = {
  pt: "ID estado",
  es: "ID departamento",
  en: "State ID",
};

export const STATE_UF = {
  pt: "Estado UF",
  es: "Departamento UF",
  en: "Federation Unit State",
};

export const NOTE_DATA = {
  pt: "Dados da nota:",
  es: "Datos de la factura:",
  en: "Note data",
};

export const OS_NUMBER = {
  pt: "Numero da OS",
  es: "Número de la OS",
  en: "OS Number",
};

export const VALUE = {
  pt: "Valor",
  es: "Valor",
  en: "Value",
};

export const DATE = {
  pt: "Data",
  es: "Fecha",
  en: "Date",
};

export const INVOICED = {
  pt: "Faturada",
  es: "Facturada",
  en: "Invoiced",
};

export const CANCELED = {
  pt: "Cancelada",
  es: "Cancelada",
  en: "Canceled",
};

export const YES = {
  pt: "Sim",
  es: "Sí",
  en: "Yes",
};

export const NO = {
  pt: "Não",
  es: "No",
  en: "No",
};

export const NOTE_LINK = {
  pt: "Link da Nota",
  es: "Enlace de la factura",
  en: "Note link",
};

export const INVOICE = {
  pt: "Nota Fiscal",
  es: "Factura",
  en: "Invoice",
};

export const CSV_FILE = {
  pt: "historicoCargas.csv",
  es: "historicoCargas.csv",
  en: "chargeHistory.csv",
};

export const CSV_FILE_PAYMENT = {
  pt: "historicoPagamentos.csv",
  es: "historicoPagamentos.csv",
  en: "paymentHistoric.csv",
};

export const ADDRESS = {
  pt: "Endereço",
  es: "Dirección",
  en: "Address",
};

export const DURATION = {
  pt: "Duração",
  es: "Duración",
  en: "Duration",
};

export const END = {
  pt: "Fim",
  es: "Fin",
  en: "End",
};

export const START = {
  pt: "Inicio",
  es: "Inicio",
  en: "Start",
};

export const COUPON = {
  pt: "Cupom",
  es: "Cupon",
  en: "Coupon",
};

export const TOTAL_VALUE = {
  pt: "Valor",
  es: "Valor",
  en: "value",
};

export const BENEFIT = {
  pt: "Beneficio: ",
  es: "Beneficio: ",
  en: "Benefit: ",
};

export const CAMPAIGN = {
  pt: "Campanha: ",
  es: "Campana: ",
  en: "Campaign: ",
};

export const SHOW_CAMPAIGN = {
  pt: "Ver campanha",
  es: "Ver campana",
  en: "Show campaign",
};

export const COUPON_CODE = {
  pt: "Cupom: ",
  es: "Cupon: ",
  en: "Coupon: ",
};

export const SHOW_DETAILS = {
  pt: "Ver detalhes do usuário",
  es: "Ver detalles del usuario",
  en: "Show user details",
};

export const ENERGY = {
  pt: "Energia",
  es: "Energía",
  en: "Energy",
};

export const IDLE_VALUE = {
  pt: "Ociosidade: ",
  es: "valor inactivo: ",
  en: "Idle value: ",
};

export const TOTAL_PRICE = {
  pt: "Total: ",
  es: "Total: ",
  en: "Total: ",
};

export const PLUG = {
  pt: "Plug",
  es: "Enchufe",
  en: "Plug",
};

export const PAID = {
  pt: "Paga",
  es: "Pagada",
  en: "Paid",
};

export const CHARGING = {
  pt: "Carregando",
  es: "Cargando",
  en: "Charging",
};

export const START_DATE_TIME = {
  pt: "Inicio (data/hora)",
  es: "Inicio (fecha/hora)",
  en: "Start (date/hour)",
};

export const END_START_TIME = {
  pt: "Fim (data/hora)",
  es: "Fin (fecha/hora)",
  en: "End (date/hour)",
};

export const START_DATE_UTC = {
  pt: "Inicio (data/hora UTC)",
  es: "Inicio (fecha/hora UTC)",
  en: "Start (date/hour UTC)",
}

export const END_DATE_UTC = {
  pt: "Fim (data/hora UTC)",
  es: "Fin (fecha/hora UTC)",
  en: "End (date/hour UTC)",
}

export const ACTIONS = {
  pt: "Ações",
  es: "Acciones",
  en: "Actions",
};

export const SEE_ON_OCPP = (transactionID) => ({
  pt: `Ver no OCPP (ID: ${transactionID})`,
  es: `Ver en el OCPP (ID: ${transactionID})`,
  en: `See on OCPP (ID: ${transactionID})`,
});

export const SEE_USER = {
  pt: "Ver Usuário",
  es: "Ver Usuario",
  en: "See User",
};

export const SEE_NFSE = {
  pt: "Ver NFSe",
  es: "Ver FacSe",
  en: "See NFSe",
};

export const ISSUE_NFSE = {
  pt: "Emitir NFSe",
  es: "Emitir FacSe",
  en: "Issue NFSe",
};

export const NON_TAX = {
  pt: "Não fiscal",
  es: "No fiscal",
  en: "Non tax",
};

export const SEE_CHARGE = {
  pt: "Ver carga",
  es: "Ver carga",
  en: "See charge",
};

export const PAYMENT_HISTORIC = {
  pt: "Histórico de Pagamentos",
  es: "Historial de Pagos",
  en: "Payment history",
};

export const STATUS = {
  pt: "Status",
  es: "Estatus",
  en: "Status",
};

export const USER_EMAIL = {
  pt: "Email do usuário",
  es: "Correo electrónico del usuario",
  en: "User's email",
};

export const CREATION_DATA = {
  pt: "Data de criação",
  es: "Fecha de creación",
  en: "Creation date",
};

export const USER_INFO = {
  pt: "INFORMAÇÕES DO USUÁRIO",
};

export const USER_NAME = {
  pt: "Nome do usuário",
};

export const REQUIRED_FIELD = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};

export const DOCUMENT_TEXT = {
  pt: "Documento",
};

export const STREET = {
  pt: "Rua",
};

export const SHOW_NFE_DATA = {
  pt: "Ver dados da NFSe",
};

export const ATTENTION = {
  pt: "Atenção!",
};

export const USER = {
  pt: "Usuário",
  es: "Usuario",
  en: "User",
};

export const TRY_AGAIN = {
  pt: "Tente novamente daqui uns instantes...",
};

export const FINISH_CHARGE = {
  pt: "Carga finalizada com sucesso.",
};

export const ALL_RIGHT = {
  pt: "Tudo certo!",
  es: "¡Muy bien!",
  en: "All set!",
};

export const REMOTE_START = {
  pt: "Início remoto",
  // TODO: translate es
  // TODO: translate en
};

export const FREE_VENDING = {
  pt: "Free Vending",
};

export const RECHARGE_BY_RFID = {
  pt: "Recarga por RFID",
  en: "Recharge by RFID",
  es: "Recarga por RFID",
};

export const CHARGE_IN_PROGRESS = {
  pt: "Carga em andamento",
  es: "Carga en progreso",
  en: "Charge in progress",
};

export const UNPAID = {
  pt: "Não paga",
  es: "No pagada",
  en: "Unpaid",
};

export const FREE_WITH_COUPON = {
  pt: "Grátis com cupom",
  es: "Gratis con cupón",
  en: "Free with coupon",
};

export const FREE_CHARGE = {
  pt: "Carga grátis",
  es: "Carga gratuita",
  en: "Free charge",
};

export const POS_PAYMENT = {
  pt: "Pagamento no caixa",
  es: "Pago en la caja",
  en: "Payment at the cashier",
};

export const WALLET_PAYMENT = {
  pt: "Pagamento com wallet",
  es: "Pago con billetera",
  en: "Payment with wallet",
};

export const CARD_PAYMENT = {
  pt: "Pagamento com cartão",
  es: "Pago con tarjeta",
  en: "Payment with card",
};

export const SEE_RECEIPT = {
  pt: "Ver/Baixar recibo",
  es: "Ver/Descargar recibo",
  en: "See/Download receipt",
};

export const TRANSACTION_ID = {
  pt: "ID Transação pagamento",
  es: "ID Transacción pago",
  en: "Payment transaction ID",
};
