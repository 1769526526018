import React from "react";
import * as texts from "../../../../../../pages/Users/locales";
import { Icon, Popover, Tag } from "antd";

interface ChargeSessionStatusBadgeProps {
  status: any;
  freire: any;
}

const ICON = {
  default: "info-circle",
  payment_failed: "close-circle",
  completed: "question-circle",
  finished: "check",
};

const COLOR = {
  default: "cyan",
  completed: "purple",
  idle: "volcano",
  payment_failed: "red",
  timed_out: "red",
  finished: "green",
};

export const ChargeSessionStatusBadge: React.FC<
  ChargeSessionStatusBadgeProps
> = ({ status, freire }) => {
  const content = (
    <span className="chargeSessionStatusBadgeText">
      {freire(texts.CHARGE_SESSION_STATUS_VALUE)[status]}
    </span>
  );
  return (
    <Popover content={content}>
      <Tag
        color={COLOR[status as keyof typeof COLOR] ?? COLOR.default}
        className={`chargeSessionStatusBadge ${status}`}
      >
        <Icon
          type={ICON[status as keyof typeof ICON] ?? ICON.default}
          style={{ marginRight: 5 }}
        />
        <span
          className="chargeSessionStatusBadgeRaw"
          style={{ marginRight: "8px" }}
        >
          {status}
        </span>
      </Tag>
    </Popover>
  );
};
