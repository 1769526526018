import {
  Button,
  Col,
  Descriptions,
  Drawer,
  Form,
  Icon,
  Input,
  notification,
  Popover,
  Row,
  Tag,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import GenerateFilter from "../../../components/GenerateFilter";
import api from "../../../services/api";
import apiData from "../../../services/apiData";
import TablePagination, {
  paginationInitConfig,
} from "../../../components/TablePagination";
import { Show } from "../../../contexts/PermissionContext";
import { getStationsOptionsBySelectedCompanies } from "../../../utils/getOptions";
import generateFields from "./fields";
import "./index.css";
import NFSeDrawer from "./NFSeDrawer";
import { FreireContext } from "../../../utils/freireContext";
import * as texts from "./locales";
import { formatters } from "../../../utils/formatters";
import StopChargeModal from "./StopChargeModal";
import CouponBenefitTag from "../../../components/CouponBenefitTag";
import { ExitIcon } from "../../../assets/icons/exit.svg";
import { Link } from "react-router-dom";
import ButtonCsvDownload from "../../../components/ButtonCsvDownload";

/// TODO: Deprecate it and remove after all changes
export const formatExcel = ({ freire, docs }) => {
  return (
    docs?.map((data, i) => {
      return {
        ...data,
        userEmail: data.userEmail
          ? data.userEmail
          : (data.userID === "admin" && freire(texts.REMOTE_START)) ||
            (data.userID === "free-vending" && freire(texts.FREE_VENDING)) ||
            "---",
        stopValue:
          data.stopValue == null
            ? "Carregando"
            : `${data.stopValue.toLocaleString(freire.userLanguage, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} kWh`,
        chargeValue:
          data.chargeValue !== undefined
            ? formatters.currency(
                freire.userLanguage,
                data.currency
              )(data.chargeValue / 100)
            : "---",
        duration: formatters.duration(freire)(
          moment(data.stopDateTime).diff(data.startDateTime)
        ),
        startDateTime: data.startDateTime
          ? moment(data.startDateTime).format("L LTS")
          : "---",
        stopDateTime: data.stopDateTime
          ? moment(data.stopDateTime).format("L LTS")
          : "---",
        station: data.station || {},
        plugsName:
          data?.station?.connectedPlugs?.find(
            (plug) => plug.startConnectorID === data.connectorID
          )?.plugsName?.name ?? "----",
        rfid: data.rfidTagCod ?? "----",
      };
    }) ?? []
  );
};

/// TODO: Deprecate it and remove after all changes
export const getHeaders = ({ freire }) => {
  return [
    { label: freire(texts.EMAIL), key: "userEmail" },
    { label: freire(texts.STATION), key: "station.name" },
    { label: "ID", key: "stationID" },
    { label: freire(texts.PLUG), key: "plugsName" },
    { label: freire(texts.ENERGY), key: "stopValue" },
    { label: freire(texts.TOTAL_VALUE), key: "chargeValue" },
    { label: freire(texts.RECHARGE_BY_RFID), key: "rfid" },
    { label: freire(texts.START), key: "startDateTime" },
    { label: freire(texts.END), key: "stopDateTime" },
    { label: freire(texts.DURATION), key: "duration" },
    { label: freire(texts.ADDRESS), key: "address" },
  ];
};

class ListChargesHistory extends Component {
  static contextType = FreireContext;

  state = {
    selectedCompanies: [],
    selectedStations: [],
    stationOptions: [],
    companyOptions: [],
    allSelectStations: [],
    allCompanies: [],
    companyID: "",
    name: "seila",
    loadingTable: false,
    data: [],
    charge: [],
    datePicker: [],
    visible: false,
    visibleUser: false,
    visibleNFSe: false,
    visibleNewNFSe: false,
    visibleStopChargeModal: false,
    currentCharge: {},
    userData: {},
    charger: {},
    NFSe: {},
    dateFiltrada: [],
    dataExcel: [],
    totalDateFiltrada: 0,
    pagination: paginationInitConfig,
    stationsFilter: [],
    loadingStationsFilter: false,
    preventPageReload: false,
    // TODO: Add it
    //csvLoading: false,
  };
  //TODO: Check if remove constructor
  // constructor(props) {
  //   super(props);
  //   //TODO: Remove it
  //   //this.CSVLinkRef = React.createRef();
  // }

  componentDidMount() {
    const { location, form } = this.props;

    const usp = new URLSearchParams(location.search);
    const station_ids = usp.get("station_ids");

    form.setFieldsValue({
      dateTime: [moment().subtract(1, "days"), moment()],
      ...(station_ids && { station_ids: `${station_ids}`.split(",") }),
    });
    this.filter();
    this.getAllSelectStations();
    this.getAllCompanies();
  }

  componentDidUpdate(_, prevState) {
    if (prevState.selectedCompanies !== this.state.selectedCompanies) {
      const stationOptions = getStationsOptionsBySelectedCompanies(
        this.state.allSelectStations,
        this.state.selectedCompanies
      );

      this.setState({ stationOptions });
    }
  }

  async getAllSelectStations() {
    const { data: allSelectStations } = await api.get(
      `select/connected_stations`
    );
    this.setState({
      allSelectStations,
      stationOptions: getStationsOptionsBySelectedCompanies(
        allSelectStations,
        this.state.selectedCompanies
      ),
    });
  }

  async getAllCompanies() {
    const { data: allCompanies } = await api.get("company");
    this.setState({
      allCompanies,
      companyOptions: allCompanies.map((company) => ({
        label: company.companyName,
        value: company._id,
      })),
    });
  }

  async getcharges(params) {
    const { freire } = this.context;
    this.setState({ loadingTable: true }, async () => {
      try {
        const {
          data: { docs, totalDocs },
        } = await api.post(`chargerHistory`, {
          ...(this.state.clearFilter && { cardFilter: this.state.cardFilter }),
          ...params,
          ...this.state.pagination,
        });
        this.setState({
          dateFiltrada: docs,
          totalDateFiltrada: totalDocs,
          loadingTable: false,
        });
      } catch (error) {
        notification.error({
          message: "Ops...",
          description: freire(texts.ERROR_GET_HISTORIC),
        });
        this.setState({ loadingTable: false });
      }
    });
  }

  closeDrawer = () => {
    this.setState({ visible: false });
  };

  closeDrawerUser = () => {
    this.setState({ visibleUser: false, userData: {} });
  };

  closeDrawerNFSe = () => {
    this.setState({ visibleNFSe: false, NFSe: {} });
  };

  closeDrawerNewNFSe = () => {
    this.setState({ visibleNewNFSe: false, userData: {} });
  };

  getParamsFilter = async () => {
    try {
      const values = await this.props.form.validateFields();
      let { stationID, dateTime, station_ids, company_ids } = values;
      let params = {
        ...(station_ids && { station_ids }),
        ...(company_ids && { company_ids }),
      };
      if (params.station_ids?.length === this.state.stationOptions.length) {
        params.station_ids = undefined;
      }
      if (params.company_ids?.length === this.state.allCompanies.length) {
        params.company_ids = undefined;
      }

      if (dateTime && dateTime.length > 0) {
        let startDate = dateTime[0].startOf("day").toISOString();

        let endDate = dateTime[1].endOf("day").toISOString();

        params["startDate"] = startDate;
        params["endDate"] = endDate;
      }

      if (stationID) {
        params["stationID"] = stationID;
      }
      return params;
    } catch (error) {
      return {};
    }
  };

  filter = async (byButton = true) => {
    if (byButton) {
      this.setState((prevState) => ({
        pagination: { ...prevState.pagination, page: 1 },
      }));
    }
    const params = await this.getParamsFilter();
    this.getcharges(params);
    if (!this.state.preventPageReload) {
      return false;
    }
  };

  clearFilter = () => {
    this.props.form.resetFields();
    this.setState(
      { datePicker: [], selectedCompanies: [], selectedStations: [] },
      this.filter
    );
    return false;
  };

  async downloadExcel() {
    const { freire } = this.context;

    const paramsFilter = await this.getParamsFilter();

    const timezone = moment.tz.guess();

    try {
      this.setState({ csvLoading: true });
      const selectedStations = this.state.selectedStations;
      const {
        data: { email },
      } = await apiData.post(`/chargerHistory`, {
        ...paramsFilter,
        page: 1,
        delay: true,
        timezone,
        limit: Number.MAX_SAFE_INTEGER,
        stationID: selectedStations,
        ...(this.state.selectedCompanies && {
          company_ids: this.state.selectedCompanies,
        }),
      });

      // TODO: Remove this if and post the alert box
      // if (docs && docs.length > 0) {
      //   downloadList = formatExcel({ docs, freire });
      // }
      notification.success({
        message: "Sucesso",
        description: `O relatorio foi enviado para seu email ${email}`,
      });
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_GENERATE_FILE),
      });
      return false;
    } finally {
      this.setState({ csvLoading: false });
    }
  }

  getUserAddress = async (userID) => {
    const { freire } = this.context;

    try {
      const { data: userData } = await api.get(`user/${userID}/address`);
      this.setState({ userData, visibleUser: true });
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_LOAD_USER),
      });
    }
  };

  getUserForNFSe = async (charger) => {
    const { freire } = this.context;

    try {
      const { userID } = charger;
      const { data: userData } = await api.get(`user/${userID}/address`);

      this.setState({ userData, visibleNewNFSe: true, charger });
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_LOAD_USER),
      });
    }
  };

  getNFSe = async (charger) => {
    try {
      if (!charger.nfse.url) {
        const { data } = await api.get(`nfse/${charger._id}`);
        charger = data;
      }

      this.setState({ charger, visibleNFSe: true });
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: this.context.freire(texts.TRY_AGAIN),
      });
    }
  };

  getColumns = () => {
    const { freire } = this.context;

    return [
      {
        title: freire(texts.EMAIL),
        dataIndex: "userEmail",
        key: "userEmail",
        render: (userEmail, { userID }) =>
          userEmail ? (
            <span>{userEmail}</span>
          ) : (
            <span>
              {userID === "admin" && freire(texts.REMOTE_START)}
              {userID === "free-vending" && freire(texts.FREE_VENDING)}
            </span>
          ),
      },
      {
        title: freire(texts.STATION),
        dataIndex: "station.name",
        key: "station.name",
        width: 150,
      },
      {
        title: "ID",
        dataIndex: "stationID",
        key: "stationID",
      },
      {
        title: freire(texts.TRANSACTION_ID),
        dataIndex: "paymentHistoryID.transationID",
        key: "paymentHistoryID.transationID",
      },
      {
        title: freire(texts.ENERGY),
        dataIndex: "stopValue",
        key: "stopValue",
        width: 100,
        render: (value, data) =>
          value == null ? (
            <Tag color="green" key={""}>
              {freire(texts.CHARGING)}
            </Tag>
          ) : (
            <span>
              {`${value.toLocaleString(freire.userLanguage, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} kWh`}
            </span>
          ),
      },
      {
        title: freire(texts.TOTAL_VALUE),
        dataIndex: "chargeValue",
        key: "chargeValue",
        width: 180,
        render: (chargeValue, data) => (
          <>
            <span>
              {freire(texts.ENERGY)}
              {": "}
              {formatters.currency(
                freire.userLanguage,
                data.currency
              )((chargeValue + data.discountValue - data.idleValue) / 100)}
            </span>

            {data.stationSnapshot?.idleFee?.enabled && (
              <>
                <br />
                <span>
                  {freire(texts.IDLE_VALUE)}
                  {formatters.currency(
                    freire.userLanguage,
                    data.currency
                  )(data.idleValue / 100)}
                </span>
              </>
            )}

            {data.coupon?.campaignDetails && (
              <>
                <br />
                <Popover
                  placement="top"
                  content={
                    <div
                      style={{ borderRadius: 2, maxWidth: 300, minHeight: 80 }}
                    >
                      <div>
                        <span style={{ fontWeight: "bold" }}>
                          {freire(texts.BENEFIT)}
                        </span>
                        <CouponBenefitTag
                          benefit={data.coupon.campaignDetails.benefit}
                          freire={freire}
                        />
                      </div>
                      <br />
                      <div>
                        <Link
                          style={{ textDecoration: "underline" }}
                          to={`/dashboard/users?email=${data.userEmail}&openDetails=true`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {freire(texts.SHOW_DETAILS)}{" "}
                          <Icon component={ExitIcon} />
                        </Link>
                      </div>
                    </div>
                  }
                >
                  <span>
                    {freire(texts.COUPON)}
                    {": - "}
                    {formatters.currency(
                      freire.userLanguage,
                      data.currency
                    )(data.discountValue / 100)}
                  </span>
                </Popover>
              </>
            )}
            <br />
            <span>
              <strong>
                {freire(texts.TOTAL_PRICE)}
                {formatters.currency(
                  freire.userLanguage,
                  data.currency
                )(chargeValue / 100)}
              </strong>
            </span>
          </>
        ),
      },
      {
        title: freire(texts.START_DATE_TIME),
        dataIndex: "startDateTime",
        key: "startDateTime",
        render: (startDateTime) =>
          startDateTime ? (
            <span>{moment(startDateTime).format("L LTS")}</span>
          ) : (
            <span>----</span>
          ),
      },
      {
        title: freire(texts.END_START_TIME),
        dataIndex: "stopDateTime",
        key: "stopDateTime",
        render: (stopDateTime) =>
          stopDateTime ? (
            <span>{moment(stopDateTime).format("L LTS")}</span>
          ) : (
            <span>----</span>
          ),
      },
      {
        title: freire(texts.START_DATE_UTC),
        dataIndex: "startDateTime",
        key: "startDateTimeUTC",
        render: (startDateTime) =>
          startDateTime ? (
            <span>{moment.utc(startDateTime).format("L LTS")}</span>
          ) : (
            <span>----</span>
          ),
      },
      {
        title: freire(texts.END_DATE_UTC),
        dataIndex: "stopDateTime",
        key: "stopDateTimeUTC",
        render: (stopDateTime) =>
          stopDateTime ? (
            <span>{moment.utc(stopDateTime).format("L LTS")}</span>
          ) : (
            <span>----</span>
          ),
      },
      {
        title: freire(texts.DURATION),
        render: (text, data) =>
          data.stopDateTime ? (
            <Tag color="blue" key={""}>
              <span>
                {formatters.duration(freire)(
                  moment(data.stopDateTime).diff(data.startDateTime)
                )}
              </span>
            </Tag>
          ) : (
            <span>----</span>
          ),
      },
      {
        title: freire(texts.PAID),
        render: (_, data) => {
          if (!data.stopDateTime) {
            return (
              <Popover content={freire(texts.CHARGE_IN_PROGRESS)}>
                <span>---</span>
              </Popover>
            );
          }

          const isPaid = data.paymentSuccess;
          const isChargeFree = data.chargeValue < 100;
          let paymentMethod = "";

          if (!isPaid) {
            paymentMethod = freire(texts.UNPAID);
          } else if (isChargeFree) {
            paymentMethod = data.coupon
              ? freire(texts.FREE_WITH_COUPON)
              : freire(texts.FREE_CHARGE);
          } else if (data.posPayment) {
            paymentMethod = freire(texts.POS_PAYMENT);
          } else if (data.paymentHistoryID?.type === "wallet-debit") {
            paymentMethod = freire(texts.WALLET_PAYMENT);
          } else if (data.paymentHistoryID?.transactionID !== "") {
            paymentMethod = freire(texts.CARD_PAYMENT);
          }

          return (
            <Popover content={paymentMethod}>
              <Icon
                style={{ color: isPaid ? "green" : "red" }}
                type={isPaid ? "check" : "close"}
              />
            </Popover>
          );
        },
      },
      {
        title: freire(texts.ADDRESS),
        dataIndex: "address",
        key: "address",
      },
      {
        title: freire(texts.ACTIONS),
        key: "acoes",
        width: 180,
        render: (text, data) => (
          <>
            <Show when={"show-btn-ocpp"}>
              <Tooltip
                placement="top"
                title={freire(texts.SEE_ON_OCPP(data.transactionID))}
              >
                <Button
                  shape="circle"
                  size="small"
                  ghost
                  type="primary"
                  style={{ marginRight: 8 }}
                  onClick={() => {
                    const newWindow = window.open(
                      data.centralSystem
                        ? `/dashboard/ocpp/transactions/${data.transactionID}`
                        : `https://ocpp.tupinambaenergia.com.br/steve/manager/transactions/details/${data.transactionID}`,
                      "_blank",
                      "noopener,noreferrer"
                    );
                    if (newWindow) newWindow.opener = null;
                  }}
                >
                  <Icon type="link" />
                </Button>
              </Tooltip>
            </Show>
            <Show when={"show-btn-stop-charge"}>
              {data.centralSystem && data.stopValue === null ? (
                <Tooltip placement="top" title={"Finalizar carga"}>
                  <Button
                    shape="circle"
                    size="small"
                    ghost
                    type="danger"
                    style={{ marginRight: 8 }}
                    onClick={() => this.showStopChargeModal(data)}
                  >
                    <Icon type="stop" />
                  </Button>
                </Tooltip>
              ) : null}
            </Show>
            <Show when={"show-btn-charger-user"}>
              <Tooltip placement="top" title={freire(texts.SEE_USER)}>
                <Button
                  shape="circle"
                  size="small"
                  ghost
                  type="primary"
                  style={{ marginRight: 8 }}
                  onClick={() => this.getUserAddress(data.userID)}
                >
                  <Icon type="barcode" />
                </Button>
              </Tooltip>
            </Show>
            {data.receiptFilePath && (
              <Tooltip placement="top" title={freire(texts.SEE_RECEIPT)}>
                <a
                  href={data.receiptFilePath}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    shape="circle"
                    size="small"
                    ghost
                    type="primary"
                    style={{ marginRight: 8 }}
                  >
                    <Icon type="download" />
                  </Button>
                </a>
              </Tooltip>
            )}
            {data.station?.rules?.nfse?.enabled && data.chargeValue > 0 && (
              <Show when={"show-btn-nfse-user"}>
                <Tooltip
                  placement="top"
                  title={
                    data.chargeValue > 0
                      ? data.nfse?.created
                        ? freire(texts.SEE_NFSE)
                        : freire(texts.ISSUE_NFSE)
                      : freire(texts.NON_TAX)
                  }
                >
                  <Button
                    shape="circle"
                    size="small"
                    ghost
                    type="primary"
                    style={{
                      marginRight: 8,
                      borderColor: data.nfse?.created ? "#1890ff" : "#FF0000",
                    }}
                    onClick={() =>
                      data.chargeValue > 0
                        ? data.nfse?.created
                          ? this.getNFSe(data)
                          : this.getUserForNFSe(data)
                        : null
                    }
                  >
                    {data.nfse?.created ? (
                      <Icon type="container" />
                    ) : (
                      <Icon type="file-excel" style={{ color: "#FF0000" }} />
                    )}
                  </Button>
                </Tooltip>
              </Show>
            )}
          </>
        ),
      },
    ];
  };

  showStopChargeModal = (currentCharge) => {
    this.setState({
      currentCharge,
      visibleStopChargeModal: true,
      preventPageReload: true,
    });
  };

  onCloseStopChargeModal = () => {
    this.setState({ currentCharge: {}, visibleStopChargeModal: false });
  };

  onConfirmStopChargeModal = () => {
    notification.success({
      message: this.context.freire(texts.ALL_RIGHT),
      description: this.context.freire(texts.FINISH_CHARGE),
    });
    this.setState({ visibleStopChargeModal: false });
    this.getcharges();
  };

  onChangeCompany = (value) => this.setState({ selectedCompanies: value });

  render() {
    const {
      userData,
      charger,
      pagination,
      visibleStopChargeModal,
      currentCharge,
      companyOptions,
      stationOptions,
      preventPageReload,
      csvLoading,
    } = this.state;
    const { freire } = this.context;
    const columns = this.getColumns();

    const filterFields = generateFields({
      stationOptions,
      companyOptions,
      onChangeCompany: this.onChangeCompany,
    });

    return (
      <div className="container">
        <Breadcrumbs
          breadcrumbs={[
            freire(texts.ADMIN),
            freire(texts.CHARGES_HISTORY),
            freire(texts.LISTING),
          ]}
        />
        <GenerateFilter
          form={this.props.form}
          fields={filterFields}
          handleClear={this.clearFilter}
          handleFilter={this.filter}
          preventPageReload={preventPageReload}
        />

        <TablePagination
          style={{ backgroundColor: "white", marginBottom: 8 }}
          scroll={{ x: 1300 }}
          tableLayout="auto"
          loading={this.state.loadingTable}
          rowKey={(data) => data._id}
          dataSource={this.state.dateFiltrada}
          columns={columns}
          total={this.state.totalDateFiltrada}
          current={pagination.page}
          onPagination={(pagination) =>
            this.setState({ pagination }, () => this.filter(false))
          }
        />

        <ButtonCsvDownload
          loading={csvLoading}
          onClick={() => this.downloadExcel()}
        />

        <Drawer
          title={freire(texts.SEE_LOAD)}
          width={500}
          onClose={this.closeDrawer}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form layout="vertical" hideRequiredMark>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="userID">
                  <Input
                    placeholder={freire(texts.NAME)}
                    value={this.state.charge.name}
                    onChange={(value) => {
                      let charge = this.state.charge;
                      charge.name = value.target.value;
                      this.setState({ charge });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={freire(texts.URL)}>
                  <Input
                    placeholder={freire(texts.URL)}
                    value={this.state.charge.url}
                    onChange={(value) => {
                      let charge = this.state.charge;
                      charge.url = value.target.value;
                      this.setState({ charge });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <Button onClick={this.closeDrawer} style={{ marginRight: 8 }}>
              {freire(texts.CANCEL)}
            </Button>
          </div>
        </Drawer>
        {/* / Ver dados para nota fiscal/ */}

        <Drawer
          title={freire(texts.VIEW_USER_DATA)}
          width={500}
          onClose={this.closeDrawerUser}
          visible={this.state.visibleUser}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form layout="vertical" hideRequiredMark>
            <Row gutter={16}>
              <Descriptions title={freire(texts.USER_DATA)} bordered column={1}>
                <Descriptions.Item label="ID">
                  {userData.userID}
                </Descriptions.Item>
                {userData.fcmToken && (
                  <>
                    {userData.fcmToken.tupinamba && (
                      <Descriptions.Item label="FCM TUPI">
                        {userData.fcmToken.tupinamba}
                      </Descriptions.Item>
                    )}
                    {userData.fcmToken.neocharge && (
                      <Descriptions.Item label="FCM NEO">
                        {userData.fcmToken.neocharge}
                      </Descriptions.Item>
                    )}
                    {userData.fcmToken.mobilize && (
                      <Descriptions.Item label="FCM MOB">
                        {userData.fcmToken.mobilize}
                      </Descriptions.Item>
                    )}
                    {userData.fcmToken["shell-recharge"] && (
                      <Descriptions.Item label="FCM SHELL">
                        {userData.fcmToken["shell-recharge"]}
                      </Descriptions.Item>
                    )}
                    {userData.fcmToken.byd && (
                      <Descriptions.Item label="FCM BYD">
                        {userData.fcmToken.byd}
                      </Descriptions.Item>
                    )}
                  </>
                )}
                <Descriptions.Item label={freire(texts.NAME)}>
                  {userData.fullName ?? userData.displayName}
                </Descriptions.Item>
                <Descriptions.Item label={freire(texts.EMAIL)}>
                  {userData.email}
                </Descriptions.Item>
                <Descriptions.Item label={freire(texts.DOCUMENT)}>
                  {userData.document}
                </Descriptions.Item>
                {userData.address && (
                  <>
                    <Descriptions.Item label={freire(texts.ZIP_CODE)}>
                      {userData.address.cep}
                    </Descriptions.Item>
                    <Descriptions.Item label={freire(texts.PUBLIC_PLACE)}>
                      {userData.address.logradouro}
                    </Descriptions.Item>
                    <Descriptions.Item label={freire(texts.NUMBER)}>
                      {userData.address.numero}
                    </Descriptions.Item>
                    <Descriptions.Item label={freire(texts.COMPLEMENT)}>
                      {userData.address.complemento}
                    </Descriptions.Item>
                    <Descriptions.Item label={freire(texts.CITY)}>
                      {userData.address.cidade}
                    </Descriptions.Item>
                    <Descriptions.Item label={freire(texts.STATE)}>
                      {userData.address.estado}
                    </Descriptions.Item>
                    <Descriptions.Item label={freire(texts.CITY_ID)}>
                      {userData.address.cidadeId}
                    </Descriptions.Item>
                    <Descriptions.Item label={freire(texts.STATE_ID)}>
                      {userData.address.estadoId}
                    </Descriptions.Item>
                    <Descriptions.Item label={freire(texts.STATE_UF)}>
                      {userData.address.estadoUf}
                    </Descriptions.Item>
                  </>
                )}
              </Descriptions>
            </Row>
          </Form>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <Button onClick={this.closeDrawerUser} style={{ marginRight: 8 }}>
              {freire(texts.CANCEL)}
            </Button>
          </div>
        </Drawer>
        <Drawer
          title={freire(texts.SEE_DATA)}
          width={500}
          onClose={this.closeDrawerNFSe}
          visible={this.state.visibleNFSe}
          bodyStyle={{ paddingBottom: 80 }}
        >
          {charger.nfse && (
            <>
              <Form layout="vertical" hideRequiredMark>
                <Row gutter={16}>
                  <Descriptions
                    title={freire(texts.NOTE_DATA)}
                    bordered
                    column={1}
                  >
                    <Descriptions.Item label={freire(texts.OS_NUMBER)}>
                      {charger.nfse.cNumOS}
                    </Descriptions.Item>
                    <Descriptions.Item label={freire(texts.VALUE)}>
                      {formatters.currency(
                        freire.userLanguage,
                        charger.currency
                      )(charger.nfse.valor)}
                    </Descriptions.Item>
                    <Descriptions.Item label={freire(texts.DATE)}>
                      {moment(charger.nfse.data).format("L")}
                    </Descriptions.Item>
                    <Descriptions.Item label={freire(texts.INVOICED)}>
                      {charger.nfse.faturada
                        ? freire(texts.YES)
                        : freire(texts.NO)}
                    </Descriptions.Item>
                    <Descriptions.Item label={freire(texts.CANCELED)}>
                      {charger.nfse.cancelada
                        ? freire(texts.YES)
                        : freire(texts.NO)}
                    </Descriptions.Item>
                    <Descriptions.Item label={freire(texts.NOTE_LINK)}>
                      <a
                        href={charger.nfse.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {freire(texts.INVOICE)}
                      </a>
                    </Descriptions.Item>
                  </Descriptions>
                </Row>
              </Form>
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  width: "100%",
                  borderTop: "1px solid #e9e9e9",
                  padding: "10px 16px",
                  background: "#fff",
                  textAlign: "right",
                }}
              >
                <Button
                  onClick={this.closeDrawerNFSe}
                  style={{ marginRight: 8 }}
                >
                  {freire(texts.CANCEL)}
                </Button>
              </div>
            </>
          )}
        </Drawer>
        <NFSeDrawer
          visibleNewNFSe={this.state.visibleNewNFSe}
          closeDrawerNewNFSe={this.closeDrawerNewNFSe}
          user={userData}
          charger={charger}
          clear={() => this.getcharges()}
        />
        <StopChargeModal
          visible={visibleStopChargeModal}
          currentCharge={currentCharge}
          onClose={this.onCloseStopChargeModal}
          onConfirm={this.onConfirmStopChargeModal}
        />
      </div>
    );
  }
}
const PageChargesHistory = Form.create({ name: "filters" })(ListChargesHistory);
export default withRouter(PageChargesHistory);
