import {
  Button,
  Col,
  Drawer,
  Form,
  Icon,
  Input,
  message,
  notification,
  Row,
  Spin,
  Tooltip,
} from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import TablePagination, {
  paginationInitConfig,
} from "../../../components/TablePagination";
import Show from "../../../contexts/PermissionContext/Show";
import api from "../../../services/api";
import "./index.css";
import * as texts from "../locales";
import { FreireContext } from "../../../utils/freireContext";
import UserDetailsAndCharges from "./Drawers/UserDetailsAndCharges";
import copy from "copy-to-clipboard";
import moment from "moment";
import ButtonCsvDownload from "../../../components/ButtonCsvDownload";

class ListUsersWithCharge extends Component {
  static contextType = FreireContext;

  state = {
    loadingTable: false,
    loadingSave: false,
    loadingCars: false,
    admin: false,
    usersList: [],
    totalUsers: 0,
    locationSearch: {},
    pagination: paginationInitConfig,
    csvLoading: false,

    dataExcel: [],
    cars: [],
    currentUser: {},

    visibleDetailsDrawer: false,
    visibleCarsDrawer: false,
  };

  componentDidMount() {
    const usp = new URLSearchParams(this.props.location.search);
    this.setState({
      locationSearch: Object.fromEntries(usp),
    });
    this.getUsers();
  }

  async getUsers(params = {}) {
    const { freire } = this.context;

    this.setState(
      { loadingTable: true, usersList: [], totalUsers: 0 },
      async () => {
        try {
          const payload = {
            ...this.state.locationSearch,
            ...params,
            ...this.state.pagination,
          };
          const {
            data: { docs, totalDocs },
          } = await api.get(`users_charged`, {
            params: payload,
          });

          this.setState({
            usersList: docs,
            totalUsers: totalDocs,
            loadingTable: false,
          });
          if (
            docs.length === 1 &&
            this.state.locationSearch.openDetails === "true"
          ) {
            this.openDetailsDrawer(docs[0]);
          }
        } catch (error) {
          this.setState({ loadingTable: false });
          let description = freire(texts.ERROR_GET_USERS);
          if (error?.response?.data?.showError) {
            description = error?.response?.data?.message;
          }
          notification.error({ message: "Ops...", description });
        }
      }
    );
  }

  async downloadUsers(params = {}) {
    const { freire } = this.context;
    const timezone = moment.tz.guess();

    try {
      this.setState({ csvLoading: true });
      const {
        data: { email },
      } = await api.get(`users_charged`, {
        params: {
          ...params,
          page: 1,
          delay: true,
          timezone,
          limit: Number.MAX_SAFE_INTEGER,
        },
      });

      notification.success({
        message: "Sucesso",
        description: `O relatório foi enviado para seu email ${email}`,
      });
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_CREATE_FILE),
      });
    } finally {
      this.setState({ csvLoading: false });
    }
  }

  openDetailsDrawer = (user) => {
    this.setState({
      visibleDetailsDrawer: true,
      currentUser: user,
    });
  };

  openCarsDrawer = async (user) => {
    const { freire } = this.context;

    this.setState(
      {
        visibleCarsDrawer: true,
        loadingCars: true,
        currentUserName: user.displayName,
        currentUserEmail: user.email,
      },
      async () => {
        try {
          const { data } = await api.get(`admin/cars/${user.userID}`);

          this.setState({ cars: data, loadingCars: false });
        } catch (error) {
          this.setState({ cars: [], loadingCars: false });
          notification.error({
            message: "Ops...",
            description: freire(texts.ERROR_GET_USERS_CARS),
          });
        }
      }
    );
  };

  closeDrawer = () => {
    this.setState({ visibleDetailsDrawer: false, visibleCarsDrawer: false });
  };

  filter = (byButton = true) => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.getUsers(values);
      }
    });

    if (byButton) {
      this.setState((prevState) => ({
        pagination: { ...prevState.pagination, page: 1 },
      }));
    }
  };

  clearFilters = () => {
    this.props.form.resetFields();
    this.filter();
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.filter();
    }
  };

  downloadExcel = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.downloadUsers(values);
      }
    });
  };

  getColumns = () => {
    const { freire } = this.context;

    return [
      {
        key: "displayName",
        title: freire(texts.NAME),
        dataIndex: "displayName",
        width: 300,
        render: (displayName) => (
          <>
            <Tooltip placement="right" title={freire(texts.COPY)}>
              <span
                className="copyText"
                onClick={() => {
                  copy(displayName ?? "----");
                  message.info(freire(texts.COPIED));
                }}
              >
                {displayName ?? "----"}
              </span>
            </Tooltip>
          </>
        ),
      },
      {
        key: "email",
        title: freire(texts.EMAIL),
        dataIndex: "email",
        minWidth: 285,
        render: (email) => (
          <>
            <Tooltip placement="right" title={freire(texts.COPY)}>
              <span
                className="copyText"
                onClick={() => {
                  copy(email ?? "----");
                  message.info(freire(texts.COPIED));
                }}
              >
                {email ?? "----"}
              </span>
            </Tooltip>
          </>
        ),
      },
      {
        key: "createdAt",
        title: freire(texts.CREATED_AT),
        dataIndex: "createdAt",
        align: "center",
        width: 190,
        render: (createdAt) => <span>{moment(createdAt).format("L LTS")}</span>,
      },
      {
        key: "chargesCount",
        title: freire(texts.CHARGES),
        dataIndex: "totalCharge",
        align: "right",
        width: 90,
        // sortDirections: ["descend"],
        // sorter: () => {},
        render: (text, data) => <>{data.totalCharge}</>,
      },
      {
        key: "actions",
        title: freire(texts.ACTIONS),
        className: "actions-column",
        width: 150,
        align: "center",
        fixed: "right",
        render: (text, data) => (
          <>
            <Show when={"show-user-charges"}>
              <Tooltip placement="top" title={freire(texts.SEE_CHARGES)}>
                <Button
                  shape="circle"
                  size="small"
                  ghost
                  type="primary"
                  style={{ marginRight: 8 }}
                  onClick={() => this.openDetailsDrawer(data)}
                >
                  <Icon type="eye" />
                </Button>
              </Tooltip>
            </Show>

            <Show when={"show-user-vehicles"}>
              <Tooltip placement="top" title={freire(texts.SEE_CARS)}>
                <Button
                  shape="circle"
                  size="small"
                  ghost
                  type="primary"
                  style={{ borderColor: "#029b2d", color: "#029b2d" }}
                  onClick={() => this.openCarsDrawer(data)}
                >
                  <Icon type="car" />
                </Button>
              </Tooltip>
            </Show>

            <Show when={"show-user-details"}>
              <Tooltip placement="top" title={freire(texts.SEE_DETAILS)}>
                <Button
                  shape="circle"
                  size="small"
                  ghost
                  type="primary"
                  style={{ marginRight: 8 }}
                  href={`/dashboard/users/${data.userID}?user_id=${data.user_id}`}
                >
                  <Icon type="edit" />
                </Button>
              </Tooltip>
            </Show>
          </>
        ),
      },
    ];
  };

  render() {
    const {
      loadingTable,
      usersList,
      totalUsers,
      dataExcel,
      currentUser,
      visibleDetailsDrawer,
      visibleCarsDrawer,
      loadingCars,
      cars,
      pagination,
      locationSearch,
      csvLoading,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { freire } = this.context;

    const columns = this.getColumns();

    return (
      <div className="container">
        <Breadcrumbs breadcrumbs={[freire(texts.ADMIN), freire(texts.USERS)]} />
        <div className="filter">
          <div>
            <strong>{freire(texts.SEARCH_DOCUMENTS)}:</strong>
            <p>{freire(texts.SEARCH_BY_DOCUMENTS)}</p>
          </div>
          <Form wrapperCol={{ span: 24 }}>
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <Form.Item label="Email">
                  {getFieldDecorator("email", {
                    initialValue: locationSearch.email,
                  })(
                    <Input
                      className="firstInput"
                      placeholder={freire(texts.EMAIL_EXAMPLE)}
                      onKeyPress={this.handleKeyPress}
                      onChange={(e) => {
                        e.target.value = e.target.value.trim();
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Name">
                  {getFieldDecorator("name", {
                    initialValue: locationSearch.name,
                  })(
                    <Input
                      className="firstInput"
                      placeholder={freire(texts.NAME_EXAMPLE)}
                      onKeyPress={this.handleKeyPress}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Documento">
                  {getFieldDecorator("document", {
                    initialValue: locationSearch.document,
                  })(
                    <Input
                      className="firstInput"
                      placeholder={freire(texts.DOCUMENT_EXAMPLE)}
                      onKeyPress={this.handleKeyPress}
                      onChange={(e) => {
                        let cleanedValue = e.target.value.replace(/\D/g, "");
                        if (cleanedValue.length > 11) {
                          cleanedValue = cleanedValue.substr(0, 11);
                        }
                        if (cleanedValue.length === 11) {
                          let formattedValue = "";
                          for (let i = 0; i < cleanedValue.length; i++) {
                            if (i === 3 || i === 6) {
                              formattedValue += ".";
                            } else if (i === 9) {
                              formattedValue += "-";
                            }
                            formattedValue += cleanedValue[i];
                          }
                          e.target.value = formattedValue;
                        } else {
                          e.target.value = cleanedValue;
                        }
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Telefone">
                  {getFieldDecorator("phone", {
                    initialValue: locationSearch.phone,
                  })(
                    <Input
                      className="firstInput"
                      placeholder={freire(texts.PHONE_EXAMPLE)}
                      onKeyPress={this.handleKeyPress}
                      onChange={(e) => {
                        e.target.value = e.target.value.trim();
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <div className="footer">
            <Button style={{ marginRight: 10 }} onClick={this.clearFilters}>
              {freire(texts.CLEAN)}
            </Button>
            <Button onClick={this.filter} type="primary">
              {freire(texts.SEARCH)}
            </Button>
          </div>
        </div>

        <TablePagination
          style={{ backgroundColor: "white", marginBottom: 8 }}
          loading={loadingTable}
          rowKey={(data) => data.userID}
          dataSource={usersList}
          columns={columns}
          total={totalUsers}
          onPagination={(pagination) =>
            this.setState({ pagination }, () => this.filter(false))
          }
          current={pagination.page}
          bordered
        />

        <ButtonCsvDownload
          loading={csvLoading}
          onClick={() => this.downloadExcel(dataExcel)}
        />

        <UserDetailsAndCharges
          visible={visibleDetailsDrawer}
          user={currentUser}
          onClose={this.closeDrawer}
        />

        {/* carros       */}
        <Drawer
          // TODO: freire
          // TODO: user may not have name
          title={`${this.state.currentUserName} (${this.state.currentUserEmail})`}
          width={500}
          onClose={this.closeDrawer}
          visible={visibleCarsDrawer}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <div>
            {loadingCars ? (
              <Spin
                style={{ display: "block", marginTop: 25 }}
                tip={freire(texts.LOADING_USER_CARS)}
              />
            ) : (
              cars.map((element) => {
                return (
                  <Row gutter={16} key={element._id}>
                    <Col span={12}>
                      {element.model} - {element.brand}
                    </Col>
                  </Row>
                );
              })
            )}
          </div>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <Button onClick={this.closeDrawer}>{freire(texts.CLOSE)}</Button>
          </div>
        </Drawer>
      </div>
    );
  }
}
const PageListUsersWithCharge = Form.create({ name: "filters" })(
  ListUsersWithCharge
);
export default withRouter(PageListUsersWithCharge);
