import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Drawer,
  Switch,
  DatePicker,
  Select,
  Radio,
  notification,
  Divider,
  InputNumber,
} from "antd";
import { withRouter } from "react-router-dom";
import { Show, useAccess } from "../../../contexts/PermissionContext";
import { useFreire } from "../../../utils/freireContext";
import DynamicCompanyForm, { validPlaceTypes } from "../DynamicCompanyForm";
import * as texts from "../locales";
import { formatAvailableCompanies, formatUsageLimitPerPeriod } from "../utils";
import { COUPON_CODE_TYPE } from "../../../services/utils";
import { isPartner as checkIsPartner } from "../../../utils/isPartner";
import BusinessHour from "../../../components/BusinessHour";

const { DIFFERENT } = COUPON_CODE_TYPE;

const { Option } = Select;

const EditDrawer = ({
  visible,
  form,
  campaign,
  loading,
  allCompanies = [],
  allStations = [],
  userCompany,
  onSave,
  onClose,
  companyPartners = [],
}) => {
  const { hasPermission } = useAccess();
  const { freire } = useFreire();
  const [hasPartners, setHasPartners] = useState();

  const [validPeriodsEnabled, setValidPeriodsEnabled] = useState(false);
  const [validPeriodsSaved, setValidPeriodsSaved] = useState(false);

  const requiredFieldRule = {
    required: true,
    message: freire(texts.REQUIRED_FIELD),
  };

  const isPartner = checkIsPartner(
    campaign?.company?._id,
    campaign?.company?.parentCompanyID,
    userCompany?.id
  );

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        ...campaign,
        company: campaign.company?._id || "",
        usageLimitPerPeriod: {
          enabled: campaign?.usageLimitPerPeriod,
          unit: campaign?.usageLimitPerPeriod?.period?.charAt(2),
          value: Number(campaign?.usageLimitPerPeriod?.period?.charAt(1)),
          usageAmount: campaign?.usageLimitPerPeriod?.usageAmount,
        },
        quota: {
          enabled: campaign?.quota,
          totalBalance: campaign?.quota?.totalBalance,
          type: campaign?.quota?.type,
          couponsExpireOnTotalUsage: campaign?.quota?.couponsExpireOnTotalUsage,
          accumulateToNextPeriod: campaign?.quota?.accumulateToNextPeriod,
        },
        campaignPartners: campaign?.campaignPartners || [],
      });
      setHasPartners(campaign?.campaignPartners?.length > 0);
      setValidPeriodsEnabled(campaign?.valid_periods_enabled || false);
    } else {
      form.resetFields();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign, visible]);

  const onClickSave = () => {
    form.validateFields((error, params) => {
      if (error) {
        return;
      }

      params.expireDate = params.expireDate.toISOString();

      if (params.usageLimitPerPeriod?.enabled) {
        params.usageLimitPerPeriod = formatUsageLimitPerPeriod(
          params.usageLimitPerPeriod
        );
      } else {
        params.usageLimitPerPeriod = undefined;
      }

      if (params?.quota?.enabled) {
        delete params.quota.enabled;

        params.quota.accumulateToNextPeriod =
          params.quota?.accumulateToNextPeriod || false;
        params.quota.couponsExpireOnTotalUsage =
          params.quota?.couponsExpireOnTotalUsage || false;
      } else {
        params.quota = undefined;
      }

      const availableCompanies = formatAvailableCompanies(params, userCompany);

      if (!availableCompanies) {
        notification.warning({
          message: freire(texts.ATTENTION),
          description: freire(texts.SPECIFIC_STATIONS_ERROR_MESSAGE),
        });
        return;
      }

      params.availableCompanies = availableCompanies;
      params.companies = undefined;

      onSave(params);
    });
  };

  const { getFieldDecorator } = form;
  const {
    validPlace,
    company: companyId,
    usageLimitPerPeriod = { enabled: false },
    quota = { enabled: false },
  } = form.getFieldsValue();

  const selectedCompany = allCompanies.find(
    (company) => company._id === companyId
  );

  const canSetCouponsToAllStations = hasPermission("set-coupons-all-stations");

  return (
    <Drawer
      title={freire(texts.EDIT_CAMPAIGN)}
      width={"40%"}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form wrapperCol={{ span: 24 }}>
        <span style={{ color: "#fd5876", fontWeight: "bold" }}>
          {freire(texts.CAMPAIGN_INFO)}
        </span>
        <Row gutter={[16, 0]}>
          <Show when={"edit-coupom-company"}>
            <Col span={24}>
              <Form.Item label={freire(texts.COMPANY)}>
                {getFieldDecorator("company")(
                  <Select
                    showSearch
                    optionFilterProp="label"
                    disabled={loading}
                    placeholder={freire(texts.COMPANY)}
                  >
                    {allCompanies.map((company) => (
                      <Option
                        key={company._id}
                        label={company.companyName}
                        value={company._id}
                      >
                        {company.companyName}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Show>
        </Row>
        <Row>
          <Col span={12}>
            <Row>
              <Switch
                disabled={isPartner}
                checkedChildren={freire(texts.YES)}
                unCheckedChildren={freire(texts.NO)}
                checked={hasPartners}
                onChange={(value) => {
                  setHasPartners(value);
                  if (!value) {
                    form.setFieldsValue({ campaignPartners: [] });
                  }
                }}
              />
              <span style={{ marginLeft: 12 }}>
                {freire(texts.HAS_PARTNERSHIP)}
              </span>
            </Row>
          </Col>
          <Col span={12}>
            <Form.Item label={freire(texts.PARTNERSHIP)}>
              {getFieldDecorator("campaignPartners")(
                <Select
                  mode="multiple"
                  placeholder={freire(texts.SELECT_PARTNERSHIP)}
                  showSearch
                  disabled={!hasPartners || isPartner}
                >
                  {companyPartners?.map((part, index) => (
                    <Option
                      key={index}
                      label={part.companyName}
                      value={part._id}
                    >
                      {part.companyName}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item label={freire(texts.CAMPAIGN_NAME)}>
              {getFieldDecorator("name", {
                rules: [requiredFieldRule],
              })(<Input placeholder={freire(texts.CAMPAIGN_NAME_EX)} />)}
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{ margin: "6px 0 24px 0" }} />

        <span style={{ color: "#fd5876", fontWeight: "bold" }}>
          {freire(texts.VALID_PLACES)}
        </span>
        <p style={{ color: "#747277" }}>
          {freire(texts.VALID_PLACES_DESCRIPTION)}
        </p>
        <Row gutter={[16, 0]}>
          <Col span={18}>
            <Form.Item label={null}>
              {getFieldDecorator("validPlace", {
                initialValue: validPlaceTypes.ALL_COMPANIES,
              })(
                <Radio.Group buttonStyle="solid">
                  <Radio
                    disabled={!canSetCouponsToAllStations || isPartner}
                    className="verticalRadio"
                    value={validPlaceTypes.ALL_NETWORK}
                  >
                    {freire(texts.ALL_NETWORK_COMPANIES_AND_STATIONS)}
                  </Radio>
                  <Radio
                    disabled={isPartner}
                    className="verticalRadio"
                    value={validPlaceTypes.ALL_COMPANIES}
                  >
                    <span>
                      {selectedCompany?.holding
                        ? freire(texts.ALL_COMPANIES_AND_STATIONS)
                        : freire(texts.ALL_STATIONS)}
                      {selectedCompany && (
                        <strong> {selectedCompany.companyName}</strong>
                      )}
                    </span>
                  </Radio>
                  <Radio
                    disabled={isPartner}
                    className="verticalRadio"
                    value={validPlaceTypes.SPECIFIC_STATIONS}
                  >
                    {selectedCompany?.holding
                      ? freire(texts.SPECIFIC_COMPANIES_AND_STATIONS)
                      : freire(texts.SPECIFIC_STATIONS)}
                  </Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row
          gutter={[16, 0]}
          style={{
            display:
              validPlace === validPlaceTypes.SPECIFIC_STATIONS
                ? "block"
                : "none",
          }}
        >
          <Col span={24}>
            <DynamicCompanyForm
              form={form}
              allCompanies={allCompanies}
              allStations={allStations}
              campaignToEdit={campaign}
              selectedCompany={selectedCompany}
            />
          </Col>
        </Row>

        <Divider style={{ margin: "6px 0 24px 0" }} />

        <>
          <span className="form-field-title">
            {freire(texts.QUOTA).toUpperCase()}
          </span>
          <Row>
            <Form.Item
              className="custom-form-item"
              labelAlign="left"
              label={freire(texts.CAMPAIGN_HAS_RESTRITIVE_QUOTA)}
            >
              {getFieldDecorator("quota.enabled", {
                valuePropName: "checked",
              })(
                <Switch
                  disabled={isPartner}
                  checkedChildren={freire(texts.YES)}
                  unCheckedChildren={freire(texts.NO)}
                />
              )}
            </Form.Item>
          </Row>

          {quota.enabled && (
            <>
              <Row>
                <Col span={12}>
                  <Form.Item labelAlign="top" label={freire(texts.MODEL)}>
                    {getFieldDecorator("quota.type", {
                      initialValue: campaign?.quota?.type || "unique",
                    })(
                      <Radio.Group
                        disabled={isPartner}
                        className="customRadioGroup campaignQuota"
                        buttonStyle="solid"
                      >
                        <Radio.Button value={"unique"}>
                          {`${freire(texts.UNIQUE_QUOTA)}`}
                        </Radio.Button>
                        <Radio.Button
                          style={{ whiteSpace: "nowrap" }}
                          value={"monthlyRecurring"}
                        >
                          {`${freire(texts.MONTHLY_RENOVATION_QUOTA)}`}
                        </Radio.Button>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Form.Item
                  labelAlign="left"
                  label={freire(texts.AVAIABLE_VALUE)}
                >
                  {getFieldDecorator("quota.totalBalance", {
                    ...(quota.enabled && { rules: [requiredFieldRule] }),
                    initialValue: campaign?.quota?.totalBalance
                      ? String(campaign?.quota?.totalBalance)
                      : undefined,
                  })(
                    <InputNumber
                      disabled={isPartner}
                      formatter={(value) => {
                        if (!value) return "";
                        const numericValue =
                          parseFloat(value.replace(/\D/g, "") || 0) / 100;
                        return Intl.NumberFormat("pt-BR", {
                          minimumFractionDigits: 2,
                        }).format(numericValue);
                      }}
                      parser={(value) => {
                        if (!value) return "";
                        return (
                          parseFloat(value.replace(/\D/g, "") || 0) / 100
                        ).toFixed(2);
                      }}
                      step={0.01}
                      stringMode
                      className="custom-input-number"
                      placeholder={freire(texts.EX_PARAM("3.000,00"))}
                    />
                  )}
                </Form.Item>
              </Row>

              <Row>
                {quota.type === "monthlyRecurring" && (
                  <Form.Item
                    className="custom-form-item"
                    labelAlign="right"
                    label={freire(texts.VALUE_ACCUMULATE_TO_NEXT_PERIOD)}
                  >
                    {getFieldDecorator("quota.accumulateToNextPeriod", {
                      valuePropName: "checked",
                      initialValue:
                        campaign?.quota?.accumulateToNextPeriod || false,
                    })(
                      <Switch
                        checkedChildren={freire(texts.YES)}
                        unCheckedChildren={freire(texts.NO)}
                      />
                    )}
                  </Form.Item>
                )}
              </Row>
            </>
          )}

          <Divider style={{ margin: "6px 0 24px 0" }} />
        </>

        <span style={{ color: "#fd5876", fontWeight: "bold" }}>
          {freire(texts.OTHER_INFO)}
        </span>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label={freire(texts.STATUS_CAMPAIGN)}>
              {getFieldDecorator("enabled", {
                valuePropName: "checked",
              })(
                <Switch
                  checkedChildren={freire(texts.YES)}
                  unCheckedChildren={freire(texts.NO)}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={freire(texts.EXPIRE_DATE_TIME)}>
              {getFieldDecorator("expireDate", {
                rules: [
                  requiredFieldRule,
                  {
                    validator: async (_, value, callback) => {
                      try {
                        if (value && moment().isAfter(value)) {
                          throw new Error(freire(texts.INPUT_DATE_TIME_FUTURE));
                        }
                      } catch (err) {
                        callback(err);
                      }
                    },
                  },
                ],
              })(
                <DatePicker
                  allowClear={false}
                  style={{ width: "100%" }}
                  placeholder={freire(texts.SELECT_DATE_TIME)}
                  format="L LTS"
                  disabledDate={(current) =>
                    current &&
                    current < moment().subtract(1, "day").endOf("day")
                  }
                  showTime={{
                    defaultValue: moment("23:59:59", "HH:mm:ss"),
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col span={16}>
            <Form.Item
              labelAlign="left"
              className="custom-form-item"
              label={freire(texts.VALID_ONLY_IN_DETERMINED_PERIOD)}
            >
              {getFieldDecorator("valid_periods_enabled", {
                valuePropName: "checked",
              })(
                <Switch
                  checkedChildren={freire(texts.YES)}
                  unCheckedChildren={freire(texts.NO)}
                  onChange={(value) => {
                    setValidPeriodsEnabled(value);
                    if (!value) {
                      form.setFieldsValue({ valid_periods: null });
                    }
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        {validPeriodsEnabled && (
          <Row>
            <Form.Item>
              {getFieldDecorator("valid_periods")(
                <BusinessHour
                  form={form}
                  fieldName="valid_periods"
                  isSaved={validPeriodsSaved}
                  setIsSaved={setValidPeriodsSaved}
                  initialWeek={campaign?.valid_periods?.week || null}
                />
              )}
            </Form.Item>
          </Row>
        )}

        {campaign?.benefit?.type !== "4" && (
          <>
            <Row>
              <Col span={8}>
                <Form.Item
                  className="custom-form-item"
                  labelAlign="right"
                  label={freire(texts.CUPON_HAS_USAGE_LIMIT_PER_PERIOD)}
                >
                  {getFieldDecorator("usageLimitPerPeriod.enabled", {
                    valuePropName: "checked",
                    initialValue: usageLimitPerPeriod.enabled,
                  })(
                    <Switch
                      checkedChildren={freire(texts.YES)}
                      unCheckedChildren={freire(texts.NO)}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            {usageLimitPerPeriod.enabled && (
              <Row>
                <Col span={6}>
                  <Form.Item
                    labelAlign="left"
                    label={freire(texts.MAX_QUANTITY)}
                  >
                    {getFieldDecorator("usageLimitPerPeriod.usageAmount", {
                      initialValue:
                        campaign?.usageLimitPerPeriod?.usageAmount || "",
                    })(
                      <InputNumber
                        min={1}
                        placeholder={freire(texts.EX_PARAM("50"))}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col className="custom-column" span={5}>
                  <Form.Item
                    labelAlign="left"
                    label={freire(texts.TIME_PERIOD)}
                  >
                    {getFieldDecorator("usageLimitPerPeriod.value", {
                      initialValue:
                        Number(
                          campaign?.usageLimitPerPeriod?.period?.charAt(1)
                        ) || "",
                    })(
                      <div className="time-period-filed-wrapper">
                        <span>{freire(texts.FOR_EACH)}</span>
                        <InputNumber
                          value={usageLimitPerPeriod?.value}
                          min={1}
                          max={usageLimitPerPeriod.unit === "D" ? 31 : 12}
                          placeholder={freire(texts.EX_PARAM("1"))}
                        />
                      </div>
                    )}
                  </Form.Item>

                  <Form.Item colon={false} label=" ">
                    {getFieldDecorator("usageLimitPerPeriod.unit", {
                      initialValue:
                        usageLimitPerPeriod?.period?.charAt(2) || "M",
                    })(
                      <Radio.Group
                        className="customRadioGroup"
                        buttonStyle="solid"
                      >
                        <Radio.Button value={"D"}>
                          {`${freire(texts.DAY)}${
                            usageLimitPerPeriod.value > 1 ? "s" : ""
                          }`}
                        </Radio.Button>
                        <Radio.Button value={"W"}>
                          {`${freire(texts.WEEK)}${
                            usageLimitPerPeriod.value > 1 ? "s" : ""
                          }`}
                        </Radio.Button>
                        <Radio.Button value={"M"}>
                          {`${
                            usageLimitPerPeriod.value > 1
                              ? freire(texts.MONTHS).charAt(0).toUpperCase() +
                                freire(texts.MONTHS).slice(1)
                              : freire(texts.MONTH)
                          }`}
                        </Radio.Button>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            )}
          </>
        )}

        {campaign?.codeType === DIFFERENT && (
          <Row>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelAlign="right"
                label={freire(texts.EACH_USER_CAN_LINK_MORE_THAN_ONE_CODE)}
              >
                {getFieldDecorator("couponsMultipleLink", {
                  valuePropName: "checked",
                })(
                  <Switch
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        )}

        <Divider style={{ margin: "6px 0 24px 0" }} />

        <span style={{ color: "#fd5876", fontWeight: "bold" }}>
          {freire(texts.NOTIFICATION)}
        </span>
        <p style={{ color: "#747277" }}>
          {freire(texts.NOTIFICATION_DESCRIPTION)}
        </p>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item label={freire(texts.TITLE)}>
              {getFieldDecorator("title")(
                <Input placeholder={freire(texts.TITLE_PLACEHOLDER)} />
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={freire(texts.MESSAGE)}>
              {getFieldDecorator("message")(
                <Input placeholder={freire(texts.MESSAGE_PLACEHOLDER)} />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
          width: "100%",
          borderTop: "1px solid #e9e9e9",
          padding: "10px 16px",
          background: "#fff",
          textAlign: "right",
        }}
      >
        <Button onClick={onClose} style={{ marginRight: 8 }}>
          {freire(texts.CANCEL)}
        </Button>
        <Button loading={loading} onClick={onClickSave} type="primary">
          {freire(texts.SAVE)}
        </Button>
      </div>
    </Drawer>
  );
};

const ComponentEditDrawer = Form.create({ name: "edit" })(EditDrawer);
export default withRouter(ComponentEditDrawer);
