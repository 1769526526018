import { FormComponentProps } from "antd/lib/form";

export const shouldShowPricePerHour = (
  form: FormComponentProps["form"]
): boolean => {
  const validPaymentCharge =
    form.getFieldValue("paymentCharge.enabled") &&
    form.getFieldValue("paymentCharge.method") !== "" &&
    form.getFieldValue("paymentCharge.method") !== "Time";

  return validPaymentCharge;
};
