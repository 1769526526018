import React, { Component, useState } from "react";
import { Button, Form, Row, Col, Icon, message } from "antd";
import { FreireContext } from "../../../utils/freireContext";
import api from "../../../services/api";
import { sorter } from "../../../utils/sorter";
import SelectCountry from "../../../components/SelectCountry";
import * as texts from "../locales";
import { COUNTRY_BR } from "../../../constants/countries";
import Breadcrumbs from "../../../components/Breadcrumbs";
import SelectPayment from "../../../components/SelectPayment";
import ValidPlaces from "../../Coupons/NewCoupons/Forms/ValidPlaces";
import { getCompanyCurrentUser } from "../../../services/auth";
import { useOnMount } from "../../../hooks";
import { formatters } from "../../../utils/formatters";
import "./index.css";
import { NewCampaignContext } from "../../Coupons/NewCoupons/context";
import { validPlaceTypes } from "../../Coupons/DynamicCompanyForm.js";
import { sleep } from "../../../utils/sleep";
import ModalBatchOperation from "./ModalBatchOperation";
import { BRAND } from "../../../config";
import Show from "../../../contexts/PermissionContext/Show";
import { WayToChargeIdleFee } from "../../../constants/station";
import { IdleFeeBatchOperation } from "../../../components/IdleFeeBatchOperation";
import {
  ActivationFee,
  disabled_activation_fee,
} from "../../../components/ActivationFee";
import {
  orderPrices,
  validateOverlappingRangesInPricePerHour,
} from "../../../components/StationPricePerHour/validators";
import { shouldShowPricePerHour } from "../../../utils/get-price-per-hour-infos";

const EMPTY_COUNTRY = Symbol("EMPTY_COUNTRY");
export const disabledIdleFee = {
  idleFee: {
    enabled: false,
    value: 0,
    gracePeriod: "",
    chargePeriod: "",
    wayToCharge: WayToChargeIdleFee.NONE,
  },
};

const CountryPai = ({ Component, ...props }) => {
  const [country, setCountry] = useState(EMPTY_COUNTRY);

  const changeCountry = (country) => {
    setCountry(country);
  };

  return (
    <Component country={country} changeCountry={changeCountry} {...props} />
  );
};

const Pai = ({ form, Component, propss }) => {
  const [userCompany, setUserCompany] = useState("");
  const [allCompanies, setAllCompanies] = useState([]);
  const [allStations, setAllStations] = useState([]);

  const getAllCompanies = async () => {
    const { data } = await api.get("company");

    setAllCompanies(data);
  };

  const getAllStations = async () => {
    const { data } = await api.get(`select/connected_stations`);

    setAllStations(data);
  };

  const getUserCompany = async () => {
    const company = await getCompanyCurrentUser();

    setUserCompany(company);
  };

  useOnMount(() => {
    getUserCompany();
    getAllCompanies();
    getAllStations();
  });

  const _allCompanies = allCompanies ?? [];
  const _allStations = allStations ?? [];

  const selectedCompany = userCompany?.id
    ? _allCompanies.find((company) => company._id === userCompany.id) ?? null
    : null;

  const getValidPlaces = () => {
    const fieldValues = {
      valid: true,
      ...JSON.parse(JSON.stringify(form.getFieldsValue() ?? {})),
    };

    const getStationsByCompanyId = (companyId) => {
      // TODO: check country
      const parentAndChildCompanies = [
        companyId,
        ..._allCompanies
          .filter((company) => company.parentCompanyID === companyId)
          .map((company) => company._id),
      ];

      return _allStations.filter((station) =>
        parentAndChildCompanies.includes(station.companyID)
      );
    };

    const getStationIdByStation = (station) => ({
      _id: station._id,
      stationID: station.stationID,
    });

    // README: do not use switch case
    if (fieldValues.validPlace === validPlaceTypes.ALL_COMPANIES) {
      const companyId = selectedCompany?._id ?? null;
      const stations = getStationsByCompanyId(companyId);

      fieldValues.companies = [
        {
          companyId,
          stationIds: stations.map(getStationIdByStation),
        },
      ];

      if (companyId === BRAND) {
        fieldValues.valid = false;
      }
    } else if (fieldValues.validPlace === validPlaceTypes.SPECIFIC_STATIONS) {
      const _companies = (fieldValues.companies ?? []).filter(
        (company) => company instanceof Object
      );

      if (_companies.length === 0) {
        fieldValues.valid = false;
      }

      for (const company of _companies) {
        if (company.specificStations) {
          const _stationIDs = (company.stationIds ?? []).filter(
            (station) => typeof station === "string"
          );

          company.stations = _stationIDs.map((stationId) =>
            _allStations.find((station) => station._id === stationId)
          );
        } else {
          company.stations = getStationsByCompanyId(company.companyId);
        }

        if (company.stations.length === 0) {
          fieldValues.valid = false;
        }

        delete company.specificStations;

        // TODO: only mongo id
        // company.stationIds = (company.stations ?? []).map(
        //   (station) => station._id
        // );
        company.stationIds = (company.stations ?? []).map(
          getStationIdByStation
        );
        delete company.stations;
      }

      fieldValues.companies = _companies;
    } else {
      // validPlaceTypes.ALL_NETWORK
      fieldValues.companies = [];
      fieldValues.valid = false;
    }

    delete fieldValues.validPlace;

    fieldValues.stations = fieldValues.companies
      .map((company) => company.stationIds)
      // TODO?: check station
      .flat()
      .map((stationIdAndID) =>
        _allStations.find((station) => station._id === stationIdAndID._id)
      )
      .filter((station) => station.country === propss.country)
      .map((station) => ({ _id: station._id, stationID: station.stationID }));

    if (fieldValues.stations.length === 0) {
      fieldValues.valid = false;
    }

    // TODO: uncomment
    // delete fieldValues.companies;
    // return fieldValues.stations;

    return fieldValues;
  };

  const validPlaces = getValidPlaces();

  return (
    <NewCampaignContext.Provider
      value={{
        form,
        userCompany,
        allCompanies: _allCompanies,
        allStations: _allStations,
        selectedCompany,
      }}
    >
      <Component {...propss} form={form} validPlaces={validPlaces} />
    </NewCampaignContext.Provider>
  );
};

class BatchOperationForm extends Component {
  static contextType = FreireContext;

  state = {
    showSelectPayment: false,
    loading: false,
    companies: [],
    loadingPaymentChargeValue: false,
    isModalOpen: false,
    keepIdleFeeValue: true,
    keepActivationFeeValue: true,
    stations: [],
  };

  resetIdleFee = () => {
    this.props.form.setFieldsValue({
      idleFee: {
        enabled: undefined,
        gracePeriod: undefined,
        value: undefined,
      },
    });
    this.setState({ keepIdleFeeValue: true });
  };

  resetActivationFee = () => {
    this.props.form.setFieldsValue({
      activation_fee: {
        enabled: undefined,
        value: undefined,
        exemption_time_limit_seconds: undefined,
        exemption_lower_charge_value: undefined,
        exemption_lower_stop_value: undefined,
        exemption_by_consumption_enabled: undefined,
        exemption_by_consumption_value: undefined,
      },
    });
    this.setState({ keepActivationFeeValue: true });
  };

  handleIdleFeeChange = (keep) => {
    this.setState({ keepIdleFeeValue: keep });
    if (keep) {
      this.props.form.getFieldsValue().paymentCharge?.enabled === false
        ? this.props.form.setFieldsValue(disabledIdleFee)
        : this.resetIdleFee();
    } else {
      this.props.form.setFieldsValue(disabledIdleFee);
    }
  };

  handleActivationFeeChange = (keep) => {
    this.setState({ keepActivationFeeValue: keep });
    if (keep) {
      this.props.form.getFieldsValue().paymentCharge?.enabled === false
        ? this.props.form.setFieldsValue(disabled_activation_fee)
        : this.resetActivationFee();
    } else {
      this.props.form.setFieldsValue(disabled_activation_fee);
    }
  };

  handleShowSelectPaymentChange = (checked) => {
    this.setState({ showSelectPayment: checked });
  };

  openModal = () => {
    const { freire } = this.context;

    this.props.form.validateFields(async (error, station) => {
      if (error) {
        return;
      }
      const { paymentCharge, idleFee, price_per_hour_enable, price_per_hour } =
        station;

      if (
        !this.props.validPlaces?.valid ||
        (this.props.validPlaces?.stations ?? []).length === 0
      ) {
        message.warning(freire(texts.ERROR_SELECT_STATIONS));
        return;
      }

      if (paymentCharge) {
        if (paymentCharge.enabled && paymentCharge.method === "") {
          message.warning(freire(texts.ERROR_INFO_CHARGE_PAYMENT_METHOD));
          return;
        }

        station.paymentCharge.value = Number(
          station.paymentCharge?.value?.replace(/[.,]/g, "")
        );

        if (paymentCharge.enabled && paymentCharge.value === 0) {
          message.warning(freire(texts.ERROR_INFO_CHARGE_PAYMENT));
          return;
        }

        if (
          paymentCharge?.method === "Time" &&
          (!paymentCharge.period ||
            !parseInt(paymentCharge.perTime) > 0 ||
            !paymentCharge.timeWindow)
        ) {
          message.warning(freire(texts.ERROR_INFO_CHARGE_PAYMENT_METHOD));
          return;
        }
      }

      if (
        idleFee.enabled &&
        (!idleFee.gracePeriod || idleFee.value === "0,00")
      ) {
        message.warning(freire(texts.ERROR_IDLE_FEE_INFO));
        return;
      }

      if (price_per_hour_enable) {
        const prices = orderPrices(price_per_hour);
        const valid = validateOverlappingRangesInPricePerHour(prices);

        if (valid) {
          message.warning(
            freire(texts.ERROR_PRICE_PER_HOUR_OVERLAPPING_RANGES)
          );
          return;
        }

        this.props.form.setFieldsValue({ price_per_hour: prices });
      }
      this.setState({ isModalOpen: true });
    });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  async componentDidMount() {
    try {
      const { data: companies } = await api.get("company");
      const companiesNotHolding = sorter(
        companies.filter((company) => !company.holding),
        "companyName"
      );
      const { data: plugs } = await api.get(`plugs`);

      this.setState({ companies: companiesNotHolding, plugs });

      await sleep(1000);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  componentDidUpdate(prevProps) {
    const { companies } = this.state;
    const userHasOnlyOneCompany = companies.length === 1;
    const initialCountry = userHasOnlyOneCompany
      ? companies[0].country
      : COUNTRY_BR;

    // atualiza o país apenas se o usuário estiver em uma empresa com visibilidade específica
    if (userHasOnlyOneCompany && this.props.country !== initialCountry) {
      this.props.changeCountry(initialCountry);
    }

    // se estamos em uma conta geral, mantém o país que o usuário selecionou
    if (!userHasOnlyOneCompany && prevProps.country !== this.props.country) {
      this.setState({
        selectedStations: this.getStationsByCountry(this.props.country),
      });
    }
  }

  // método auxiliar para filtrar as estações por país selecionado
  getStationsByCountry(country) {
    return this.state.stations.filter((station) => station.country === country);
  }

  render() {
    const { loading } = this.state;

    const {
      companies,
      // allCompanies,
      // paymentCharge,
    } = this.state;
    const {
      paymentCharge,
      acceptCoupon,
      posPayment,
      idleFee,
      activation_fee,
      price_per_hour_enable,
      price_per_hour,
    } = this.props.form.getFieldsValue();

    const { freire } = this.context;
    const { validPlaces } = this.props;
    const { getFieldDecorator, setFieldsValue } = this.props.form;
    const selectedCompany = companies.find(
      (company) => company.country === this.props.form.getFieldValue("country")
    );
    const selectedCompanyCurrency = formatters.currency(
      freire.userLanguage,
      selectedCompany?.currency
    )(null);
    const userHasOnlyOneCompany = companies.length === 1;
    const initialCountry = userHasOnlyOneCompany
      ? companies[0].country
      : COUNTRY_BR;

    if (this.props.country === EMPTY_COUNTRY) {
      this.props.changeCountry(initialCountry);
    }

    if (paymentCharge?.enabled === false) {
      if (idleFee?.enabled) setFieldsValue(disabledIdleFee);
      if (activation_fee?.enabled) setFieldsValue(disabled_activation_fee);
    }

    const showPricePerHourEnabledOption = shouldShowPricePerHour(
      this.props.form
    );

    return (
      <Show when={"batch-operation"}>
        <div>
          <div className="container">
            <Breadcrumbs
              breadcrumbs={[
                freire(texts.ADMIN),
                freire(texts.STATIONS),
                freire(texts.BATCH_OPERATION),
              ]}
            />
          </div>

          <div className="filter-modal-batch">
            <Icon
              type="profile"
              style={{
                color: "#1890FF",
                marginRight: 15,
                paddingTop: 4,
                fontSize: 20,
              }}
            />
            <div className="update-info">
              <span className="span-update" style={{ fontSize: 14 }}>
                {freire(texts.UPDATE_BATCH_PRICES)}
              </span>
              <span
                className="span-update"
                style={{ color: "#747277", fontSize: 14 }}
              >
                {freire(texts.UPDATE_BATCH_PRICES_DESCRIPTION)}
              </span>
            </div>

            <div className="container">
              <span
                style={{
                  color: "#fd5876",
                  fontWeight: "bold",
                }}
              >
                {freire(texts.SELECT_STATIONS)}
              </span>
              <Form>
                <Row gutter={16}>
                  <Col span={12}>
                    <SelectCountry
                      form={this.props.form}
                      label={texts.SELECT_COUNTRY_ESTATIONS}
                      disabled={userHasOnlyOneCompany}
                      initialValue={initialCountry}
                      showWarn={false}
                      onSelect={this.props.changeCountry}
                    />

                    <ValidPlaces
                      hideAllNetwork
                      sameCompany
                      currency={selectedCompany?.currency}
                    />
                  </Col>
                </Row>

                <span style={{ color: "#fd5876", fontWeight: "bold" }}>
                  {freire(texts.UPDATE_CHARGE_METHOD)}
                </span>
                <span
                  style={{
                    color: "#747277",
                    display: "block",
                    marginBottom: "20px",
                  }}
                >
                  {freire(texts.UPDATE_CHARGE_METHOD_DESCRIPTION)}
                </span>

                <SelectPayment
                  getFieldDecorator={getFieldDecorator}
                  isLoading={loading}
                  forms={this.props.form}
                  paymentCharge={paymentCharge}
                  selectedCompanyCurrency={selectedCompanyCurrency}
                  loadingPaymentChargeValue={this.loadingPaymentChargeValue}
                  idleFee={idleFee}
                  keepIdleFeeValue={this.state.keepIdleFeeValue}
                  resetIdleFee={this.resetIdleFee}
                  activation_fee={activation_fee}
                  keepActivationFeeValue={this.state.keepActivationFeeValue}
                  resetActivationFee={this.resetActivationFee}
                  showPricePerHourEnabledOption={showPricePerHourEnabledOption}
                />

                <IdleFeeBatchOperation
                  loading={loading}
                  keepIdleFeeValue={this.state.keepIdleFeeValue}
                  idleFee={idleFee}
                  getFieldDecorator={getFieldDecorator}
                  forms={this.props.form}
                  handleIdleFeeChange={this.handleIdleFeeChange}
                  texts={texts}
                  freire={freire}
                />

                <ActivationFee
                  form={this.props.form}
                  loading={loading}
                  selectedCompanyCurrency={selectedCompanyCurrency}
                  bacthOperation
                  activation_fee={activation_fee}
                  keepActivationFeeValue={this.state.keepActivationFeeValue}
                  handleActivationFeeChange={this.handleActivationFeeChange}
                />

                <Button
                  style={{ float: "right" }}
                  type="primary"
                  onClick={this.openModal}
                >
                  {freire(texts.SAVE)}
                </Button>

                <ModalBatchOperation
                  stations={validPlaces.stations}
                  freire={freire}
                  isOpen={this.state.isModalOpen}
                  closeModal={this.closeModal}
                  getStationsAudit={this.getStationsAudit}
                  setLoadingSave={this.setLoadingSave}
                  paymentCharge={paymentCharge}
                  country={this.props.country}
                  acceptCoupon={acceptCoupon}
                  posPayment={posPayment}
                  idleFee={idleFee}
                  activation_fee={activation_fee}
                  price_per_hour_enable={price_per_hour_enable}
                  price_per_hour={price_per_hour}
                />
              </Form>
            </div>
          </div>
        </div>
      </Show>
    );
  }
}

const PaiComForm = Form.create({ name: "inputs" })(Pai);

const One = (props) => (
  <PaiComForm Component={BatchOperationForm} propss={props} />
);
const Two = (props) => <CountryPai Component={One} {...props} />;

export default Two;
