import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Spin, notification, Tabs } from "antd";
import { Show } from "../../../contexts/PermissionContext";
import Details from "../ListUsersWithCharge/Drawers/UserDetailsAndCharges/Details";
import Coupons from "../ListUsersWithCharge/Drawers/UserDetailsAndCharges/Coupons";
import { Tags } from "../ListUsersWithCharge/Drawers/UserDetailsAndCharges/Tags";
import Charges from "../ListUsersWithCharge/Drawers/UserDetailsAndCharges/Charges";
import api from "../../../services/api";
import { useFreire } from "../../../utils/freireContext";
import * as texts from "../locales";
import "./index.css";
import { useQuery } from "../../../hooks/query";

const UserDetails = () => {
  const { userID } = useParams();
  const { freire } = useFreire();
  const searchParams = useQuery();
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState(null);

  const getUserDetails = useCallback(async () => {
    debugger;
    const user_id = searchParams.get("user_id");
    try {
      setLoading(true);
      const { data } = await api.get(`admin/user/${userID}?user_id=${user_id}`);

      setUserDetails(data);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_GET_DETAILS_USER),
      });
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID, searchParams]);

  useEffect(() => {
    if (!userDetails) {
      getUserDetails();
    }
  }, [getUserDetails, userDetails]);

  if (loading) {
    return (
      <div
        style={{ display: "flex", justifyContent: "center", padding: "50px" }}
      >
        <Spin size="large" tip={freire(texts.LOADING_USER)} />
      </div>
    );
  }

  if (!userID) {
    return null;
  }

  return (
    <Show when={"show-user-details"}>
      <div className="user-details-container" style={{ padding: "0 24px" }}>
        <Tabs>
          <Tabs.TabPane tab={freire(texts.PERSONAL_DATA)} key="personalData">
            <Show when={"show-user-personal-data"}>
              <Details userID={userID} opened={true} />
            </Show>
          </Tabs.TabPane>
          <Tabs.TabPane tab={freire(texts.CHARGES)} key="charges">
            <Charges userID={userID} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={freire(texts.ASSOCIATECUPONS_DATA)} key="coupons">
            <Coupons
              userData={userDetails}
              loadingModal={loading}
              opened={true}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={freire(texts.TAGS_TITLE)} key="tags">
            <Tags
              userData={userDetails}
              loadingModal={loading}
              getUserDetails={getUserDetails}
              opened={true}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Show>
  );
};

export default UserDetails;
