import { getUserAdminData } from "./adminData";
import api from "./api";
import firebase from "./firebaseConfig";

export const isAuthenticated = async () => {
  return !firebase.auth().currentUser !== null;
};

export const getCompanyCurrentUser = async () => {
  const user = firebase.auth().currentUser;

  if (user) {
    const userAdminData = await getUserAdminData(user.email);

    if (userAdminData && userAdminData?.company_id) {
      return {
        id: userAdminData?.company_id,
        parentCompanyID: userAdminData?.parent_company_id,
        name: userAdminData?.company_name,
      };
    }
  }

  return null;
};

export const getUserPermissions = async () => {
  try {
    const { data } = await api.get("/auth/permissions");

    if (data?.permissions) {
      const objPermissions = {};
      data.permissions.forEach((p) => (objPermissions[p] = true));

      return objPermissions;
    }

    return {};
  } catch (error) {}
};