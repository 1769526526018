export const ACTIVATE_ACTIVATION_FEE = {
  pt: "Ativar taxa de ativação",
  es: "Activar tarifa de activación",
  en: "Activate activation fee",
};

export const ACTIVATION_FEE_VALUE = {
  pt: "Custo da ativação",
  es: "Costo de activación",
  en: "Activation fee value",
};

export const BUTTON_KEEP = {
  pt: "Manter atual",
  es: "Mantener actual",
  en: "Keep current",
};

export const BUTTON_CHANGE = {
  pt: "Modificar",
  es: "Cambiar",
  en: "Change",
};

export const YES = {
  pt: "Sim",
  es: "SÍ",
  en: "Yes",
};

export const NO = {
  pt: "Não",
  es: "No",
  en: "No",
};

export const REQUIRED_FIELD = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};

export const ACTIVATION_FEE_KWH_VALUE_EXEMPTION = {
  pt: "Carga mínima para cobrança (kWh)",
  es: "Carga mínima para cobranza (kWh)",
  en: "Minimum charge (kWh)",
};

export const ACTIVATION_FEE_CHARGE_VALUE_EXEMPTION = (currency: string) => ({
  pt: `Preço mínimo da carga para cobrança (${currency})`,
  es: `Preço mínimo de la carga para cobranza (${currency})`,
  en: `Minimum charge value (${currency})`,
});

export const NO_ACTIVATION_FEE = {
  pt: "Sem taxa de ativação",
  es: "Sin tarifa de activación",
  en: "No activation fee",
};

export const CURRENT_ACTIVATION_FEE = {
  pt: "Taxa de ativação atual",
  es: "Tarifa de activación actual",
  en: "Current activation fee",
};

export const EXEMPT_ACTIVATION_FEE_BY_CONSUMPTION = {
  pt: "Isentar taxa de ativação por consumo",
  es: "Eximir tarifa de activación por consumo",
  en: "Exempt activation fee by consumption",
};

export const EXEMPT_ACTIVATION_FEE_BY_CONSUMPTION_TOOLTIP = {
  pt: "Quando o usuário realiza a carga do valor de kWh predefinido, a taxa de ativação não é aplicada.",
  es: "Cuando el usuario realiza la carga del valor de kWh predefinido, la tarifa de activación no se aplica.",
  en: "When the user charges the predefined kWh value, the activation fee is not applied.",
};

export const CONSUMPTION_KWH_LABEL = {
  pt: "Consumo (kWh)",
  es: "Consumo (kWh)",
  en: "Consumption (kWh)",
};

export const ADD_STATION_PRICE_PER_HOUR = {
  pt: "Adicionar horário com preço customizado",
  es: "Agregar horario con precio personalizado",
  en: "Add hour with custom price",
};

export const PRICE_PER_HOUR_VALUE = (currency: string) => ({
  pt: `Valor (${currency})`,
  es: `Valor (${currency})`,
  en: `Value (${currency})`,
});

export const OF = {
  pt: "Das",
  es: "De",
  en: "Of",
};

export const TO = {
  pt: "As",
  es: "A",
  en: "To",
};

export const WARNING_START_TIME_AFTER_END_TIME = {
  pt: "A hora de início não pode ser posterior à hora de término.",
  es: "La hora de inicio no puede ser posterior a la hora de finalización.",
  en: "Start time cannot be after end time.",
};

export const WARNING_PRICE_PER_HOUR_VALUE = {
  pt: "O valor deve ser maior que 0,00",
  es: "El valor debe ser mayor que 0,00",
  en: "The value must be greater than 0.00",
};

export const WARNING_ACTIVATION_FEE_BATCH_OPERATION = {
  pt: "Estações que não tiverem cobrança na carga não poderão ter a taxa de ativação habilitada.",
  es: "Las estaciones que no tengan cobro en la carga no podrán tener la tarifa de activación habilitada.",
  en: "Stations that do not have a charge fee will not be able to have the activation fee enabled.",
};

export const DEFAULT_PRICE_PER_HOUR_VALUE = (
  method: string,
  currency: string
) => ({
  pt: `Valor ${method} padrão (${currency})`,
  es: `Valor ${method} estándar (${currency})`,
  en: `Standard ${method} value (${currency})`,
});

export const ACTIVATION_FEE_VALUE_WITH_CURRENCY = (currency: string) => ({
  pt: `Custo da ativação (${currency})`,
  es: `Costo de activación (${currency})`,
  en: `Activation fee value (${currency})`,
});
