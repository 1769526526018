import React from "react";

export const Gift = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.4546 15H6.45459V27C6.45459 31.5 7.95459 33 12.4546 33H24.4546C28.9546 33 30.4546 31.5 30.4546 27V15Z"
      stroke="currentColor"
      strokeWidth="2.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.75 10.5V12C32.75 13.65 31.955 15 29.75 15H7.25C4.955 15 4.25 13.65 4.25 12V10.5C4.25 8.85 4.955 7.5 7.25 7.5H29.75C31.955 7.5 32.75 8.85 32.75 10.5Z"
      stroke="currentColor"
      strokeWidth="2.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.9601 7.49991H9.68017C9.17017 6.94491 9.18517 6.08991 9.72517 5.54991L11.8552 3.41991C12.4102 2.86491 13.3252 2.86491 13.8802 3.41991L17.9601 7.49991Z"
      stroke="currentColor"
      strokeWidth="2.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.3054 7.49991H19.0254L23.1054 3.41991C23.6604 2.86491 24.5754 2.86491 25.1304 3.41991L27.2604 5.54991C27.8004 6.08991 27.8154 6.94491 27.3054 7.49991Z"
      stroke="currentColor"
      strokeWidth="2.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9102 15V22.71C13.9102 23.91 15.2302 24.615 16.2351 23.97L17.6451 23.04C18.1551 22.71 18.8001 22.71 19.2951 23.04L20.6301 23.94C21.6201 24.6 22.9551 23.895 22.9551 22.695V15H13.9102Z"
      stroke="currentColor"
      strokeWidth="2.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
