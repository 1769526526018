import ListAlertGroups from "../AlertGroup/ListAlertGroups";
import NewAlertGroup from "../AlertGroup/NewAlertGroup";
import ListChargesHistory from "../ChargesHistory/ListChargesHistory";
import ListCoupons from "../Coupons/ListCoupons";
import CouponsViewer from "../Coupons/ListCoupons/CouponsViewer";
import NewCoupons from "../Coupons/NewCoupons";
import Actions from "../OCPP/Actions";
import Logs from "../OCPP/Logs";
import OcppStations from "../OCPP/OcppStations";
import Tags from "../OCPP/Tags";
import Transactions from "../OCPP/Transactions";
import Transaction from "../OCPP/Transactions/Transaction";
import ListPaymentsHistory from "../PaymentsHistory/ListPaymentsHistory";
import ListPermissionGroups from "../PermissionGroup/ListPermissionGroups";
import NewPermissionGroup from "../PermissionGroup/NewPermissionGroup";
import ListPlugs from "../Plugs/ListPlugs";
import NewPlug from "../Plugs/NewPlug";
import OkrPowerbi from "../OkrPowerbi";
import ListPublicitys from "../Publicity/ListPublicitys";
import NewPublicity from "../Publicity/NewPublicity";
import ListReportGroups from "../ReportGroup/ListReportGroups";
import NewReportGroup from "../ReportGroup/NewReportGroup";
import ListRfidCampaigns from "../RfidCampaigns/ListRfidCampaigns";
import NewRfidCampaign from "../RfidCampaigns/NewRfidCampaign";
import AuditStations from "../Stations/AuditStations";
import AvailabilityStations from "../Availability/Stations";
import AvailabilityStationsReal from "../Availability/StationsReal";
import AvailabilitySystem from "../Availability/System";
import ListConnectedStations from "../Stations/ConnectedStations";
import ListStations from "../Stations/ListStations";
import NewStation from "../Stations/NewStation";
import ListCompanies from "../Companies/ListCompanies";
import ListRecipient from "../Users/ListRecipient";
import ListUsers from "../Users/ListUsers";
import ListUsersWithCharge from "../Users/ListUsersWithCharge";
import UserDetails from "../Users/UserDetails";
import NewCompany from "../Companies/NewCompany";
import newRecipient from "../Users/newRecipient";
import ListVisibilityGroup from "../VisibilityGroup/ListVisibilityGroup";
import NewVisibilityGroup from "../VisibilityGroup/NewVisibilityGroup";
import Home from "./Home";
import Docs from "../Docs";
import BatchOperation from "../Stations/BatchOperation/index";
import PageListLocations from "../Locations/ListLocations";
import NewLocation from "../Locations/NewLocation";
import ProposalList from "../Proposal/List";
import ProposalCreation from "../Proposal/Creation";
import ProposalEdit from "../Proposal/Edit";

const RoutesDashboardConfig = (matchUrl) => {
  return [
    {
      permission: "menu-dashboard",
      path: `${matchUrl}/`,
      exact: true,
      component: Home,
    },
    {
      permission: "menu-stations-not-connected",
      path: `${matchUrl}/list_stations`,
      exact: true,
      component: ListStations,
    },
    {
      permission: "menu-stations-connected",
      path: `${matchUrl}/connected_stations`,
      exact: true,
      component: ListConnectedStations,
    },
    {
      permission: "add-station-not-connected",
      path: `${matchUrl}/new_station`,
      exact: true,
      component: NewStation,
    },
    {
      permission: "add-station-connected",
      path: `${matchUrl}/new_station_connected`,
      exact: true,
      component: NewStation,
    },
    {
      permission: "batch-operation",
      path: `${matchUrl}/batch-operation`,
      exact: true,
      component: BatchOperation,
    },
    {
      permission: "menu-stations-audit",
      path: `${matchUrl}/audit_stations`,
      exact: true,
      component: AuditStations,
    },
    {
      permission: "menu-visibility-groups",
      path: `${matchUrl}/visibility_group`,
      exact: true,
      component: ListVisibilityGroup,
    },
    {
      permission: "menu-visibility-new-group",
      path: `${matchUrl}/new_visibility_group`,
      exact: true,
      component: NewVisibilityGroup,
    },
    {
      permission: "menu-users",
      path: `${matchUrl}/users`,
      exact: true,
      component: ListUsersWithCharge,
    },
    {
      permission: "menu-users",
      path: `${matchUrl}/users/:userID`,
      exact: true,
      component: UserDetails,
    },
    {
      permission: "menu-advertising",
      path: `${matchUrl}/advertising`,
      exact: true,
      component: ListPublicitys,
    },
    {
      permission: "add-advertising",
      path: `${matchUrl}/new_advertising`,
      exact: true,
      component: NewPublicity,
    },
    {
      permission: "menu-admin-users",
      path: `${matchUrl}/admin_users`,
      exact: true,
      component: ListUsers,
    },
    {
      permission: "menu-admin-companies",
      path: `${matchUrl}/companys`,
      exact: true,
      component: ListCompanies,
    },
    {
      permission: "add-company",
      path: `${matchUrl}/new_company`,
      exact: true,
      component: NewCompany,
    },
    {
      permission: "menu-admin-recipients",
      path: `${matchUrl}/recipients`,
      exact: true,
      component: ListRecipient,
    },
    {
      permission: "add-recipient",
      path: `${matchUrl}/new_recipient`,
      exact: true,
      component: newRecipient,
    },
    {
      permission: "menu-admin-plugs",
      path: `${matchUrl}/plugs`,
      exact: true,
      component: ListPlugs,
    },
    {
      permission: "add-plug",
      path: `${matchUrl}/new_plug`,
      exact: true,
      component: NewPlug,
    },
    {
      permission: "menu-admin-okr-powerbi",
      path: `${matchUrl}/okr-powerbi`,
      exact: true,
      component: OkrPowerbi,
    },
    {
      permission: "menu-admin-permissions",
      path: `${matchUrl}/permission_groups`,
      exact: false,
      component: ListPermissionGroups,
    },
    {
      permission: "add-permission-group",
      path: `${matchUrl}/new_permission_group`,
      exact: false,
      component: NewPermissionGroup,
    },
    {
      permission: "menu-reports-groups",
      path: `${matchUrl}/report_groups`,
      exact: true,
      component: ListReportGroups,
    },
    {
      permission: "menu-reports-new-group",
      path: `${matchUrl}/new_report_group`,
      exact: true,
      component: NewReportGroup,
    },
    {
      permission: "menu-histories-charges",
      path: `${matchUrl}/charges_history`,
      exact: true,
      component: ListChargesHistory,
    },
    {
      permission: "menu-histories-payments",
      path: `${matchUrl}/payment_history`,
      exact: true,
      component: ListPaymentsHistory,
    },
    {
      permission: "menu-coupons",
      path: `${matchUrl}/coupons`,
      exact: true,
      component: ListCoupons,
    },
    {
      permission: "show-cupons-list",
      path: `${matchUrl}/coupons/:campaignId`,
      exact: true,
      component: CouponsViewer,
    },
    {
      permission: "add-cupons",
      path: `${matchUrl}/new_coupons`,
      exact: true,
      component: NewCoupons,
    },
    {
      permission: "menu-tags-rfid",
      path: `${matchUrl}/rfid-campaigns`,
      exact: true,
      component: ListRfidCampaigns,
    },
    {
      permission: "add-tags-rfid",
      path: `${matchUrl}/new-rfid-campaign`,
      exact: true,
      component: NewRfidCampaign,
    },
    {
      permission: "menu-stations-availability",
      path: `${matchUrl}/availability_stations`,
      exact: true,
      component: AvailabilityStations,
    },
    {
      permission: "menu-stations-availability",
      path: `${matchUrl}/availability_stations_real`,
      exact: true,
      component: AvailabilityStationsReal,
    },
    {
      permission: "menu-system-availability",
      path: `${matchUrl}/availability_system`,
      exact: true,
      component: AvailabilitySystem,
    },
    {
      permission: "menu-ocpp-stations",
      path: `${matchUrl}/ocpp/stations`,
      exact: true,
      component: OcppStations,
    },
    {
      permission: "menu-ocpp-charges",
      path: `${matchUrl}/ocpp/transactions`,
      exact: true,
      component: Transactions,
    },
    {
      permission: "show-details-ocpp-charge",
      path: `${matchUrl}/ocpp/transactions/:id`,
      exact: true,
      component: Transaction,
    },
    {
      permission: "menu-ocpp-operations",
      path: `${matchUrl}/ocpp/actions`,
      exact: false,
      component: Actions,
    },
    {
      permission: "menu-ocpp-alert-groups",
      path: `${matchUrl}/ocpp/alert_groups`,
      exact: false,
      component: ListAlertGroups,
    },
    {
      permission: "add-alert-group",
      path: `${matchUrl}/ocpp/new_alert_group`,
      exact: false,
      component: NewAlertGroup,
    },
    {
      permission: "menu-ocpp-tags",
      path: `${matchUrl}/ocpp/tags`,
      exact: true,
      component: Tags,
    },
    {
      permission: "menu-ocpp-logs",
      path: `${matchUrl}/ocpp/logs`,
      exact: false,
      component: Logs,
    },
    {
      permission: "menu-docs",
      path: `${matchUrl}/docs`,
      exact: false,
      component: Docs,
    },
    {
      permission: "menu-locations",
      path: `${matchUrl}/list_locations`,
      exact: true,
      component: PageListLocations,
    },
    {
      permission: "add-location",
      path: `${matchUrl}/new_location`,
      exact: true,
      component: NewLocation,
    },
    {
      permission: "menu-proposals",
      path: `${matchUrl}/proposal_list`,
      exact: true,
      component: ProposalList,
    },
    {
      permission: "menu-proposals",
      path: `${matchUrl}/proposal_creation`,
      exact: true,
      component: ProposalCreation,
    },
    {
      permission: "menu-proposals",
      path: `${matchUrl}/proposal_edit/:proposalId`,
      exact: true,
      component: ProposalEdit,
    },
  ];
};
export default RoutesDashboardConfig;
