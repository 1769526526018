import {
  COUPON_STATUS_TEXT,
  COUPON_TYPE,
  COUPON_VALUE_TYPE,
} from "../../services/utils";
import { formatters } from "../../utils/formatters";
import { validPlaceTypes } from "./DynamicCompanyForm";
import * as texts from "./locales";

const { FREE, PERCENTAGE, FIXED_VALUE, CREDIT } = COUPON_TYPE;

export const formatAvailableCompanies = (params, userCompany) => {
  switch (params.validPlace) {
    case validPlaceTypes.ALL_COMPANIES: {
      return [
        {
          companyId: params?.company ?? userCompany.id,
          stationIds: [],
        },
      ];
    }
    case validPlaceTypes.SPECIFIC_STATIONS: {
      const companies = params.companies.filter(
        (company) => company !== undefined
      );

      const someInvalidInput = companies.some(
        (company) =>
          company.specificStations && (company.stationIds ?? []).length === 0
      );

      if (someInvalidInput) {
        return false;
      }

      params.companies = undefined;

      return companies.map((company) => ({
        companyId: company.companyId,
        stationIds: company.specificStations ? company.stationIds : [],
      }));
    }
    default: {
      return [];
    }
  }
};

export const getBenefitDescription = (benefit, currency, freire) => {
  switch (benefit.type) {
    case FREE:
      return {
        iconColor: "#48ac16",
        tagColor: "green",
        text: freire(texts.FREE),
      };
    case PERCENTAGE:
      return {
        iconColor: "#1890ff",
        tagColor: "blue",
        text: freire(
          texts.DISCOUNT(
            formatters.percentage(freire.userLanguage)(benefit.value)
          )
        ),
      };
    case FIXED_VALUE:
      return {
        iconColor: "#722ed1",
        tagColor: "purple",
        text: freire(
          texts.DISCOUNT(
            formatters.currency(
              freire.userLanguage,
              currency
            )(benefit.value / 100)
          )
        ),
      };
    case CREDIT:
      if (benefit.valueType === COUPON_VALUE_TYPE.KWH) {
        return {
          iconColor: "#eb7c05",
          tagColor: "orange",
          text: freire(texts.CREDIT_KWH(benefit.value)),
        };
      }
      return {
        iconColor: "#eb7c05",
        tagColor: "orange",
        text: freire(
          texts.CREDIT_CURRENCY(
            formatters.currency(
              freire.userLanguage,
              currency
            )(benefit.value / 100)
          )
        ),
      };
    default:
      return {};
  }
};

export const getCouponStatusText = (status) => COUPON_STATUS_TEXT[status];

export const formatDuration = (formField) => {
  if (formField?.enabled) {
    const { unit, value } = formField;
    return `P${value}${unit}`;
  }

  return undefined;
};

export const formatUsageLimitPerPeriod = (usageLimitPerPeriod) => {
  if (usageLimitPerPeriod.enabled) {
    return {
      usageAmount: Number(usageLimitPerPeriod.usageAmount),
      period: formatDuration(usageLimitPerPeriod),
    };
  }

  return undefined;
};

export const wordsToExcludeFromCouponSheet = ["chassi"];
