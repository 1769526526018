export const OOPS = {
  pt: "Ops...",
  es: "Epa...",
  en: "Ops...",
};

export const ERROR_GET_STATIONS = {
  pt: "Erro ao buscar as estações",
  es: "Error al buscar las estaciones",
  en: "Error searching stations",
};

export const AVAILABLE = {
  pt: "Disponível",
  es: "Disponible",
  en: "Available",
};

export const CHARGING = {
  pt: "Carregando",
  es: "Cargando",
  en: "Charging",
};

export const FAULTED = {
  pt: "Com defeito",
  es: "Con defecto",
  en: "Defective",
};

export const SUSPENDEDEV = {
  pt: "Suspenso",
  es: "Suspendido",
  en: "Suspended",
};

export const CHARGE_PAID = {
  pt: "Carga Paga?",
  es: "Carga Paga?",
  en: "Charge Paid?",
};

export const NO_COUPON = {
  pt: "Sem cupom",
  es: "Sin cupón",
  en: "No coupon",
};

export const CHARGE_VALUE = {
  pt: "Valor da Carga",
  es: "Valor de la Carga",
  en: "Charge Value",
};

export const THERE_IS = (time) => ({
  pt: `Há ${time}`,
  es: `Hace ${time}`,
  en: `${time} ago`,
});

export const PREPARING = {
  pt: "Preparando",
  es: "Preparando",
  en: "Preparing",
};

export const FINISHING = {
  pt: "Finalizando",
  es: "Acabado",
  en: "Finishing",
};

export const UNAVAILABLE = {
  pt: "Indisponível",
  es: "Indisponible",
  en: "Unavailable",
};

export const SOFT_REBOOT = {
  en: "Soft reboot",
};

export const HARD_REBOOT = {
  en: "Hard reboot",
};

export const CREATE_HASH_KEY = {
  pt: "Criar senha para autenticação",
};

export const COPY_HASH_KEY = {
  pt: "Copiar senha de autenticação",
};

export const DELETE_HASH_KEY = {
  pt: "Remover autenticação",
};

export const CONFIRM_SEND_RESET = {
  pt: "Confirmação para enviar comando reset",
  es: "Confirmación de envío de la orden de reinicio",
  en: "Confirmation to send reset command",
};

export const CONFIRM_SEND_RESET_DESCRIPTION = {
  pt: "A estação reiniciará após enviar o comando.",
  es: "La estación se reiniciará después de enviar la orden.",
  en: "The station will reset after sending the command.",
};

export const ATTENTION = {
  pt: "Atenção!",
};

export const SEARCH_TAG_ERROR = {
  pt: "Erro ao buscar as tags",
};

export const CONFIRM_CREATE_HASH_KEY_DESCRIPTION = {
  pt: "Se a estação já estiver conectada, ela desconectará e só conseguirá se conectar novamente quando a senha for configurada dentro da interface de configurações da estação",
};

export const CONFIRM_CREATE_HASH_KEY_OK_TEXT = {
  pt: "Criar senha",
};

export const CONFIRM_DELETE_HASH_KEY_DESCRIPTION = {
  pt: "Ao remover a autenticação, também será necessário remover a senha na interface de configurações da estação",
};

export const CONFIRM_DELETE_HASH_KEY_OK_TEXT = {
  pt: "Remover autenticação",
};

export const SUCCESS_CREATE_HASH_KEY = (stationId) => ({
  pt: `A senha da estação ${stationId} foi criada e copiada com sucesso!`,
});

export const SUCCESS_COPY_HASH_KEY = (stationId) => ({
  pt: `A senha da estação ${stationId} foi copiada com sucesso!`,
});

export const SUCCESS_DELETE_HASH_KEY = (stationId) => ({
  pt: `A senha da estação ${stationId} foi deletada com sucesso!`,
});

export const OPERATION_ERROR = {
  pt: "Erro ao executar operação",
};

export const ALL_RIGHT = {
  pt: "Tudo certo!",
  es: "¡Está bien!",
  en: "All set!",
};

export const STATION_RESPONSE = {
  pt: "A estação respondeu com o status:",
  es: "La estación respondió con el estado:",
  en: "The station answered with the status:",
};

export const REMOTE_CHARGE_EMAIL_REQUIRED = {
  pt: "O E-mail do usuário é obrigatório",
  es: "El correo electrónico del usuario es obligatorio",
  en: "The user's email is required",
};

export const REMOTE_CHARGE_USER_NOT_FOUND = (email) => ({
  pt: `Usuário não encontrado para o e-mail "${email}"`,
  es: `Usuario no encontrado para el correo electrónico "${email}"`,
  en: `User not found for the email "${email}"`,
});

export const TRANSACTION_PENDING_ERROR_TITLE = {
  pt: "Aguarde um momento, a estação está tentando conectar",
  es: "Espere un momento, la estación está intentando conectar",
  en: "Please wait a moment, the station is trying to connect",
};

export const TRANSACTION_PENDING_ERROR_MESSAGE = (connectorId, stationId) => ({
  pt: `Existe uma tentativa de início de carga em andamento para este usuário no conector ${connectorId} da estação ${stationId}. Caso a carga não inicie em 60 segundos tente novamente`,
  es: `Hay un intento de inicio de carga en curso para este usuario en el conector ${connectorId} de la estación ${stationId}. Si la carga no comienza en 60 segundos, intente nuevamente`,
  en: `There is an ongoing charging attempt for this user on connector ${connectorId} at station ${stationId}. If the charge does not start within 60 seconds, please try again`,
});

export const UNEXPECTED = {
  pt: "Algo inesperado aconteceu",
  es: "Ha ocurrido algo inesperado",
  en: "Something unexpected happened",
};

export const IDENTIFICATION = {
  pt: "Identificação",
  es: "Identificación",
  en: "Identification",
};

export const STATUS = {
  pt: "Status",
  es: "Estado",
  en: "Status",
};

export const DISCONNECTED = {
  pt: "Desconectada",
  es: "Desconectado",
  en: "Disconnected",
};

export const LAST_HEARBEAT = {
  pt: "Último heartbeat",
  es: "Último heartbeat",
  en: "Last heartbeat",
};

export const CONNECTION_TIME = {
  pt: "Tempo de conexão",
  es: "Tiempo de conexión",
  en: "Connection time",
};

export const CHARGEPOINT_VENDOR = {
  pt: "Fabricante",
  es: "Fabricante",
  en: "Manufacturer",
};

export const MODEL = {
  pt: "Modelo",
  es: "Modelo",
  en: "Model",
};

export const FIRMWARE = {
  en: "Firmware",
};

export const ACTIONS = {
  pt: "Ações",
  es: "Acciones",
  en: "Actions",
};

export const ADMIN = {
  pt: "Admin",
  es: "Administración",
  en: "Admin",
};

export const OCPP = {
  en: "OCPP",
};

export const STATIONS = {
  pt: "Estações",
  es: "Estaciones",
  en: "Stations",
};

export const SEARCH_BY_STATION_ID = {
  pt: "Pesquise por ID ou nome da estação",
  es: "Búsqueda por ID o nombre de la estación",
  en: "Search by ID or station name",
};

export const SEARCH_BY_STATION_ID_EX = {
  pt: "Ex.: CPTESTE0001",
  es: "p.ej.: CPTESTE0001",
  en: "Example: CPTESTE0001",
};

export const PERIOD_HEARTBEAT = {
  pt: "Período de heartbeat",
  es: "Período de heartbeat",
  en: "Heartbeat period",
};

export const START_DATE = {
  pt: "Data inicial",
  es: "Fecha de inicio",
  en: "Start date",
};

export const FINAL_DATE = {
  pt: "Data final",
  es: "Fecha de finalización",
  en: "End date",
};

export const SHOWING = {
  pt: "Exibindo",
  es: "Exhibiendo",
  en: "Displaying",
};

export const CLEAN = {
  pt: "Limpar",
  es: "Limpiar",
  en: "Clear",
};

export const FILTER = {
  pt: "Filtrar",
  es: "Filtrar",
  en: "Filter",
};

export const STATION = {
  pt: "Estação",
  es: "Estación",
  en: "Station",
};

export const CONNECTOR = {
  pt: "Conector",
  es: "Conector",
  en: "Connector",
};

export const TAG = {
  pt: "Tag",
  es: "Etiqueta",
  en: "Tag",
};

export const START = {
  pt: "Início",
  es: "Inicie",
  en: "Start",
};

export const END_AT = {
  pt: "Término em",
  es: "Terminar como",
  en: "End in",
};

export const START_VALUE = {
  pt: "Start value",
  es: "Valor inicial",
  en: "Start value",
};

export const STOP_VALUE = {
  pt: "Stop value",
  es: "Valor de parada",
  en: "Stop value",
};

export const ERROR_GET_CHARGES = {
  pt: "Erro ao buscar as cargas",
  es: "Error al buscar las cargas",
  en: "Error searching charges",
};

export const STOP = {
  pt: "Parar",
  es: "Parar",
  en: "Stop",
};

export const CONFIRM_SEND_STOP_COMMAND = {
  pt: "Confirmação para enviar comando stop",
  es: "Confirmación para enviar la orden de parada",
  en: "Confirmation to send stop command",
};

export const CONFIRM_SEND_STOP_COMMAND_DESCRIPTION = {
  pt: "A carga será interrompida após enviar o comando.",
  es: "La carga se detendrá después de enviar la orden.",
  en: "Charge will be interrupted after sending the command.",
};

export const CHARGES = {
  pt: "Cargas",
  es: "Cargas",
  en: "Charges",
};

export const CHARGES_TOTAL = (amount, total) => ({
  pt: `${amount} de ${total} Cargas`,
  es: `${amount} de ${total} Cargas`,
  en: `${amount} of ${total} Charges`,
});

export const CHARGE = {
  pt: "Carga",
  es: "Carga",
  en: "Charge",
};
export const COD = {
  pt: "Código",
  en: "Code",
  es: "Código",
};

export const TAGS = {
  pt: "Tags",
  es: "Etiquetas",
  en: "Tags",
};

export const ALL = {
  pt: "Todas",
  es: "Todas",
  en: "All",
};

export const ACTIVE = {
  pt: "Ativas",
  es: "Activas",
  en: "Active",
};

export const IDLE = {
  pt: "Ociosas",
  es: "Ociosas",
  en: "Idle",
};

export const FINISHED = {
  pt: "Finalizadas",
  es: "Terminadas",
  en: "Finished",
};

export const DISPLAY = {
  pt: "Exibição",
  es: "Mostrar",
  en: "Display",
};

export const TRANSACTION_ID = {
  pt: "ID da transação",
  es: "ID de la transacción",
  en: "Transaction ID",
};

export const TRANSACTION_ID_EX = {
  pt: "Ex.: 234",
  es: "p.ej.: 234",
  en: "Example: 234",
};

export const START_PERIOD = {
  pt: "Período de início",
  es: "Período de inicio",
  en: "Start period",
};

export const NOT_FIND_CHARGE_BY_ID = {
  pt: "Não foi possível encontrar a carga para o ID fornecido",
  es: "No se ha podido encontrar la carga para el ID suministrado",
  en: "It was not possible to find the charge for the given ID",
};

export const GOBACK_CHARGES = {
  pt: "Voltar para todas as cargas",
  es: "Volver a todas las cargas",
  en: "Return to all charges",
};

export const CHARGE_ID = {
  pt: "ID da carga",
  es: "ID de la carga",
  en: "Charge ID",
};

export const STATION_ID = {
  pt: "ID da estação",
  es: "ID de la estación",
  en: "Station ID",
};

export const CONNECTOR_ID = {
  pt: "ID do conector",
  es: "ID del conector",
  en: "Connector ID",
};

export const STARTED_AT = {
  pt: "Iniciada em",
  es: "Iniciada el",
  en: "Started at",
};

export const STARTED_IDLE_AT = {
  pt: "Ociosa em",
  es: "Ociosa en",
  en: "Idle at",
};

export const STOPED_AT = {
  pt: "Finalizada em",
  es: "Terminada el",
  en: "Finished at",
};

export const TIME = {
  pt: "Horário",
  es: "Tiempo",
  en: "Time",
};

export const NEW_STATUS = {
  pt: "Novo status",
  es: "Nuevo estado",
  en: "New status",
};

export const OLD_STATUS = {
  pt: "Status anterior",
  es: "Estado anterior",
  en: "Old status",
};

export const ERROR_CODE = {
  pt: "Código de erro",
  es: "Código de error",
  en: "Error code",
};

export const VENDOR_ERROR_CODE = {
  pt: "Código de erro do fabricante",
  es: "Código de error del fabricante",
  en: "Vendor error code",
};

export const INFO = {
  pt: "Info",
  es: "Info",
  en: "Info",
};

export const OTHER = {
  pt: "Outros Dados",
  es: "Otros Datos",
  en: "Other Data",
};

export const VALUE = {
  pt: "Valor",
  es: "Valor",
  en: "Value",
};

export const RAW_VALUE = {
  pt: "Valor (raw)",
  es: "Valor (raw)",
  en: "Value (raw)",
};

export const UNIT = {
  pt: "Unidade",
  es: "Unidad",
  en: "Unit",
};

export const CONTEXT = {
  pt: "Reading context",
  es: "Contexto de lectura",
  en: "Reading context",
};

export const FORMAT = {
  pt: "Formato",
  es: "Formato",
  en: "Format",
};

export const MEASURAND = {
  en: "Measurand",
};

export const LOCATION = {
  pt: "Location",
  es: "Ubicación",
  en: "Location",
};

export const PHASE = {
  pt: "Fase",
  es: "Fase",
  en: "Phase",
};

export const LOGS = {
  en: "Logs",
};

export const SELECT_STATION = {
  pt: "Selecione a estação",
  es: "Seleccione la estación",
  en: "Select the station",
};

export const DAY_REFERRING = {
  pt: "Dia referente ao Log",
  es: "Día del log",
  en: "Day referring to Log",
};

export const SELECT_DATE = {
  pt: "Selecione a data",
  es: "Seleccione la fecha",
  en: "Select date",
};

export const FULLSCREEN = {
  pt: "Tela cheia",
  es: "Pantalla completa",
  en: "Fullscreen",
};

export const OPERATIONS = {
  pt: "Operações",
  es: "Operaciones",
  en: "Operations",
};

export const SELECT_PARAMS = {
  pt: "Selecione os parâmetros",
  es: "Seleccione los parámetros",
  en: "Select parameters",
};

export const SELECT_PARAMS_EXEC = {
  pt: "Selecione a operação a ser executada",
  es: "Seleccione la operación a realizar",
  en: "Select the operation to be executed",
};

export const VERIFY_AGAIN = {
  pt: "Verifique e tente novamente!",
  es: "¡Compruebe e inténtelo nuevamente!",
  en: "Check and try again!",
};

export const REQUIRED = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};

export const SELECT_CONNECTOR_ID = {
  pt: "Selecione o ID do conector",
  es: "Seleccione el ID del conector",
  en: "Select the connector ID",
};

export const ALL_IDS = {
  pt: "Todos (ID 0)",
  es: "Todos (ID 0)",
  en: "All (ID 0)",
};

export const AVAILABLE_TYPE = {
  pt: "Tipo de disponibilidade",
  es: "Tipo de disponibilidad",
  en: "Availability type",
};

export const INOPERATIVE = {
  pt: "Fora de operação",
  es: "Fuera de operación",
  en: "Out of order",
};

export const OPERATIVE = {
  pt: "Em operação",
  es: "En funcionamiento",
  en: "Operative",
};

export const EXECUTE = {
  pt: "Executar",
  es: "Ejecutar",
  en: "Execute",
};

export const SECONDS = {
  pt: "segundos",
  es: "segundos",
  en: "seconds",
};

export const QTD_OF_TIMES = {
  pt: "qtd. de vezes",
  es: "número de veces",
  en: "quantity of times",
};

export const PERCENTAGEM = {
  pt: "porcentagem",
  es: "porcentaje",
  en: "percentage",
};

export const COMMA_SEPARATED_LIST = {
  pt: "lista separada por vírgulas",
  es: "lista separada por comas",
  en: "list separated by commas",
};

export const ACTIVE_DISABLE = {
  pt: "ativar ou desativar",
  es: "activar o desactivar",
  en: "enable or disable",
};

export const IN_KH = {
  pt: "em Wh",
  es: "en Wh",
  en: "in Wh",
};

export const ACTIVATE = {
  pt: "Ativar",
  es: "Activar",
  en: "Enable",
};

export const DISABLE = {
  pt: "Desativar",
  es: "Desactivar",
  en: "Disable",
};

export const DESCRIPTION = {
  pt: "Descrição",
};

export const EXPIRATION_DATE = {
  pt: "Data de expiração",
};
export const MAX_CHARGER = {
  pt: "Máximo de cargas simultâneas",
};

export const ONLY_INTERGER_NUMBERS = {
  pt: "Apenas números inteiros",
  es: "Sólo números enteros",
  en: "Only integral numbers",
};

export const ENTER_VALUE_OF_CONFIGURATION = {
  pt: "Informe o valor referente a configuração",
  es: "Introduzca el valor referente a la configuración",
  en: "Inform the value referring to the configuration",
};

export const NO_BLANK_SPACE_ALLOWED = {
  pt: "Não é permitido espaços em branco",
  es: "No se admiten espacios en blanco",
  en: "It is not allowed blank spaces",
};

export const PRESET = {
  pt: "Predefinida",
  es: "Predeterminado",
  en: "Preset",
};

export const TYPE = {
  pt: "Tipo",
  es: "Tipo",
  en: "Type",
};

export const SELECT_TYPE_CONFIGURATION = {
  pt: "Selecione o tipo de configuração",
  es: "Seleccione el tipo de configuración",
  en: "Select the configuration type",
};

export const CUSTOMIZED = {
  pt: "Personalizada",
  es: "Personalizado",
  en: "Customized",
};

export const CONFIGURATION = {
  pt: "Configuração",
  es: "Configuración",
  en: "Configuration",
};

export const SELECT_CONFIGURATION = {
  pt: "Selecione uma configuração",
  es: "Seleccione una configuración",
  en: "Select a configuration",
};

export const INFORM_THE_CONFIGURATION = {
  pt: "Informe a configuração",
  es: "Informe la configuración",
  en: "Inform the configuration",
};

export const BOOLEANO = {
  pt: "booleano",
  es: "booleano",
  en: "boolean",
};

export const INTEGER = {
  pt: "inteiro",
  es: "entero",
  en: "integral",
};

export const CLOSE = {
  pt: "Fechar",
  es: "Cerrar",
  en: "Close",
};

export const CONFIGURATION_NOT_FINDED = {
  pt: "Configurações não encontradas:",
  es: "Configuración no encontrada:",
  en: "Configurations not found:",
};

export const READ_ONLY_QUESTION = {
  pt: "Apenas Leitura?",
  es: "¿Sólo lectura?",
  en: "Only read?",
};

export const NO_CONFIGURATION_ALERT = {
  pt: "Se nenhuma configuração for selecionada, a estação retornará uma lista de todas as configurações e seus respectivos valores.",
  es: "Si no se selecciona ninguna configuración, la estación de trabajo devolverá una lista de todas las configuraciones y sus valores.",
  en: "If no configuration is selected, the station will return a list of all configurations and its respective values.",
};

export const SELECT_ONE_OR_MORE_CONFIGURATION = {
  pt: "Selecione ou digite uma ou mais configurações",
  // TODO: es: translate
  // TODO: en: translate
};

export const SELECT_ONE_STATION = {
  pt: "Selecione uma estação para receber o comando",
  es: "Seleccione una estación para recibir la orden",
  en: "Select a station to receive the command",
};

export const LOADING_STATIONS = {
  pt: "Buscando estações conectadas ...",
  es: "Buscando estaciones conectadas ...",
  en: "Searching connected stations ...",
};

export const STATION_NOT_FOUND = {
  pt: "Nenhuma estação encontrada!",
  es: "¡No se encontró ninguna estación!",
  en: "No station found!",
};

export const CHARGE_STARTED = {
  pt: "Carga iniciada com sucesso!",
  es: "¡Carga iniciada con éxito!",
  en: "Charge successfully started!",
};

export const CHARGE_ATTACHED = {
  pt: "Carga atrelada com sucesso!",
  es: "¡Carga atrelada con éxito!",
  en: "Charge successfully attached!",
};

export const ATTACH_REMOTE_CHARGE_FAILED = {
  pt: "Carga não atrelada ao usuário, será necessário pedir a Tech para atrelar",
  es: "Carga no atrelada al usuario, se necesita pedir la Tech para vincular",
  en: "Charge not attached to the user, it is necessary to request the Tech to attach",
};

export const ATTACH_REMOTE_CHARGE_SUCCESS = {
  pt: "Carga atrelada ao usuário",
  es: "Carga atrelada al usuario",
  en: "Charge attached to the user",
};

export const CHARGE_HISTORY_CURRENTLY_CHARGING = {
  pt: "O carro ainda está conectado, será cobrada quando o usuário finalizar",
  es: "El carro aún está conectado, será cobrada cuando el usuario finalice",
  en: "The car is still connected, it will be charged when the user finishes",
};

export const SELECT_TAG_OCPP = {
  pt: "Selecione a Tag OCPP",
  es: "Seleccione la etiqueta OCPP",
  en: "Select a OCPP Tag",
};

export const REMOTE_CHARGE_EMAIL = {
  pt: "E-mail do usuário para atrelar a carga. (em teste)",
  es: "Correo electrónico del usuario para vincular la carga. (en prueba)",
  en: "Email of the user to attach the charge. (in test)",
};

export const REMOTE_CHARGE_COUPON = {
  pt: "Cupom utilizado na carga (se houver)",
  es: "Cupón utilizado en la carga (si hay)",
  en: "Coupon used in the charge (if there is)",
};

export const TAG_OCPP = {
  pt: "Tag OCPP",
  es: "Etiqueta OCPP",
  en: "OCPP Tag",
};

export const ACTIVE_TRANSACTION_ID = {
  pt: "ID da transação ativa",
  es: "ID de la transacción activa",
  en: "Active transaction ID",
};

export const SELECT_TRANSACTION = {
  pt: "Selecione a transação",
  es: "Seleccione la transacción",
  en: "Select the transaction",
};

export const MESSAGE_TO_REQUEST = {
  pt: "Mensagem a ser requisitada",
  es: "Mensaje para solicitar",
  en: "Message to be requested",
};

export const SELECT_MESSAGE = {
  pt: "Selecione a mensagem",
  es: "Seleccione el mensaje",
  en: "Select the message",
};

export const FALLOW_LOGS = {
  pt: `Acompanhe os logs para ver o andamento da atualização. A estação enviará 'FirmwareStatusNotification' com o status.`,
  es: "Sigue los registros para ver el progreso de la actualización. La estación de trabajo enviará 'FirmwareStatusNotification' con el estado.",
  en: "Follow the logs to see the progress of the update. The station will send 'FirmwareStatusNotification' with the status.",
};

export const URL_TO_DOWNLOAD_FIRMWARE = {
  pt: "URL para download do firmware",
  es: "URL para la descarga del firmware",
  en: "Firmware file URL",
};

export const URL_TO_DOWNLOAD_FIRMWARE_EX = {
  pt: "Ex.: https://site.com/firmwares/v1.2.3.bin",
  es: "p.ej.: https://site.com/firmwares/v1.2.3.bin",
  en: "Example: https://website.com/firmwares/v1.2.3.bin",
};

export const DATE_TIME_TO_ATT = {
  pt: "Data/hora para atualizar",
  es: "Fecha/hora de actualización",
  en: "Date/time to update",
};

export const INSERT_DATE_TIME_IN_FUTURE = {
  pt: "Insira uma data/hora no futuro",
  es: "Introduzca una fecha/hora en el futuro",
  en: "Insert a date/time in the future",
};

export const SELECT_DATE_TIME = {
  pt: "Selecione data/hora",
  es: "Seleccione la fecha/hora",
  en: "Select date/time",
};

export const NUMBER_OF_ATTEMPTS = {
  pt: "Número de tentativas (opcional)",
  es: "Número de intentos (opcional)",
  en: "Number of attempts (optional)",
};

export const NUMBER_EX = {
  pt: "Ex.: 3",
  es: "p.ej.: 3",
  en: "Example: 3",
};

export const INTERVAL_IN_SECONDS_ATTEMPTS = {
  pt: "Intervalo de tentativas em segundos (opcional)",
  es: "Intervalo de intentos en segundos (opcional)",
  en: "Interval of attempts in seconds (optional)",
};

export const USER_TAG = {
  pt: "Tag de usuário?",
};

export const SEARCH_BY_CODE_TAG = {
  pt: "Pesquise pelo código da Tag...",
};

export const SEARCH = {
  pt: "Pesquisar",
  es: "Buscar",
  en: "Search",
};

export const YES = {
  pt: "Sim",
  es: "Sí",
  en: "Yes",
};

export const NO = {
  pt: "Não",
  es: "No",
  en: "No",
};

export const USER_EMAIL = {
  pt: "Email do usuário",
  es: "Correo electrónico del usuario",
  en: "User email",
};

export const USER_COUPON_TO_USE = {
  pt: "Cupom do usuário para essa carga",
  es: "Cupón del usuario para esta carga",
  en: "User coupon to use in this charge",
};

export const ATTACH_REMOTE_CHARGE_BUTTON = {
  pt: "Atrelar carga ao usuário",
  es: "Vincular carga al usuario",
  en: "Attach charge to user",
};

export const ATTACH_REMOTE_CHARGE_LABEL = {
  pt: "Atrelar",
  es: "Vincular",
  en: "Attach",
};

export const METER_VALUES = {
  pt: "Valores do medidor",
  es: "Valores del medidor",
  en: "Meter values",
};

export const STATUS_NOTIFICATIONS = {
  pt: "Notificações de status",
  es: "Notificaciones de estado",
  en: "Status notifications",
};

export const CONFIRM_ATTACH_REMOTE_CHARGE = {
  pt: "Deseja realmente vincular a carga ao usuário?",
  es: "Desea realmente vincular la carga al usuario?",
  en: "Do you really want to attach the charge to the user?",
};

export const CONFIRM_ATTACH_REMOTE_CHARGE_DESCRIPTION = {
  pt: "Isso irá atrelar a carga ao usuário de forma permanente, e efetuar a cobranca do pagamento se houver.",
  es: "Esto vinculará la carga al usuario de forma permanente, y efetuará la cobranza del pago si hay.",
  en: "This will attach the charge to the user permanently, and make the payment if there is.",
};

export const DURATION = {
  pt: "Duração",
  es: "Duración",
  en: "Duration",
};

export const SPECIFY_SCHEDULE_DURATION = {
  pt: "Tempo em segundos. Duração do schedule solicitado",
  es: "Tiempo en segundos. Duración del schedule solicitado",
  en: "Time in seconds. Length of requested schedule",
};
