export const LINK_BRANCH_IO_TUPI = "link.tupinambaenergia.com.br";
export const LINK_BRANCH_IO_MOBILIZE = "mobilize.tupinambaenergia.com.br";
export const LINK_BRANCH_IO_SHELL = "shell-recharge.tupinambaenergia.com.br";
export const LINK_BRANCH_IO_BYD = "byd.tupinambaenergia.com.br";
export const LINK_BRANCH_IO_BRS = "brsupercarga.tupimob.com";
export const LINK_BRANCH_IO_VOLTZ = "voltz.tupimob.com";
export const LINK_BRANCH_IO_ION = "ion.tupimob.com";
export const LINK_BRANCH_IO_ANDRADESALES = "andradesales.tupimob.com";
export const LINK_BRANCH_IO_CHARGERPRO = "chargerpro.tupimob.com";
export const LINK_BRANCH_IO_ELESOL = "elesol.tupimob.com";
export const LINK_BRANCH_IO_PLUGBRA = "plugbra.tupimob.com";
export const LINK_BRANCH_IO_EVELETROPOSTO = "eveletroposto.tupimob.com";
export const LINK_BRANCH_IO_CIACHARGE = "ciacharge.tupimob.com";
export const LINK_BRANCH_IO_LINKPLUSENERGY = "linkplusenergy.tupimob.com";

export const getShareStationLink = ({
  link,
  stationID,
}: {
  link: string;
  stationID: string;
}) => {
  return `${link}/estacao/${stationID}`;
};
