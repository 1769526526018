import {
  Button,
  Col,
  Drawer,
  Form,
  Icon,
  Input,
  notification,
  Radio,
  Row,
  Table,
  Tooltip,
} from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Show from "../../../contexts/PermissionContext/Show";
import api from "../../../services/api";
import EditDrawer from "./EditDrawer";
import "./index.css";
import { FreireContext } from "../../../utils/freireContext";
import * as texts from "../locales";

class ListUsers extends Component {
  static contextType = FreireContext;

  state = {
    loadingTable: false,
    loadingSave: false,

    data: [],
    dataFiltrada: [],

    user: {},
    companies: [],
    permissionGroups: [],
    cars: [],

    visibleEditDrawer: false,
    visibleCarDrawer: false,

    tablePage: 1,

    preventPageReload: false,
  };

  componentDidMount() {
    this.getUsers();
    this.getAditionalData();
  }

  getAditionalData = async () => {
    api.get("/company").then(({ data: companies }) => {
      this.setState({ companies });
    });

    api.get("/auth/permission-groups").then(({ data: permissionGroups }) => {
      this.setState({ permissionGroups });
    });
  };

  async getUsers() {
    const { freire } = this.context;

    try {
      this.setState({ loadingTable: true });

      const { data: users } = await api.get(`user`);

      this.setState({
        dataFiltrada: users,
        data: users,
        loadingTable: false,
      });
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_GET_USERS),
      });

      this.setState({ loadingTable: false });
    }
    return false;
  }

  openEditDrawer = (user) => {
    this.setState({ user, visibleEditDrawer: true });
  };

  openCarDrawer = async (user) => {
    const { data: cars } = await api.get(`admin/cars/${user.uid}`);

    this.setState({ cars, visibleCarDrawer: true });
  };

  closeDrawer = () => {
    this.setState({
      visibleEditDrawer: false,
      visibleCarDrawer: false,
      loadingSave: false,
    });
  };

  filter = () => {
    var data = this.state.data;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.email) {
          data = data.filter((str) => {
            return (
              str.email?.toLowerCase().indexOf(values.email.toLowerCase()) >= 0
            );
          });
        }

        if (values.hasOwnProperty("admin") && values.admin !== undefined) {
          if (values.admin) {
            data = data.filter((str) => {
              return str?.admin_data && str?.admin_data?.is_admin;
            });
          } else {
            data = data.filter((str) => {
              return !str?.admin_data || !str?.admin_data?.is_admin;
            });
          }
        }

        this.setState({
          dataFiltrada: data,
          tablePage: 1,
        });
      }
    });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.filter();
    }
  };

  clearFilters = () => {
    this.props.form.resetFields();
    this.setState({ dataFiltrada: this.state.data });
    this.getUsers();
    return false;
  };

  editUser = (payload) => {
    const { freire } = this.context;

    const { user } = this.state;
    this.setState({ loadingSave: true }, async () => {
      try {
        const data = {
          email: user.email,
          admin: payload.admin,
          companyID: payload.companyID,
          companyName: payload.companyName,
          permissionGroup: payload.permissionGroup,
        };

        await api.post(`user/company`, data);

        this.closeDrawer();

        await this.getUsers();

        this.filter();
      } catch (err) {
        notification.error({
          message: freire(texts.ERROR_EDIT_USER),
          description: freire(texts.VERIFY_DATA_TRY_AGAIN),
        });

        this.setState({ loadingSave: false });
      }
    });
  };

  getColumns = () => {
    const { freire } = this.context;
    return [
      {
        title: freire(texts.NAME),
        dataIndex: "displayName",
        key: "displayName",
        render: (displayName) => <span>{displayName || "----"}</span>,
      },
      {
        title: freire(texts.EMAIL),
        dataIndex: "email",
        key: "email",
      },
      {
        title: freire(texts.ADMIN),
        dataIndex: "admin_data.is_admin",
        width: "10%",
        key: "admin_data.is_admin",
        render: (is_admin) => (
          <Icon
            type={typeof is_admin === "boolean" && is_admin ? "check" : "close"}
          />
        ),
      },
      {
        title: freire(texts.COMPANY),
        dataIndex: "admin_data.company_name",
        width: "20%",
        key: "admin_data.company_name",
        render: (company_name) => (
          <div style={{ paddingLeft: 5 }}>{company_name || "----"}</div>
        ),
      },
      {
        title: freire(texts.ACTIONS),
        key: "acoes",
        width: "10%",
        render: (text, data) => (
          <>
            <Show when={"edit-user"}>
              <Tooltip placement="top" title={freire(texts.SHOW_DETAILS_EDIT)}>
                <Button
                  shape="circle"
                  size="small"
                  ghost
                  type="primary"
                  style={{ marginRight: 8 }}
                  onClick={() => this.openEditDrawer(data)}
                >
                  <Icon type="edit" />
                </Button>
              </Tooltip>
            </Show>

            <Show when={"show-user-vehicles-admin"}>
              <Tooltip placement="top" title={freire(texts.SEE_CARS)}>
                <Button
                  shape="circle"
                  size="small"
                  type="dashed"
                  onClick={() => this.openCarDrawer(data)}
                >
                  <Icon type="car" />
                </Button>
              </Tooltip>
            </Show>
          </>
        ),
      },
    ];
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.filter();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = this.getColumns();
    const { freire } = this.context;

    return (
      <div className="container">
        <Breadcrumbs
          breadcrumbs={[
            freire(texts.ADMIN),
            freire(texts.MANAGEMENT),
            freire(texts.USERS),
          ]}
        />
        <div className="filter">
          <Form onSubmit={this.handleSubmit} wrapperCol={{ span: 24 }}>
            <Form.Item>
              <div style={{ flexDirection: "row", display: "flex" }}>
                {getFieldDecorator("email")(
                  <Input
                    className="firstInput"
                    placeholder={freire(texts.SEARCH_BY_EMAIL)}
                    onKeyPress={this.handleKeyPress}
                    onChange={(e) => {
                      e.target.value = e.target.value.trim();
                    }}
                  />
                )}
                <Button
                  icon="search"
                  className="buttonSearch"
                  onClick={this.filter}
                >
                  {freire(texts.SEARCH)}
                </Button>
              </div>
            </Form.Item>

            <Row gutter={[16, 8]}>
              <Col span={6}>
                <Form.Item label={freire(texts.ADMIN)}>
                  {getFieldDecorator("admin")(
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value={true}>
                        {freire(texts.YES)}
                      </Radio.Button>
                      <Radio.Button value={undefined}>---</Radio.Button>
                      <Radio.Button value={false}>
                        {freire(texts.NO)}
                      </Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <div className="footer">
            <Button style={{ marginRight: 10 }} onClick={this.clearFilters}>
              {freire(texts.CLEAN)}
            </Button>
            <Button onClick={this.filter} type="primary">
              {freire(texts.FILTER)}
            </Button>
          </div>
        </div>

        <Table
          style={{ backgroundColor: "white" }}
          loading={this.state.loadingTable}
          rowKey={(data) => data.uid}
          dataSource={this.state.dataFiltrada}
          columns={columns}
          pagination={{
            current: this.state.tablePage,
            onChange: (page) => this.setState({ tablePage: page }),
          }}
        />

        <EditDrawer
          visible={this.state.visibleEditDrawer}
          loading={this.state.loadingSave}
          onClose={this.closeDrawer}
          onSave={this.editUser}
          user={this.state.user}
          companies={this.state.companies}
          permissionGroups={this.state.permissionGroups}
        />
        {/* carros       */}
        <Drawer
          title={freire(texts.USER_CARS)}
          width={500}
          onClose={this.closeDrawer}
          visible={this.state.visibleCarDrawer}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <div>
            {this.state.cars.map((car) => {
              return (
                <div key={car._id}>
                  <Row gutter={16}>
                    <Col span={12}>
                      {car.model} - {car.brand}
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <Button onClick={this.closeDrawer} style={{ marginRight: 8 }}>
              {freire(texts.CANCEL)}
            </Button>
          </div>
        </Drawer>
      </div>
    );
  }
}
const PageListUsers = Form.create({ name: "filters" })(ListUsers);
export default withRouter(PageListUsers);
