import React, { Component } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Drawer,
  Select,
  message,
  Tooltip,
  Popconfirm,
  Icon,
  Table,
  Upload,
  notification,
  Switch,
} from "antd";
import { withRouter } from "react-router-dom";
import api from "../../../services/api";
import languageEncoding from "detect-file-encoding-and-language";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import * as texts from "../locales";
import { FreireContext } from "../../../utils/freireContext";
import { Show } from "../../../contexts/PermissionContext";

const { Option } = Select;

class EditDrawer extends Component {
  static contextType = FreireContext;

  state = {
    loadingStations: false,
    loadingLocale: false,
    stations: [],
    loadingTable: false,
    responseListEmail: {},
    usersNotFound: [],
    dataSource: [],
    fileList: [],
    email: "",
    addType: "",
    dateFiltrada: [],
    apps: [],
    companies: [],
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.visible !== prevProps.visible) {
      if (this.props.visible) {
        this.setState({ stations: [] });
        this.props.form.setFieldsValue(this.props.group);
        this.getUsersVisibilityGroup();
      }
    }
  }

  getStations() {
    const { freire } = this.context;

    this.setState({ loadingStations: true, stations: [] }, async () => {
      try {
        const { data } = await api.get(`select/connected_stations`);
        this.setState({
          loadingStations: false,
          stations: data,
        });
      } catch (error) {
        message.error(freire(texts.ERROR_GET_STATIONS));
        this.setState({
          loadingStations: false,
        });
      }
    });
  }

  getUsersVisibilityGroup() {
    const { freire } = this.context;

    this.setState({ loadingTable: true, dateFiltrada: [] }, async () => {
      try {
        const { data: dateFiltrada } = await api.get(
          `visibilityGroup/${this.props.group._id}/users`
        );
        this.setState({
          loadingTable: false,
          dateFiltrada,
        });
      } catch (error) {
        message.error(freire(texts.ERROR_GET_STATIONS));
        this.setState({
          loadingTable: false,
        });
      }
    });
  }

  save() {
    this.props.form.validateFields(async (err, payload) => {
      if (err) {
        return;
      }

      this.props.onSave(payload);
    });
  }

  async addUserPerEmail() {
    const { freire } = this.context;

    try {
      this.setState({ loadingLocale: true });
      const email = this.props.form.getFieldValue("email");
      const { data: responseEmail } = await api.put(
        `visibilityGroup/${this.props.group._id}/domain`,
        {
          email,
        }
      );
      message.success(
        <span style={{ paddingLeft: 10 }}>
          {freire(
            texts.USERS_VISIBILITY_ADD_RESPONSE(
              responseEmail.usersFound,
              responseEmail.usersAdds
            )
          )}
        </span>,
        5
      );
      this.getUsersVisibilityGroup();
      this.setState({ loadingLocale: false, responseEmail });
    } catch (e) {
      message.error(freire(texts.ERROR_ADD_USERS));
    }
  }

  async addUserListEmail() {
    const { freire } = this.context;

    try {
      this.setState({ loadingLocale: true });
      const { dataSource } = this.state;
      const { data: responseListEmail } = await api.put(
        `visibilityGroup/${this.props.group._id}/listEmail`,
        {
          dataSource,
        }
      );
      message.success(
        <div style={{ margin: 10 }}>
          {freire(texts.USERS_FOUND(responseListEmail.usersFound))}
          <br />
          {freire(texts.USERS_NOT_FOUND(responseListEmail.usersNotFound))}
          <br />
          {freire(texts.USERS_ADDS(responseListEmail.usersAdds))}
          <br />
        </div>,
        5
      );
      this.getUsersVisibilityGroup();
      this.setState({ loadingLocale: false, responseListEmail });
    } catch (e) {
      message.error(freire(texts.ERROR_ADD_USERS));
    }
  }

  async addEmail() {
    const { freire } = this.context;

    try {
      const { email } = this.state;
      const response = await api.put(
        `userVisibility/${this.props.group._id}/email`,
        { email: email }
      );
      if (response.status === 200) this.getUsersVisibilityGroup();
      message.success(
        <div style={{ margin: 10 }}>{freire(texts.USER_ADD)}</div>,
        5
      );
    } catch (e) {
      message.error(freire(texts.USERS_ALREADY_VISIBILITY));
    }
  }

  async deleteUserVisibility(userVisibilityID) {
    const { freire } = this.context;

    try {
      await api.delete(`userVisibility/${userVisibilityID}`);
      message.success(
        <div style={{ margin: 10 }}>
          {freire(texts.USER_VISIBILITY_DELETED)}
        </div>,
        5
      );

      this.getUsersVisibilityGroup();
    } catch (e) {
      message.error(freire(texts.ERROR));
    }
  }

  async deleteAllUsersVisibility() {
    const { freire } = this.context;

    try {
      await api.delete(`visibilityGroup/${this.props.group._id}/users`);
      message.success(
        <div style={{ margin: 10 }}>
          {freire(texts.ALL_USER_VISIBILITY_DELETED)}
        </div>,
        2
      );

      this.getUsersVisibilityGroup();
    } catch (e) {
      message.error(freire(texts.ERROR));
    }
  }

  async readFile(file) {
    var f = file;
    var reader = new FileReader();
    reader.onload = (e) => {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "string" });

      const sheetName = readedData.SheetNames[0];
      const dados = readedData.Sheets[sheetName];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(dados, {
        header: 1,
        defval: "",
      });

      let listaFinal = [];
      var index = 0;
      if (dataParse.length > 0) {
        if (dataParse[0].length > 0 && !dataParse[0][0].includes("@"))
          index = 1;
      }
      dataParse.slice(index).forEach((linha) => {
        if (linha[0].includes("@"))
          listaFinal.push({
            email: linha[0],
          });
      });

      this.setState({ dataSource: listaFinal });
    };
    var result = { encoding: "utf-8" };
    try {
      result = await languageEncoding(f);
    } catch (error) {}

    reader.readAsText(f, result.encoding || "utf-8");
  }

  getColumns = () => {
    const { freire } = this.context;

    return [
      {
        title: freire(texts.NAME),
        dataIndex: "userName",
        key: "userName",
      },
      {
        title: freire(texts.EMAIL),
        dataIndex: "userEmail",
        key: "userEmail",
      },
      {
        title: freire(texts.FROM),
        dataIndex: "from",
        key: "from",
      },
      {
        title: freire(texts.ACTIONS),
        key: "acoes",
        render: (group, data) => (
          <>
            <Tooltip placement="top" title={freire(texts.DELETE)}>
              <Popconfirm
                placement="top"
                title={freire(texts.CONFIRM_DELETE)}
                okText={freire(texts.YES)}
                cancelText={freire(texts.NO)}
                onConfirm={() => this.deleteUserVisibility(group._id)}
              >
                <Button
                  style={{ marginRight: 8 }}
                  shape="circle"
                  size="small"
                  ghost
                  type="danger"
                >
                  <Icon type="delete" />
                </Button>
              </Popconfirm>
            </Tooltip>
          </>
        ),
      },
    ];
  };

  async componentDidMount() {
    const [appsResult, companiesResult] = await Promise.all([
      api.get("/white-label?includeTupi=true"),
      api.get("/company")
    ]);
    this.setState({ apps: appsResult.data, companies: companiesResult.data });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      loadingStations,
      stations,
      loadingTable,
      dateFiltrada,
      loadingLocale,
      fileList,
      dataSource,
      responseListEmail,
      addType,
      email,
      apps,
      companies,
    } = this.state;
    const { visible, onClose, loading } = this.props;
    const { freire } = this.context;

    const columns = this.getColumns();
    const headers = [{ label: "Email", key: "email" }];

    const stationsRequired = (this.props.form.getFieldValue("ocpiPartyIds")?.length ?? 0) === 0;
    const visibleToEveryone = this.props.form.getFieldValue("visibleToEveryone") === true;

    return (
      <Drawer
        title={freire(texts.EDIT_GROUP)}
        width={800}
        onClose={() => (loading ? {} : onClose())}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={freire(texts.GROUP_NAME)}>
                {getFieldDecorator("name", {
                  rules: [
                    {
                      required: true,
                      message: freire(texts.REQUIRED_FIELD),
                    },
                  ],
                })(
                  <Input
                    disabled={loading}
                    placeholder={freire(texts.GROUP_NAME_EX)}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={freire(texts.STATIONS)}>
                {getFieldDecorator("stations", {
                  rules: [
                    {
                      required: stationsRequired,
                      message: freire(texts.REQUIRED_FIELD),
                    },
                  ],
                })(
                  <Select
                    mode="multiple"
                    disabled={loading}
                    loading={loadingStations}
                    onDropdownVisibleChange={(opened) => {
                      if (opened && stations.length === 0) {
                        this.getStations();
                      }
                    }}
                    style={{ width: "100%" }}
                    placeholder={
                      loadingStations
                        ? freire(texts.CHARGING)
                        : freire(texts.SELECT_STATIONS)
                    }
                  >
                    {stations.map((station) => {
                      return (
                        <Option key={station._id} value={station.stationID}>
                          {`${station.name} (${station.stationID})`}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <span style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: 14 }}>
            {freire(texts.EMAIL_DOMAIN)}
          </span>
          <Row gutter={16}>
            <Col span={16}>
              <Form.Item>
                {getFieldDecorator("email", {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(
                  <Input
                    addonBefore="@"
                    disabled={loading}
                    placeholder={freire(texts.EMAIL_EX)}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Button
                loading={loadingLocale}
                style={{ top: 3 }}
                onClick={() => this.addUserPerEmail()}
              >
                {freire(texts.ADD_BY_DOMAIN)}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Form.Item label={freire(texts.ADD_USER_BY_QRCODE)}>
                {getFieldDecorator("addOnQr", {
                  valuePropName: "checked",
                })(
                  <Switch
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Show when={"developer"}>
            <Row>
              <Col span={10}>
                <Form.Item label={freire(texts.OCPI_APPS)}>
                  {getFieldDecorator("apps", {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Select
                    mode="multiple"
                    disabled={loading}
                    style={{ width: "100%" }}
                    placeholder={freire(texts.ADD_OCPI_APP)}
                  >
                    {apps.map((app) => {
                      return (
                        <Option key={app.alias} value={app.alias}>
                          {`${app.name} (${app.alias})`}
                        </Option>
                      );
                    })}
                  </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={3}></Col>
              <Col span={10}>
              <Form.Item label={freire(texts.OCPI_PARTNERS)}>
                  {getFieldDecorator("ocpiPartyIds", {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Select
                    mode="multiple"
                    disabled={loading}
                    style={{ width: "100%" }}
                    placeholder={freire(texts.ADD_OCPI_PARTNER)}
                  >
                    {companies
                      .filter((company) => company.ocpiPartyId)
                      .map((company) => {
                        return (
                          <Option key={company.ocpiPartyId} value={company.ocpiPartyId}>
                            {`${company.companyName} (${company.ocpiPartyId})`}
                          </Option>
                        );
                      })
                    }
                  </Select>
                  )}
                </Form.Item></Col>
            </Row>
          </Show>
          <Row>
            <Col span={10}>
              <Form.Item label={freire(texts.VISIBLE_TO_EVERYONE)}>
                {getFieldDecorator("visibleToEveryone", {
                  valuePropName: "checked",
                })(
                  <Switch
                    onChange={(value) => {
                      this.setState({
                        visibleToEveryone: value,
                      });
                    }}
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                  />
                )}
              </Form.Item>
            </Col>
            { visibleToEveryone ? null : ( <>
              <Col span={3}></Col>
              <Col span={10}>
                <Form.Item label={freire(texts.METHOD_ADD_USERS)}>
                  <Select
                    defaultValue={addType}
                    onChange={(value) => {
                      this.setState({
                        addType: value,
                      });
                    }}
                    placeholder={freire(texts.SELECT_METOD)}
                  >
                    <Option value="planilha">{freire(texts.SHEET)}</Option>
                    <Option value="email">{freire(texts.EMAIL)}</Option>
                  </Select>
                </Form.Item>
              </Col> 
            </> )}
          </Row>
          <Row>
            { visibleToEveryone ? null : ( <>
              {addType === "planilha" ? (
                <Col span={24}>
                  <h2>{freire(texts.SELECT_SHEET)}</h2>
                  <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture"
                    customRequest={({ onSuccess, file }) => onSuccess()}
                    beforeUpload={(file) => {
                      if (file.type !== "text/csv") {
                        notification.error({
                          message: freire(texts.OOPS),
                          description: freire(texts.ONLY_CSV_ALLOWED),
                        });
                        return false;
                      }

                      this.readFile(file);
                      return true;
                    }}
                    onChange={(info) => {
                      if (!info.fileList.length)
                        this.setState({ dataSource: [], responseListEmail: [] });
                      this.setState({
                        fileList: info.fileList.filter((file) => !!file.status),
                      });
                    }}
                    fileList={fileList}
                  >
                    <Button
                      disabled={fileList.length === 1}
                      style={{ width: "100%", height: 66, overflow: "hidden" }}
                      size="large"
                    >
                      <Icon type="upload" />
                      <span>{freire(texts.CLICK_OR_DRAG_HERE)}</span>
                    </Button>
                  </Upload>
                  <Row>
                    <Col>
                      <Row>
                        {dataSource.length > 0 ? (
                          <div style={{ margin: 10, marginLeft: 0 }}>
                            {freire(texts.EMAILS_FOUND(dataSource.length))}
                          </div>
                        ) : null}
                      </Row>
                      {responseListEmail.emails &&
                      responseListEmail.emails.length > 0 ? (
                        <CSVLink
                          filename={freire(texts.CSV_FILE)}
                          className="btn btn-primary"
                          data={this.state.dateFiltrada}
                          headers={headers}
                        >
                          {freire(texts.DOWNLOAD_CSV_FILE)}
                        </CSVLink>
                      ) : dataSource.length > 0 ? (
                        <Button
                          loading={loadingLocale}
                          style={{ marginBottom: 10 }}
                          onClick={() => this.addUserListEmail()}
                        >
                          {freire(texts.ADD_EMAILS_FOUNDED)}
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
              ) : null}
              {addType === "email" ? (
                <div>
                  <Input
                    placeholder={freire(texts.EMAIL_EX)}
                    value={email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                  <Button
                    loading={loadingLocale}
                    style={{ marginTop: 10, marginBottom: 10 }}
                    onClick={() => this.addEmail()}
                  >
                    {freire(texts.ADD_EMAIL)}
                  </Button>
                </div>
              ) : null}
              </>
            )}
          </Row>
        </Form>

        { visibleToEveryone ? null : ( <>
          <Table
            style={{ backgroundColor: "white", marginTop: 20 }}
            //scroll={{ x: 1300 }}
            tableLayout="auto"
            rowKey={(data) => data._id}
            loading={loadingTable}
            dataSource={dateFiltrada}
            columns={columns}
            size="small"
          /> 
          <Row>
            <Col span={16}></Col>
            <Col span={8}>
              {dateFiltrada.length > 0 ? (
                <Tooltip placement="top" title={freire(texts.DELETE)}>
                  <Popconfirm
                    placement="top"
                    title={freire(texts.CONFIRM_DELETE)}
                    okText={freire(texts.YES)}
                    cancelText={freire(texts.NO)}
                    onConfirm={() => this.deleteAllUsersVisibility()}
                  >
                    <Button
                      style={{ marginTop: 10, marginBottom: 10 }}
                      //shape="circle"
                      size="small"
                      type="dashed"
                    >
                      {freire(texts.DELETE_ALL_USERS)}
                      <Icon type="delete" />
                    </Button>
                  </Popconfirm>
                </Tooltip>
              ) : null}
            </Col>
          </Row>
        </> )}

        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
            zIndex: 9999,
          }}
        >
          <Button
            disabled={loading}
            onClick={() => onClose()}
            style={{ marginRight: 8 }}
          >
            {freire(texts.CANCEL)}
          </Button>
          <Button loading={loading} onClick={() => this.save()} type="primary">
            {freire(texts.SAVE)}
          </Button>
        </div>
      </Drawer>
    );
  }
}

const ComponentEditDrawer = Form.create({ name: "edit" })(EditDrawer);
export default withRouter(ComponentEditDrawer);
