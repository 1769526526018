import React, { Component } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Drawer,
  Switch,
  Select,
  message,
  Radio,
  notification,
  Divider,
  Icon,
  Alert,
  Popover,
  InputNumber,
  DatePicker,
  Spin,
  Tag,
} from "antd";
import "./index.css";
import api from "../../services/api";
import imgController from "../../assets/img/controller.png";
import moment from "moment-timezone";

import { withRouter } from "react-router-dom";
import { Show } from "../../contexts/PermissionContext";
import { FreireContext } from "../../utils/freireContext";
import * as texts from "./locales";
import TitleTopicEditDrawer from "../TitleTopicEditDrawer";
import RadioGroup from "../TupiFielsForms/RadioGroup";
import TupiSelect from "../TupiSelect";
import EnergyRemunerationTable from "./EnergyRemunerationTable";
import RemunerationMediaTable from "./RemunerationMediaTable";
import SelectLocation from "../SelectLocation";
import SelectCountry from "../SelectCountry";
import { formatters } from "../../utils/formatters";
import { POWER_AC, POWER_DC } from "../../constants/power";
import { sorter } from "../../utils/sorter";
import BusinessHour from "../BusinessHour";
import TextToolTip from "../TextToolTip/index";
import { formatarMoeda } from "../FormatingUtils";
import {
  IdleChargePeriodDefault,
  WayToChargeIdleFee,
} from "../../constants/station";
import { COUNTRY_BR } from "../../constants/countries";
import {
  ACTIVATE_ACTIVATION_FEE,
  ACTIVATION_FEE_VALUE_WITH_CURRENCY,
  ACTIVATION_FEE_KWH_VALUE_EXEMPTION,
  ACTIVATION_FEE_CHARGE_VALUE_EXEMPTION,
  EXEMPT_ACTIVATION_FEE_BY_CONSUMPTION,
  EXEMPT_ACTIVATION_FEE_BY_CONSUMPTION_TOOLTIP,
  CONSUMPTION_KWH_LABEL,
} from "../../locales";
import { disabled_activation_fee } from "../ActivationFee";
import { getCompanyCurrentUser } from "../../services/auth";
import { BRAND } from "../../config";
import { StationPricePerHour } from "../StationPricePerHour";
import {
  orderPrices,
  validateOverlappingRangesInPricePerHour,
} from "../StationPricePerHour/validators";
import { shouldShowPricePerHour } from "../../utils/get-price-per-hour-infos";

const { Option } = Select;

// TODO: GET /contract-models
// type ContractModel = {
//   alias: string;
//   name: string;
//   fullName?: string;
//   zendeskTag: string;
// };
export const contractModelOptions = (freire) => [
  {
    label: freire(texts.CAAS_CONTRACT_MODEL),
    value: "caas",
  },
  {
    label: freire(texts.EAAS_CONTRACT_MODEL),
    value: "eaas",
  },
  {
    label: freire(texts.SAAS_CONTRACT_MODEL),
    value: "saas",
  },
];

const genericOptions = (freire) => [
  {
    label: freire(texts.NO_INFO),
    value: null,
  },
  { label: freire(texts.YES), value: true },
  { label: freire(texts.NO), value: false },
];

class EditDrawer extends Component {
  static contextType = FreireContext;

  fields = [
    "name",
    "freeParking",
    "address",
    "lat",
    "lng",
    "phone",
    "disabled",
    "companyID",
    "private",
    "acceptCoupon",
    "payment",
    "paymentCharge",
    "plugs",
    "users",
    "stationFlags",
    "stationParkingSpots",
    "stationPartners",
    "businessHoursPeriod",
    "idleFee",
    "adapters",
    "activation_fee",
  ];
  state = {
    plugs: [],
    connectedPlugs: [],
    plugsSelecteds: [],
    listLocals: [],
    listTypes: [],
    listModels: [],
    locations: [],
    remunerationMedia: undefined,
    remunerationEnergy: undefined,
    plugsConcurrently: undefined,
    isPowerDividedToPlugs: undefined,
    tupiInstall: undefined,
    splitRemunerationMedia: [],
    hasPartnership: false,
    isBusinessHourSaved: true,
    saveTry: false,
    companyID: undefined,
    hasAdapters: false,
    companyPartners: [],
    station: {},
    loadingStation: false,
    tupiUser: false,
    showPricePerHourForm: false,
    parentCompanyID: null,
    parentCompanyName: "...",
  };

  async componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible) {
      if (this.props.visible) {
        this.setState({
          loadingStation: true,
        });
        await this.getStation();

        const {
          connectedDate,
          installedAt,
          operationStartedAt,
          contractStartedAt,
          contractEndedAt,
          firstPaymentAt,
          splitRemunerationMedia,
          contractModel,
          idleFee,
          companyID,
          location_id,
          price_per_hour,
          ...rest
        } = this.state?.station;
        this.getLocation(location_id);
        this.getAllLocations(companyID);

        this.getUserCompanyID();
        this.listCompanyPartners();
        this.getPlugs();
        this.getStationLocals();
        this.getStationModels();
        this.getStationTypes();
        this.props.form.resetFields();
        this.props.form.setFieldsValue({
          ...rest,
          companyID,
          contractModel: contractModel === null ? undefined : contractModel,
          connectedDate: connectedDate === null ? null : moment(connectedDate),
          installedAt: installedAt === null ? null : moment(installedAt),
          operationStartedAt:
            operationStartedAt === null ? null : moment(operationStartedAt),
          contractStartedAt:
            contractStartedAt === null ? null : moment(contractStartedAt),
          contractEndedAt:
            contractEndedAt === null ? null : moment(contractEndedAt),
          firstPaymentAt:
            firstPaymentAt === null ? null : moment(firstPaymentAt),
          idleFee: {
            ...idleFee,
            gracePeriod: moment.duration(idleFee?.gracePeriod).asMinutes(),
          },
          disabled: !this.state.station.disabled,
        });

        this.setState({
          plugsSelecteds: this.state.station.plugs,
          connectedPlugs: this.state.station.connectedPlugs,
          remunerationMedia: this.state.station.remunerationMedia,
          remunerationEnergy: this.state.station.remunerationEnergy,
          plugsConcurrently: this.state.station.plugsConcurrently,
          isPowerDividedToPlugs: this.state.station.isPowerDividedToPlugs,
          tupiInstall: this.state.station.tupiInstall,
          splitRemunerationMedia: this.state.station.splitRemunerationMedia,
          hasPartnership:
            !!this.state.station?.stationPartners &&
            this.state.station?.stationPartners.length > 0,
          hasAdapters: this.state.station?.adapters?.length > 0,
          showPricePerHourForm: false,
        });

        this.setState({ loadingStation: false });
      } else {
        this.setState({
          plugsSelecteds: [],
          connectedPlugs: [],
        });
      }
    }
  }

  async getUserCompanyID() {
    const result = await getCompanyCurrentUser();
    if (result) {
      this.setState({
        tupiUser: result.id === BRAND && result.parentCompanyID === BRAND,
      });
    }
  }

  async getStation() {
    const { freire } = this.context;
    try {
      const response = await api.get(`/admin/station/${this.props.station_id}`);
      const { parentCompanyID } = response.data;
      this.setState({ station: response.data, parentCompanyID });
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_GET_STATION),
      });
    }
  }

  async getStationLocals() {
    const { freire } = this.context;
    try {
      const response = await api.get(`station-locals`);
      this.setState({ listLocals: response.data });
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_GET_STATIONS_LOCATIONS),
      });
    }
  }

  async getStationModels() {
    const { freire } = this.context;
    try {
      const response = await api.get(`station-models`);
      this.setState({ listModels: response.data });
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_GET_STATIONS_MODELS),
      });
    }
  }

  async getStationTypes() {
    const { freire } = this.context;

    try {
      const response = await api.get(`station-types`);
      this.setState({ listTypes: response.data });
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_GET_STATIONS_TYPES),
      });
    }
  }

  async getLocation(locationId) {
    const { freire } = this.context;
    try {
      const { data: location } = await api.get(`locations/${locationId}`);

      this.setState({ locations: [location], companyId: location.company_id });
      this.props.form.setFieldsValue({ location_id: location.id });
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_GET_LOCATIONS),
      });
    }
  }

  async getAllLocations(companyId) {
    const { freire } = this.context;
    try {
      const params =
        companyId === null ? { no_company: true } : { company_id: [companyId] };
      const response = await api.get("locations", {
        params,
      });
      this.setState({ locations: response.data, companyId });
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_GET_LOCATIONS),
      });
    }
  }

  async getPlugs(params = {}) {
    const { freire } = this.context;

    this.setState({ loadingPlugs: true, plugs: [] }, async () => {
      try {
        const { data: plugs } = await api.get(`plugs`, { params });

        this.setState({ plugs });
      } catch (error) {
        notification.error({
          message: "Ops...",
          description: freire(texts.ERROR_GET_PLUGS),
        });
      }
    });
  }

  async listCompanyPartners() {
    try {
      const { data } = await api.get("company");
      const { parentCompanyID } = this.state;
      const parentCompany = data.find(
        (company) => company._id === parentCompanyID
      );
      const parentCompanyName = parentCompany
        ? parentCompany.companyName
        : null;

      this.setState({
        companyPartners: data,
        parentCompanyName,
      });
    } catch (err) {
      console.log("Error fetching companies: ", err);
    }
  }

  clearWarningsStates() {
    this.setState({ saveTry: false, isBusinessHourSaved: true });
  }

  save() {
    const { freire } = this.context;

    if (!this.state.saveTry) {
      if (!this.state.isBusinessHourSaved) {
        message.warning(freire(texts.WARNING_BUSINESS_HOUR));
        this.setState({ saveTry: true });
        return;
      }
    } else {
      message.warning(freire(texts.WARNING_BUSINESS_HOUR_NOT_SAVED));
      this.clearWarningsStates();
    }
    this.props.form.validateFieldsAndScroll(
      { scroll: { offsetTop: 75 } },
      async (err, station) => {
        if (!this.state.hasPartnership) {
          station.stationPartners = [];
        }
        if (err) {
          return;
        }

        const { connectedPlugs, splitRemunerationMedia } = this.state;
        var { payment, paymentCharge, idleFee, activation_fee } = station;

        if (station.price_per_hour_enable) {
          if (!this.state.showPricePerHourForm) {
            station.price_per_hour_unchanged = true;
          } else if (
            station.price_per_hour &&
            station.price_per_hour.length > 0
          ) {
            const prices = orderPrices(station.price_per_hour);
            const valid = validateOverlappingRangesInPricePerHour(prices);

            if (valid) {
              message.warning(
                freire(texts.ERROR_PRICE_PER_HOUR_OVERLAPPING_RANGES)
              );
              return;
            }

            station.paymentCharge.value = station.price_per_hour_default_value;

            station.price_per_hour_default_value = parseInt(
              station?.price_per_hour_default_value?.replace(/[.,]/g, "") ?? 0
            );

            station.price_per_hour = station.price_per_hour.map((hour) => ({
              ...hour,
              value: parseInt(hour?.value?.replace(/[.,]/g, "") ?? 0),
              start_time: hour.start_time.format("HH:mm"),
              end_time: hour.end_time.format("HH:mm"),
              timezone: moment.tz.guess(),
            }));
          }
        } else {
          station.price_per_hour = [];
        }

        if (payment) {
          station.payment.value = Number(
            station.payment.value.replace(/[.,]/g, "")
          );

          if (payment.enabled && station.payment.value === 0) {
            message.warning(freire(texts.ERROR_INFO_RESERVE_PAYMENT));
            return;
          }
        }

        if (paymentCharge) {
          if (paymentCharge.enabled && paymentCharge.method === "") {
            message.warning(freire(texts.ERROR_INFO_CHARGE_PAYMENT_METHOD));
            return;
          }

          station.paymentCharge.value = Number(
            station.paymentCharge.value.replace(/[.,]/g, "")
          );

          if (paymentCharge.enabled && station.paymentCharge.value === 0) {
            message.warning(freire(texts.ERROR_INFO_CHARGE_PAYMENT));
            return;
          }

          if (
            paymentCharge?.method === "Time" &&
            (!paymentCharge.period ||
              !parseInt(paymentCharge.perTime) > 0 ||
              !paymentCharge.timeWindow)
          ) {
            message.warning(freire(texts.ERROR_INFO_CHARGE_PAYMENT_METHOD));
            return;
          }
        }

        if (connectedPlugs) {
          station.connectedPlugs = connectedPlugs;
        }

        station.disabled = !station.disabled;
        station.connectedDate = station.connectedDate?.toISOString();
        station.splitRemunerationMedia = splitRemunerationMedia;
        if (!station.partnerInstallerName) station.partnerInstallerName = null;
        if (!station.contractModel) station.contractModel = null;

        if (idleFee?.enabled) {
          if (idleFee.value === "0,00" || idleFee.gracePeriod < 1) {
            message.warning(freire(texts.ERROR_INFO_IDLE_FEE));
            return;
          }
          //TODO: desmockar wayToCharge e chargePeriod
          station.idleFee = {
            enabled: idleFee.enabled,
            value: Number(idleFee.value.replace(",", "")),
            gracePeriod: moment.duration(idleFee.gracePeriod, "minutes"),
            wayToCharge: WayToChargeIdleFee.PER_DURATION,
            chargePeriod: IdleChargePeriodDefault,
          };
        } else {
          station.idleFee = {
            enabled: false,
            value: 0,
            gracePeriod: "",
            chargePeriod: "",
            wayToCharge: WayToChargeIdleFee.NONE,
          };
        }

        if (activation_fee.enabled) {
          const {
            value,
            exemption_lower_charge_value,
            exemption_by_consumption_enabled,
            exemption_by_consumption_value,
          } = activation_fee;

          station.activation_fee = {
            enabled: activation_fee.enabled,
            value: Number(value.replace(",", "")),
            exemption_lower_charge_value: exemption_lower_charge_value.replace(
              ",",
              ""
            ),
            exemption_by_consumption_enabled,
            exemption_by_consumption_value,
          };
        } else {
          station.activation_fee = disabled_activation_fee.activation_fee;
        }

        this.props.onSave(station);
      }
    );
  }

  handleLatLong = (value) => {
    const hasLatLong = value.includes(",");

    if (hasLatLong) {
      const [lat, lng] = value.split(", ");

      this.props.form.setFieldsValue({
        lat,
        lng,
      });
    }
  };

  handlePlugs = (value, e) => {
    this.setState({
      plugsSelecteds: value,
    });
  };

  handleAdaptersSwitch = (hasAdapters) => {
    this.setState({ hasAdapters });

    if (!hasAdapters) {
      this.props.form.setFieldsValue({ adapters: [] });
    }
  };

  addPlug() {
    var { connectedPlugs } = this.state;
    var newConnectedPlug = {
      plug_id: null,
      station_ID: this.state.station._id,
      current: null,
      power: null,
    };
    var newConnectedPlugs = connectedPlugs.concat(newConnectedPlug);

    this.setState({ connectedPlugs: newConnectedPlugs });
  }

  removePlugs() {
    this.setState({ connectedPlugs: [] });
  }

  changerPlug(plug, index) {
    var { connectedPlugs } = this.state;
    connectedPlugs[index].plug_id = plug;

    this.setState({ connectedPlugs });
  }

  getSummary = () => {
    const { paymentCharge } = this.props.form.getFieldsValue();
    const { freire } = this.context;

    if (
      paymentCharge &&
      paymentCharge.enabled &&
      paymentCharge.period !== "" &&
      paymentCharge.value &&
      paymentCharge.perTime > 0 &&
      paymentCharge.timeWindow !== ""
    ) {
      const timeWindow =
        paymentCharge.timeWindow === "min"
          ? freire(texts.METHOD_TIME_MIN)
          : freire(texts.METHOD_TIME_HOUR);
      if (paymentCharge.period === "relative") {
        return freire(
          texts.METHOD_RELATIVE_DESCRIPTION(
            paymentCharge.value,
            paymentCharge.perTime,
            timeWindow
          )
        );
      } else if (paymentCharge.period === "fixed") {
        return freire(
          texts.METHOD_FIXED_DESCRIPTION(
            paymentCharge.value,
            paymentCharge.perTime,
            timeWindow
          )
        );
      }
    }

    return freire(texts.PAYMENT_DESCRIPTION_INCOMPLETE);
  };
  setIsBusinessHourSaved(value) {
    this.setState({ isBusinessHourSaved: value });
  }

  onChangeLocation(id) {
    const location = this.state.locations.find(
      (location) => location.id === id
    );

    this.props.form.setFieldsValue({
      country: location.country,
      address: location.address,
      phone: location.phone,
      companyID: location.company_id || "",
      private: location.private,
      freeParking: location.freeParking,
    });

    if (location.country === COUNTRY_BR) {
      this.props.form.setFieldsValue({
        address2: {
          state: location.state,
          city: location.city,
        },
      });
    } else {
      this.props.form.setFieldsValue({
        address2: {
          province: location.state,
          city: location.city,
        },
      });
    }
  }

  handlePricePerHourForm() {
    this.setState((prevState) => ({
      showPricePerHourForm: !prevState.showPricePerHourForm,
    }));

    if (this.state.showPricePerHourForm) {
      this.props.form.resetFields(["price_per_hour"]);
    }
  }

  renderPricePerHour() {
    const { freire } = this.context;
    return this.state?.station?.price_per_hour?.map((hour) => (
      <Row key={hour.start_time + hour.end_time + hour.value}>
        <span className="price-per-hour-info">{freire(texts.VALUE)}</span>
        <Tag color="green" className="price-per-hour-info">
          {`${formatters.getCurrencySymbol(
            freire.userLanguage,
            this.state?.station?.currency
          )} ${formatarMoeda(String(hour.value))}`}
        </Tag>
        <span className="price-per-hour-info">{freire(texts.OF)}</span>
        <Tag color="blue" className="price-per-hour-info">
          {hour.start_time}
        </Tag>
        <span className="price-per-hour-info">{freire(texts.TO)}</span>
        <Tag color="blue" className="price-per-hour-info">
          {hour.end_time}
        </Tag>
        <Divider />
      </Row>
    ));
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { visible, onClose, loading, companies } = this.props;
    const {
      plugs,
      plugsSelecteds,
      connectedPlugs,
      listLocals,
      listModels,
      listTypes,
      remunerationMedia,
      remunerationEnergy,
      tupiInstall,
      splitRemunerationMedia,
      locations,
      hasAdapters,
      companyPartners,
      loadingStation,
      tupiUser,
      showPricePerHourForm,
    } = this.state;
    const {
      payment,
      paymentCharge,
      current,
      canReserve,
      companyID,
      idleFee,
      activation_fee,
    } = this.props.form.getFieldsValue();

    const { freire } = this.context;
    const loadingPaymentChargeValue =
      loading ||
      (paymentCharge &&
        (!paymentCharge.enabled || paymentCharge.method === ""));

    const selectedCountry =
      this.props.form.getFieldValue("country") ??
      this.state.station?.country ??
      COUNTRY_BR;

    const selectedCompany = companies.find(
      (company) => company._id === companyID
    );

    const selectedCompanyCurrency = formatters.currency(
      freire.userLanguage,
      selectedCompany?.currency
    )(null);

    const stationFlags = ["Shell", "Tupinambá"];

    const showActivationFee =
      (paymentCharge?.enabled && paymentCharge?.method !== "Fixo") ||
      activation_fee?.enabled;
    const isActivationFeeEnabled = getFieldValue("activation_fee.enabled");
    const isExemptionByConsumptionEnabled = getFieldValue(
      "activation_fee.exemption_by_consumption_enabled"
    );

    const showPricePerHourEnabledOption = shouldShowPricePerHour(
      this.props.form
    );

    const isPricePerHourEnabled =
      showPricePerHourEnabledOption &&
      this.props.form.getFieldValue("price_per_hour_enable");

    const formList = connectedPlugs?.map((item, index) => (
      <div style={{ width: "90%", marginLeft: "5%" }} key={index.toString()}>
        <span
          style={{
            color: "#7A84BF",
            fontWeight: "bold",
            marginBottom: 8,
          }}
        >
          {freire(texts.POWER_PLUG(index + 1))}
        </span>
        <Row gutter={16}>
          <Row gutter={16} style={{ height: 75, marginLeft: "4px" }}>
            <Col span={6}>
              <div
                style={{
                  color: "rgb(0,0,0)",
                  fontSize: 14,
                  fontWeight: "bold",
                  paddingBottom: 8,
                }}
              >
                {freire(texts.PLUG)}
              </div>
              <Select
                style={{ width: "100%" }}
                disabled={loading}
                defaultValue={item.plug_id}
                onChange={(e) => this.changerPlug(e, index)}
              >
                {plugsSelecteds &&
                  plugs
                    .filter((item) => plugsSelecteds.includes(item.name))
                    .map((plug) => (
                      <Option key={plug._id} value={plug._id}>
                        {plug.name}
                      </Option>
                    ))}
              </Select>
            </Col>
            <Col span={6}>
              <Form.Item label={freire(texts.CUSTOM_NAME)}>
                <Input
                  style={{ width: "100%" }}
                  placeholder={freire(texts.ENTER_CUSTOM_NAME)}
                  disabled={loading}
                  maxLength={5}
                  defaultValue={connectedPlugs[index]?.customName ?? ""}
                  onChange={(e) => {
                    const formattedValue = String(e.target.value ?? "").trim();

                    connectedPlugs[index].customName = formattedValue;
                    this.setState({ connectedPlugs });
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={6} style={{ height: 75 }}>
              <div
                style={{
                  color: "rgb(0,0,0)",
                  fontSize: 14,
                  fontWeight: "bold",
                  paddingBottom: 8,
                }}
              >
                {freire(texts.CURRENT)}
              </div>
              <Radio.Group
                disabled={loading}
                defaultValue={connectedPlugs[index].current}
                onChange={(e) => {
                  connectedPlugs[index].current = e.target.value;
                  connectedPlugs[index].power = null;
                  this.setState({ connectedPlugs });
                }}
                buttonStyle="solid"
              >
                <Radio.Button value="AC">AC</Radio.Button>
                <Radio.Button value="DC">DC</Radio.Button>
              </Radio.Group>
            </Col>
            <Col span={6} style={{ height: 75 }}>
              <div
                style={{
                  color: "rgb(0,0,0)",
                  fontSize: 14,
                  fontWeight: "bold",
                  paddingBottom: 8,
                }}
              >
                {connectedPlugs[index].current
                  ? freire(texts.POWER(connectedPlugs[index].current))
                  : freire(texts.POWER(""))}
              </div>
              <Select
                disabled={
                  (connectedPlugs[index] && !connectedPlugs[index].current) ||
                  loading
                }
                placeholder={freire(texts.SELECT_POWER)}
                defaultValue={connectedPlugs[index].power}
                onChange={(e) => {
                  connectedPlugs[index].power = e;
                  this.setState({ connectedPlugs });
                }}
              >
                {(connectedPlugs[index] &&
                connectedPlugs[index].current === "AC"
                  ? POWER_AC
                  : POWER_DC
                ).map((power) => (
                  <Option key={power} value={power}>
                    {`${power.toLocaleString(freire.userLanguage)} kW`}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Row>
        <Divider
          style={{
            marginTop: 0,
          }}
        />
      </div>
    ));

    return (
      <Drawer
        title={freire(texts.STATION_EDIT)}
        width={"50%"}
        onClose={() => (loading ? {} : onClose())}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose
      >
        {loadingStation && (
          <div className="loading">
            <Spin tip={freire(texts.LOOKING_FOR_STATION)} />
          </div>
        )}
        <Form id="form_edit_station" layout="vertical">
          <TitleTopicEditDrawer title={freire(texts.INFORMATION)} />
          <Row gutter={16} style={{ marginTop: 20 }}>
            <Col span={24}>
              <Form.Item label={freire(texts.NAME)}>
                {getFieldDecorator("name")(
                  <Input placeholder={freire(texts.NAME)} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            {/* TODO: expandir para 24 caso o user não tenha a permissão de editar a company (edit-company-station) */}
            <Show when={"edit-company-station"}>
              <Col span={12}>
                <Form.Item
                  style={{ marginBottom: "4px" }}
                  label={freire(texts.COMPANY)}
                >
                  {getFieldDecorator("companyID")(
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder={freire(texts.SELECT_COMPANY)}
                      onChange={(id) => {
                        this.getAllLocations(id);
                        this.props.form.resetFields([
                          "location_id",
                          "companyID",
                          "phone",
                          "address",
                          "address2",
                        ]);
                      }}
                    >
                      {tupiUser && (
                        <Option
                          label={freire(texts.WITHOUT_COMPANY)}
                          value={null}
                          style={{ borderBottom: "1px solid #D4D4D3" }}
                        >
                          {freire(texts.WITHOUT_COMPANY)}
                        </Option>
                      )}
                      {companies.map((company) => (
                        <Option
                          key={company._id}
                          value={company._id}
                          label={company.companyName}
                        >
                          {company.companyName}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Show>
            <Col span={12}>
              <Form.Item
                style={{ marginBottom: "4px" }}
                label={freire(texts.LOCALIZATION)}
              >
                {getFieldDecorator("location_id")(
                  <Select
                    showSearch
                    optionFilterProp="label"
                    onChange={(id) => this.onChangeLocation(id)}
                  >
                    {locations &&
                      sorter(locations).map((location) => (
                        <Option
                          disabled={location.country !== selectedCountry}
                          key={location.id}
                          value={location.id}
                          label={location.name}
                        >
                          {location.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <div className="ant-form-item-label">
                <label>{freire(texts.PARENT_COMPANY)}</label>
              </div>
              <Input
                value={this.state.parentCompanyName || ""}
                disabled
                style={{ marginBottom: "32px" }}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ display: "flex", alignItems: "center" }}>
            <Col span={12}>
              {!!companyID && (
                <Row>
                  <Switch
                    disabled={loading}
                    checked={this.state.hasPartnership}
                    onChange={(value) => {
                      this.setState({ hasPartnership: value });
                    }}
                  />
                  <span style={{ marginLeft: 12 }}>
                    {freire(texts.HAS_PARTNERSHIP)}
                  </span>
                </Row>
              )}
            </Col>
            <Col
              span={12}
              style={this.state.hasPartnership ? {} : { display: "none" }}
            >
              <Form.Item label={freire(texts.STATION_PARTNERSHIPS)}>
                {getFieldDecorator("stationPartners")(
                  <Select
                    allowClear
                    disabled={loading}
                    mode="multiple"
                    showSearch
                    optionFilterProp="children"
                  >
                    {companyPartners?.map((company) => (
                      <Option
                        disabled={company.country !== selectedCountry}
                        key={company._id}
                        value={company._id}
                      >
                        {company.companyName}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Form.Item label="">
                {getFieldDecorator("disabled", {
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled={loading}
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                  />
                )}
                <span style={{ marginLeft: 10 }}>
                  {freire(texts.STATION_ACTIVE)}
                </span>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="">
                {getFieldDecorator("freeParking", {
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                  />
                )}
                <span style={{ marginLeft: 10 }}>
                  {freire(texts.FREE_PARKING)}
                </span>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={freire(texts.PHONE)}>
                {getFieldDecorator("phone")(
                  <Input disabled placeholder={freire(texts.PHONE)} />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <SelectCountry
                form={this.props.form}
                label={texts.SELECT_COUNTRY}
                disabled={true}
                initialValue={selectedCountry}
                showWarn={false}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col>
              <Form.Item label={freire(texts.ADDRESS)}>
                {getFieldDecorator("address")(
                  <Input disabled placeholder={freire(texts.ADDRESS)} />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <SelectLocation
              disabled={true}
              form={this.props.form}
              countryCode={selectedCountry}
              currentLocation={this.state.station?.address2}
            />
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={freire(texts.LATITUDE)}>
                {getFieldDecorator("lat")(
                  <Input
                    onBlur={(e) => this.handleLatLong(e.target.value)}
                    disabled={loading}
                    style={{ width: "100%" }}
                    placeholder="Ex.: -00.0000"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={freire(texts.LONGITUDE)}>
                {getFieldDecorator("lng")(
                  <Input
                    disabled={loading}
                    style={{ width: "100%" }}
                    placeholder="Ex.: -00.0000"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item label="">
                {getFieldDecorator("private", {
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                  />
                )}
                <span style={{ marginLeft: 10 }}>
                  {freire(texts.PRIVATE_STATION)}
                </span>
              </Form.Item>
            </Col>
          </Row>
          <Divider style={{ margin: 0 }} />
          <TitleTopicEditDrawer title={freire(texts.OPERATION)} />
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={freire(texts.PLUGS)}>
                {getFieldDecorator("plugs")(
                  <Select
                    mode="multiple"
                    disabled={loading}
                    onChange={this.handlePlugs}
                  >
                    {plugs &&
                      sorter(plugs).map((plug) => (
                        <Option key={plug._id} value={plug.name}>
                          {plug.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={freire(texts.CURRENT)}>
                {getFieldDecorator("current")(
                  <Radio.Group
                    disabled={loading}
                    onChange={() => this.props.form.resetFields(["power"])}
                    buttonStyle="solid"
                  >
                    <Radio.Button value="AC">AC</Radio.Button>
                    <Radio.Button value="DC">DC</Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={freire(texts.POWER(current))}>
                {getFieldDecorator("power")(
                  <Select
                    disabled={!current || loading}
                    placeholder={freire(texts.SELECT_POWER)}
                  >
                    {(current === "AC" ? POWER_AC : POWER_DC).map((power) => (
                      <Option key={power} value={power}>
                        {`${power.toLocaleString(freire.userLanguage)} kW`}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {formList}
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <Button
                style={{
                  color: "#7A84BF",
                  textDecoration: "underline",
                  fontWeight: "bold",
                  borderWidth: 0,
                }}
                onClick={() => this.addPlug()}
              >
                {freire(texts.ADD_POWER_PLUG)}
              </Button>
            </div>
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <Button
                style={{
                  color: "#FC385C",
                  textDecoration: "underline",
                  fontWeight: "bold",
                  borderWidth: 0,
                }}
                onClick={() => this.removePlugs()}
              >
                {freire(texts.REMOVE_ALL_POWER_PLUGS)}
              </Button>
            </div>
          </div>

          <Row
            gutter={16}
            style={{
              display: "flex",
              alignItems: "flex-end",
              margin: "26px 28px 0",
            }}
          >
            <Col span={12}>
              <Form.Item label="">
                <Switch
                  disabled={loading}
                  checkedChildren={freire(texts.YES)}
                  unCheckedChildren={freire(texts.NO)}
                  checked={hasAdapters}
                  onChange={this.handleAdaptersSwitch}
                />
                <span style={{ marginLeft: 10 }}>
                  {freire(texts.ADD_ADAPTERS)}
                </span>
              </Form.Item>
            </Col>

            <Col span={12} style={!hasAdapters && { display: "none" }}>
              <Form.Item label={freire(texts.ADAPTERS)}>
                {getFieldDecorator("adapters", {
                  rules: [
                    {
                      required: hasAdapters,
                      message: freire(texts.REQUIRED_FIELD),
                    },
                  ],
                })(
                  <Select
                    mode="multiple"
                    disabled={loading}
                    onChange={this.handleAdapters}
                  >
                    {sorter(plugs).map((plug) => (
                      <Option key={plug._id} value={plug.name}>
                        {plug.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ marginTop: 0, marginBottom: 24 }} />

          <div style={{ fontWeight: 700, marginTop: 12 }}>
            {freire(texts.BUSINESSHOURS)}
          </div>
          <div>{freire(texts.BUSINESSHOURS_INFO)}</div>
          <Col style={{ marginLeft: 64, marginRight: 16, marginTop: 14 }}>
            <Form.Item label={freire(texts.OLD_BUSINESS_HOUR)}>
              {getFieldDecorator("businessHours")(
                <Input
                  disabled={loading}
                  placeholder={freire(texts.OLD_BUSINESS_HOUR_PLACEHOLDER)}
                />
              )}
            </Form.Item>
            <div
              style={{
                border: "1px solid #1890FF",
                borderRadius: 5,
                backgroundColor: "#E6F7FF",
                padding: 8,
                fontSize: 14,
              }}
            >
              <Icon
                type="warning"
                style={{ color: "#1890FF", marginRight: 8 }}
              />
              {freire(texts.OLD_BUSINESS_HOUR_WARNING)}
            </div>
          </Col>
          <Form.Item>
            {getFieldDecorator("businessHoursPeriod")(
              <BusinessHour
                loading={loading}
                form={this.props.form}
                station={this.state.station}
                setIsSaved={this.setIsBusinessHourSaved.bind(this)}
                isSaved={this.state.isBusinessHourSaved}
              />
            )}
          </Form.Item>

          <Show when={"edit-visibility-station"}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label={freire(texts.PLUGS_CONCURRENTLY)}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ whiteSpace: "pre-wrap", marginBottom: 16 }}>
                      {freire(texts.TEXT_CONNECTORS)}
                    </span>
                    {getFieldDecorator("plugsConcurrently")(
                      <Radio.Group
                        disabled={loading}
                        onChange={(e) => {
                          this.setState({
                            plugsConcurrently: e.target.value,
                          });
                          this.props.form.resetFields([
                            "isPowerDividedToPlugs",
                          ]);
                        }}
                        buttonStyle="solid"
                      >
                        <Radio.Button value={null}>
                          {freire(texts.NO_INFORMATIONS)}
                        </Radio.Button>
                        <Radio.Button value={true}>
                          {freire(texts.YES)}
                        </Radio.Button>
                        <Radio.Button value={false}>
                          {freire(texts.NO)}
                        </Radio.Button>
                      </Radio.Group>
                    )}
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row
              gutter={16}
              style={{
                display: getFieldValue("plugsConcurrently") ? "block" : "none",
              }}
            >
              <Col span={12}>
                <Form.Item label={freire(texts.SIMULTANEOUS_POWER)}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ whiteSpace: "nowrap", marginBottom: 16 }}>
                      {freire(texts.TEXT_POWER)}
                    </span>
                    {getFieldDecorator("isPowerDividedToPlugs")(
                      <Radio.Group
                        onChange={(e) =>
                          this.setState({
                            isPowerDividedToPlugs: e.target.value,
                          })
                        }
                        value={this.state.isPowerDividedToPlugs}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Radio value={true}>
                            {freire(texts.IS_DIVIDE_POWER)}
                          </Radio>
                          <Radio value={false}>
                            {freire(texts.FIXED_POWER_BETWEEN_PLUGS)}
                          </Radio>
                        </div>
                      </Radio.Group>
                    )}
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row
              style={{ display: "flex", alignItems: "flex-end" }}
              gutter={16}
            >
              <Col span={12}>
                <Form.Item label={freire(texts.VISIBILITY)}>
                  {getFieldDecorator("visibility")(
                    <Select
                      disabled={loading}
                      placeholder={freire(texts.SELECT_VISIBILITY_TYPE)}
                    >
                      <Option value={"all"}>{freire(texts.ALL)}</Option>
                      <Option value={"groups"}>{freire(texts.GROUPS)}</Option>
                      <Option value={"none"}>{freire(texts.HIDDEN)}</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Divider style={{ margin: 0, marginBottom: 24 }} />
          </Show>

          <span style={{ color: "#fd5876", fontWeight: "bold" }}>
            {freire(texts.COUPONS_PAYMENT)}
          </span>
          <Row style={{ marginTop: 8 }}>
            <span style={{ fontWeight: "bold" }}>{freire(texts.CHARGE)}</span>
          </Row>

          <Row gutter={24} style={{ marginTop: 8 }}>
            <Col span={8}>
              <Form.Item label="">
                {getFieldDecorator("paymentCharge.enabled", {
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled={loading}
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                    onChange={(value) => {
                      if (!value) {
                        this.props.form.setFieldsValue({
                          paymentCharge: {
                            value: 0,
                            method: "",
                            description: "",
                          },
                          idleFee: {
                            enabled: false,
                            value: 0,
                            gracePeriod: "",
                            chargePeriod: "",
                            wayToCharge: WayToChargeIdleFee.NONE,
                          },
                          activation_fee:
                            disabled_activation_fee.activation_fee,
                          price_per_hour_enable: false,
                          price_per_hour: undefined,
                        });
                      }
                    }}
                  />
                )}
                <span style={{ marginLeft: 10 }}>
                  {freire(texts.PAYMENT_CHARGE)}
                </span>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="">
                {getFieldDecorator("acceptCoupon", {
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled={loading}
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                  />
                )}
                <span style={{ marginLeft: 10 }}>
                  {freire(texts.ACCEPT_COUPONS_CHARGE)}
                </span>
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={24}
            style={!paymentCharge?.enabled ? { display: "none" } : {}}
          >
            <Col span={24}>
              <Form.Item label={freire(texts.METHOD)}>
                {getFieldDecorator("paymentCharge.method")(
                  <Radio.Group
                    disabled={loading}
                    onChange={({ target }) => {
                      this.props.form.resetFields([
                        "paymentCharge.value",
                        "paymentCharge.period",
                        "paymentCharge.timeWindow",
                        "paymentCharge.perTime",
                      ]);
                      this.props.form.setFieldsValue({
                        price_per_hour_enable: false,
                        price_per_hour: undefined,
                      });

                      if (target.value === "Fixo") {
                        this.props.form.setFieldsValue({
                          activation_fee:
                            disabled_activation_fee.activation_fee,
                        });
                      }
                    }}
                    buttonStyle="solid"
                  >
                    <Radio.Button value="kWh">
                      {freire(texts.METHOD_KWH)}
                    </Radio.Button>
                    <Radio.Button value="Time">
                      {freire(texts.METHOD_TIME)}
                    </Radio.Button>
                    <Radio.Button value="Fixo">
                      {freire(texts.METHOD_FIXED)}
                    </Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              span={12}
              style={isPricePerHourEnabled ? { display: "none" } : {}}
            >
              <Form.Item
                style={
                  paymentCharge?.method === "Time" || !paymentCharge?.enabled
                    ? { display: "none" }
                    : {}
                }
                label={`${freire(texts.VALUE)}${
                  paymentCharge?.method !== ""
                    ? paymentCharge?.method === "kWh"
                      ? ` ${freire(texts.OF_KWH)}`
                      : ` ${freire(texts.FIXED)}`
                    : ""
                } (${selectedCompanyCurrency})`}
              >
                {getFieldDecorator("paymentCharge.value", {
                  normalize: (input) => {
                    return input ? formatarMoeda(String(input)) : "0,00";
                  },
                })(
                  <Input
                    disabled={
                      loadingPaymentChargeValue ||
                      this.props.form.getFieldValue("price_per_hour_enable")
                    }
                    placeholder={freire(texts.VALUE_EX)}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row
            gutter={24}
            style={showPricePerHourEnabledOption ? {} : { display: "none" }}
          >
            <Col>
              <Form.Item>
                {getFieldDecorator("price_per_hour_enable", {
                  valuePropName: "checked",
                  initialValue: false,
                })(
                  <Switch
                    disabled={loading}
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                    onChange={(value) => {
                      if (!value) {
                        this.props.form.setFieldsValue({
                          price_per_hour_enable: false,
                          price_per_hour: [],
                        });
                      }
                    }}
                  />
                )}
                <span style={{ marginLeft: 10 }}>
                  {freire(texts.ACCEPT_PRICE_PER_HOUR)}
                </span>
              </Form.Item>
            </Col>
          </Row>
          <Row
            className="price-per-hour-container"
            style={isPricePerHourEnabled ? {} : { display: "none" }}
          >
            <Col>
              <Row style={!showPricePerHourForm ? {} : { display: "none" }}>
                {this.renderPricePerHour()}
              </Row>
              <Button
                type="link"
                onClick={() => this.handlePricePerHourForm()}
                icon={!showPricePerHourForm ? "up" : "down"}
                style={{ textAlign: "left" }}
              >
                {!showPricePerHourForm
                  ? freire(texts.CHANGE_TIME_SLOTS_WITH_CUSTOM_PRICE)
                  : freire(texts.CANCEL)}
              </Button>
              {showPricePerHourForm && (
                <Row>
                  <StationPricePerHour
                    isVisible={isPricePerHourEnabled}
                    loading={loading}
                    forms={this.props.form}
                    selectedCompanyCurrency={selectedCompanyCurrency}
                    showDefaultValue
                  />
                </Row>
              )}
            </Col>
          </Row>

          <div
            style={paymentCharge?.method !== "Time" ? { display: "none" } : {}}
          >
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item label={freire(texts.METHOD_CHARGE)}>
                  {getFieldDecorator("paymentCharge.period")(
                    <Radio.Group disabled={loading} buttonStyle="solid">
                      <Radio.Button value="relative">
                        {freire(texts.METHOD_TIME_RELATIVE)}
                      </Radio.Button>
                      <Radio.Button value="fixed">
                        {freire(texts.METHOD_TIME_FIXED)}
                      </Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={5}>
                <Form.Item label={freire(texts.VALUE)}>
                  {getFieldDecorator("paymentCharge.value", {
                    normalize: (input) => {
                      return input ? formatarMoeda(String(input)) : "0,00";
                    },
                  })(
                    <Input
                      style={{ minWidth: "9ch" }}
                      disabled={loadingPaymentChargeValue}
                      placeholder={freire(texts.VALUE_EX)}
                    />
                  )}
                </Form.Item>
              </Col>
              <div className="payment-period">
                {freire(texts.PAYMENT_PERIOD)}
              </div>
              <div className="div-payment-description">
                <Col span={4}>
                  <div style={{ marginTop: 6, minWidth: "6ch" }}>
                    {freire(texts.METHOD_DESCRIPTION)}
                  </div>
                </Col>
                <Col span={4}>
                  <Form.Item>
                    {getFieldDecorator("paymentCharge.perTime")(
                      <Input
                        style={{ minWidth: "6ch" }}
                        disabled={loadingPaymentChargeValue}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item>
                    {getFieldDecorator("paymentCharge.timeWindow")(
                      <Radio.Group disabled={loading} buttonStyle="solid">
                        <Radio.Button value="min">
                          {freire(texts.METHOD_TIME_MIN)}
                        </Radio.Button>
                        <Radio.Button value="hour">
                          {freire(texts.METHOD_TIME_HOUR)}
                        </Radio.Button>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
              </div>
            </Row>
            <Row>
              <div className="box-payment-description">
                <div className="box-icon-info">
                  <Icon type="info" className="icon-info" />
                </div>
                <div>
                  <div>{freire(texts.PAYMENT_CHARGE_RESUME)}</div>
                  <div>{this.getSummary()}</div>
                </div>
              </div>
            </Row>
          </div>

          <Row
            style={
              paymentCharge?.enabled ? { marginTop: 15 } : { display: "none" }
            }
          >
            <span style={{ fontWeight: "bold" }}>{freire(texts.IDLE_FEE)}</span>
          </Row>

          <Row
            gutter={24}
            style={
              paymentCharge?.enabled ? { marginTop: 8 } : { display: "none" }
            }
          >
            <Col span={8}>
              <Form.Item label="">
                {getFieldDecorator("idleFee.enabled", {
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled={loading}
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                  />
                )}
                <span style={{ marginLeft: 10 }}>
                  {freire(texts.ACCEPT_IDLE_FEE)}
                </span>
              </Form.Item>
            </Col>
          </Row>

          <Row
            gutter={24}
            style={!idleFee?.enabled ? { display: "none" } : { marginTop: 8 }}
          >
            <Col span={8}>
              <Form.Item
                label={freire(
                  texts.VALUE_PER_MINUTE(
                    formatters.currency(
                      freire.userLanguage,
                      this.state.station.currency
                    )(null)
                  )
                )}
              >
                {getFieldDecorator("idleFee.value", {
                  normalize: (input) => {
                    return input ? formatarMoeda(String(input)) : "0,00";
                  },
                })(<Input type="text" placeholder={freire(texts.VALUE_EX)} />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={freire(texts.GRACE_PERIOD)}>
                {getFieldDecorator("idleFee.gracePeriod")(
                  <Input
                    type="number"
                    min={1}
                    placeholder={freire(texts.GRACE_PERIOD_MINUTES)}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row
            style={showActivationFee ? { marginTop: 8 } : { display: "none" }}
          >
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator("activation_fee.enabled", {
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled={loading}
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                    onChange={(value) => {
                      if (!value) {
                        this.props.form.setFieldsValue(disabled_activation_fee);
                      }
                    }}
                  />
                )}
                <span style={{ marginLeft: 10 }}>
                  {freire(ACTIVATE_ACTIVATION_FEE)}
                </span>
              </Form.Item>
            </Col>
            <Col
              span={6}
              style={isActivationFeeEnabled ? {} : { display: "none" }}
            >
              <Form.Item
                label={freire(
                  ACTIVATION_FEE_VALUE_WITH_CURRENCY(selectedCompanyCurrency)
                )}
              >
                {getFieldDecorator("activation_fee.value", {
                  normalize: (input) => {
                    return input ? formatarMoeda(String(input)) : "0,00";
                  },
                })(<Input disabled={loading} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row style={isActivationFeeEnabled ? {} : { display: "none" }}>
            <Col span={8}>
              <Form.Item label={freire(ACTIVATION_FEE_KWH_VALUE_EXEMPTION)}>
                {getFieldDecorator("activation_fee.exemption_lower_stop_value")(
                  <InputNumber disabled={loading} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row style={isActivationFeeEnabled ? {} : { display: "none" }}>
            <Col span={12}>
              <Form.Item
                label={freire(
                  ACTIVATION_FEE_CHARGE_VALUE_EXEMPTION(selectedCompanyCurrency)
                )}
              >
                <Col span={5}>
                  {getFieldDecorator(
                    "activation_fee.exemption_lower_charge_value",
                    {
                      normalize: (input) => {
                        return input ? formatarMoeda(String(input)) : "0,00";
                      },
                    }
                  )(<Input disabled={loading} />)}
                </Col>
              </Form.Item>
            </Col>
          </Row>
          <Row style={isActivationFeeEnabled ? {} : { display: "none" }}>
            <Col span={10}>
              <Form.Item>
                {getFieldDecorator(
                  "activation_fee.exemption_by_consumption_enabled",
                  {
                    valuePropName: "checked",
                  }
                )(
                  <Switch
                    disabled={loading}
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                    onChange={(value) => {
                      if (!value) {
                        this.props.form.setFieldsValue({
                          activation_fee: {
                            ...activation_fee,
                            exemption_by_consumption_enabled: false,
                            exemption_by_consumption_value: 0,
                          },
                        });
                      }
                    }}
                  />
                )}
                <span style={{ marginLeft: 10 }}>
                  {freire(EXEMPT_ACTIVATION_FEE_BY_CONSUMPTION)}
                </span>
                <TextToolTip
                  icon="question-circle-o"
                  title={freire(EXEMPT_ACTIVATION_FEE_BY_CONSUMPTION)}
                  info={[
                    {
                      info: freire(
                        EXEMPT_ACTIVATION_FEE_BY_CONSUMPTION_TOOLTIP
                      ),
                    },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col
              span={6}
              style={isExemptionByConsumptionEnabled ? {} : { display: "none" }}
            >
              <Form.Item label={freire(CONSUMPTION_KWH_LABEL)}>
                {getFieldDecorator(
                  "activation_fee.exemption_by_consumption_value"
                )(<InputNumber placeholder="0" min={0} />)}
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ marginTop: 12 }}>
            <span style={{ fontWeight: "bold" }}>{freire(texts.RESERVE)}</span>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="">
                {getFieldDecorator("canReserve", {
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled={loading}
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                    onChange={(value) => {
                      if (!value) {
                        this.props.form.setFieldsValue({
                          payment: { enabled: false, value: 0 },
                        });
                      }
                    }}
                  />
                )}
                <span style={{ marginLeft: 10 }}>
                  {freire(texts.ACCEPT_RESERVE)}
                </span>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="">
                {getFieldDecorator("payment.enabled", {
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled={loading || !canReserve}
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                    onChange={(value) => {
                      if (!value) {
                        this.props.form.setFieldsValue({
                          payment: { value: 0 },
                        });
                      }
                    }}
                  />
                )}
                <span style={{ marginLeft: 10 }}>
                  {freire(texts.PAYMENT_RESERVE)}
                </span>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={freire(texts.RESERVE_VALUE_IN(selectedCompanyCurrency))}
              >
                {getFieldDecorator("payment.value", {
                  normalize: (input) => {
                    return input ? formatarMoeda(String(input)) : "0,00";
                  },
                })(
                  <Input
                    disabled={loading || (payment && !payment.enabled)}
                    placeholder={freire(texts.VALUE_EX)}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Divider style={{ margin: 0, marginBottom: 24 }} />
          <TitleTopicEditDrawer title={freire(texts.OTHER_SETTINGS)} />
          <Show when={"edit-test-station"}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="">
                  {getFieldDecorator("test", {
                    valuePropName: "checked",
                  })(
                    <Switch
                      disabled={loading}
                      checkedChildren={freire(texts.YES)}
                      unCheckedChildren={freire(texts.NO)}
                    />
                  )}
                  <span style={{ marginLeft: 10 }}>
                    {freire(texts.TEST_STATION)}
                  </span>
                </Form.Item>
              </Col>
            </Row>
          </Show>

          <Show when={"edit-controller-station"}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      {freire(texts.END_CHARGE_LABEL)}
                      <Popover
                        placement="topLeft"
                        title={freire(texts.LABEL)}
                        trigger="hover"
                        content={
                          <div style={{ width: 400 }}>
                            <Alert
                              message={freire(texts.END_CHARGE_LABEL_INFO)}
                              type="warning"
                              showIcon
                            />
                            <img
                              style={{ width: "100%", marginTop: 20 }}
                              src={imgController}
                              alt="info"
                            />
                          </div>
                        }
                      >
                        <Icon type="question-circle-o" />
                      </Popover>
                    </span>
                  }
                >
                  {getFieldDecorator("controller")(
                    <Input placeholder={freire(texts.BRAND_EX)} />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Show>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label={freire(texts.ALTERNATIVE_QR_CODE)}>
                {getFieldDecorator("alternativeQrCode")(
                  <Input
                    placeholder={freire(texts.ALTERNATIVE_QR_CODE_PLACEHOLDER)}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label={freire(texts.QUALIFICATION_DATE)}>
                {getFieldDecorator("connectedDate", {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(
                  <DatePicker
                    allowClear
                    style={{ width: "100%" }}
                    placeholder={freire(texts.SELECT_DATE_TIME)}
                    format="L LTS"
                    showTime={{
                      defaultValue: moment("23:59:59", "HH:mm:ss"),
                    }}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label={freire(texts.STATION_TYPE)}>
                {getFieldDecorator("stationTypeID")(
                  <Select disabled={loading}>
                    {listTypes.map((item) => {
                      return (
                        <Option key={item._id} value={item._id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={freire(texts.STATION_MODEL)}>
                {getFieldDecorator("stationModelID")(
                  <Select disabled={loading}>
                    {listModels.map((item) => {
                      return (
                        <Option key={item._id} value={item._id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={freire(texts.STATION_FLAG)}>
                {getFieldDecorator("stationFlags.Flag")(
                  <Select allowClear>
                    {stationFlags.map((flag) => (
                      <Option key={flag} value={flag}>
                        {flag}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label={freire(texts.STATION_PARKING_SPOT)}>
                {getFieldDecorator("stationParkingSpot")(
                  <InputNumber
                    min={0} // o zero é usado como valor default no banco
                    style={{ width: "100%" }}
                    placeholder={freire(texts.EX_PARAM("2"))}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item label={freire(texts.STATION_LOCAL)}>
                {getFieldDecorator("stationLocalID")(
                  <Select disabled={loading}>
                    {listLocals.map((item) => {
                      return (
                        <Option key={item._id} value={item._id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label={
                  <span>
                    {freire(texts.FLAG_TITLE_RECHARGE)}
                    <TextToolTip
                      title={freire(texts.FLAG_TITLE_RECHARGE)}
                      icon="question-circle-o"
                      info={[
                        {
                          name: freire(texts.FLAG_ON_THE_GO),
                          title: freire(texts.FLAG_ON_THE_GO_TITLE),
                          info: freire(texts.FLAG_ON_THE_GO_INFO),
                        },
                        {
                          name: freire(texts.FLAG_DESTINATION),
                          title: freire(texts.FLAG_DESTINATION_TITLE),
                          info: freire(texts.FLAG_DESTINATION_INFO),
                        },
                        {
                          name: freire(texts.FLAG_HOME_BASED),
                          title: freire(texts.FLAG_HOME_BASED_TITLE),
                          info: freire(texts.FLAG_HOME_BASED_INFO),
                        },
                        {
                          name: freire(texts.FLAG_HUB),
                          info: freire(texts.FLAG_HUB_INFO),
                        },
                      ]}
                    />
                  </span>
                }
              >
                {getFieldDecorator("stationFlags.TypeRecharge")(
                  <Select allowClear>
                    <Option value="onTheGo">
                      {freire(texts.FLAG_ON_THE_GO)}
                    </Option>
                    <Option value="destination">
                      {freire(texts.FLAG_DESTINATION)}
                    </Option>
                    <Option value="homeBased">
                      {freire(texts.FLAG_HOME_BASED)}
                    </Option>
                    <Option value="hub">{freire(texts.FLAG_HUB)}</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <span>
                    {freire(texts.FLAG_TITLE_ACCESS)}
                    <TextToolTip
                      title={freire(texts.FLAG_TITLE_ACCESS)}
                      icon="question-circle-o"
                      info={[
                        {
                          name: freire(texts.FLAG_PUBLIC),
                          info: freire(texts.FLAG_PUBLIC_INFO),
                        },
                        {
                          name: freire(texts.FLAG_SEMI_PUBLIC),
                          info: freire(texts.FLAG_SEMI_PUBLIC_INFO),
                        },
                        {
                          name: freire(texts.FLAG_PRIVATE),
                          info: freire(texts.FLAG_PRIVATE_INFO),
                        },
                      ]}
                    />
                  </span>
                }
              >
                {getFieldDecorator("stationFlags.TypeAccess")(
                  <Select allowClear>
                    <Option value="public">{freire(texts.FLAG_PUBLIC)}</Option>
                    <Option value="semiPublic">
                      {freire(texts.FLAG_SEMI_PUBLIC)}
                    </Option>
                    <Option value="private">
                      {freire(texts.FLAG_PRIVATE)}
                    </Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <span>
                    {freire(texts.FLAG_TITLE_ROAD)}
                    <TextToolTip
                      title={freire(texts.FLAG_TITLE_ROAD)}
                      icon="question-circle-o"
                      info={[
                        {
                          name: freire(texts.FLAG_ROAD),
                          info: freire(texts.FLAG_ROAD_INFO),
                        },
                        {
                          name: freire(texts.FLAG_URBAN),
                          info: freire(texts.FLAG_URBAN_INFO),
                        },
                      ]}
                    />
                  </span>
                }
              >
                {getFieldDecorator("stationFlags.TypeRoad")(
                  <Select allowClear>
                    <Option value="road">{freire(texts.FLAG_ROAD)}</Option>
                    <Option value="urban">{freire(texts.FLAG_URBAN)}</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Show when={"edit-station-contract-model"}>
            <TitleTopicEditDrawer title={freire(texts.CONTRACT_MODEL)} />
            <Row gutter={[16, 8]}>
              <Col>
                <Form.Item label={freire(texts.TUPI_CHARGER)}>
                  {getFieldDecorator("tupiPlug")(
                    <RadioGroup options={genericOptions(freire)} />
                  )}
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label={freire(texts.TUPI_INSTALLER)}>
                  {getFieldDecorator("tupiInstall")(
                    <RadioGroup
                      options={genericOptions(freire)}
                      onChangeCallback={(e) =>
                        this.setState({ tupiInstall: e.target.value })
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12} hidden={!tupiInstall}>
                <Form.Item label={freire(texts.PARTNER_INSTALLER)}>
                  {getFieldDecorator("partnerInstallerName")(
                    <Input placeholder={freire(texts.PARTNER_NAME)} />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={freire(texts.CONTRACT_MODEL_LOWER_CASE)}>
                  {getFieldDecorator("contractModel")(
                    <TupiSelect
                      placeholder={freire(texts.SELECT)}
                      options={contractModelOptions(freire)}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={freire(texts.REMUNERATION_ENERGY)}>
                  {getFieldDecorator("remunerationEnergy")(
                    <RadioGroup
                      onChangeCallback={(e) => {
                        this.setState({ remunerationEnergy: e.target.value });
                      }}
                      options={genericOptions(freire)}
                    />
                  )}
                </Form.Item>
              </Col>
              {remunerationEnergy === true && (
                <Col span={24}>
                  <EnergyRemunerationTable
                    data={this.state.station.company?.splitRule?.map(
                      (rule) => ({
                        key: rule._id,
                        receptor: rule.recipientName,
                        percentage: `${rule.percentage}%`,
                      })
                    )}
                  />
                </Col>
              )}
              <Col span={24}>
                <Form.Item label={freire(texts.REMUNERATION_MEDIA)}>
                  {getFieldDecorator("remunerationMedia")(
                    <RadioGroup
                      onChangeCallback={(e) => {
                        this.setState({ remunerationMedia: e.target.value });
                      }}
                      options={genericOptions(freire)}
                    />
                  )}
                </Form.Item>
              </Col>
              {remunerationMedia === true && (
                <Col span={24}>
                  <RemunerationMediaTable
                    data={splitRemunerationMedia || []}
                    updateData={(data) =>
                      this.setState({ splitRemunerationMedia: data })
                    }
                  />
                </Col>
              )}
              <Col span={12}>
                <Form.Item label={freire(texts.INSTALL_DATE)}>
                  {getFieldDecorator("installedAt")(
                    <DatePicker
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={freire(texts.SELECT_DATE_TIME)}
                      format="L LTS"
                      showTime={{
                        defaultValue: moment("23:59:59", "HH:mm:ss"),
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={freire(texts.START_OPERATION)}>
                  {getFieldDecorator("operationStartedAt")(
                    <DatePicker
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={freire(texts.SELECT_DATE_TIME)}
                      format="L LTS"
                      showTime={{
                        defaultValue: moment("23:59:59", "HH:mm:ss"),
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={freire(texts.START_CONTRACT)}>
                  {getFieldDecorator("contractStartedAt")(
                    <DatePicker
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={freire(texts.SELECT_DATE_TIME)}
                      format="L LTS"
                      showTime={{
                        defaultValue: moment("23:59:59", "HH:mm:ss"),
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={freire(texts.END_CONTRACT)}>
                  {getFieldDecorator("contractEndedAt")(
                    <DatePicker
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={freire(texts.SELECT_DATE_TIME)}
                      format="L LTS"
                      showTime={{
                        defaultValue: moment("23:59:59", "HH:mm:ss"),
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={freire(texts.FIRST_PAYMENT)}>
                  {getFieldDecorator("firstPaymentAt")(
                    <DatePicker
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={freire(texts.SELECT_DATE_TIME)}
                      format="L LTS"
                      showTime={{
                        defaultValue: moment("23:59:59", "HH:mm:ss"),
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Show>
        </Form>

        <div
          style={{
            zIndex: 9999,
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
          }}
        >
          <Button
            disabled={loading}
            onClick={() => onClose()}
            style={{ marginRight: 8 }}
          >
            {freire(texts.CANCEL)}
          </Button>
          <Button loading={loading} onClick={() => this.save()} type="primary">
            {freire(texts.SAVE)}
          </Button>
        </div>
      </Drawer>
    );
  }
}

const ComponentEditDrawer = Form.create({ name: "edit" })(EditDrawer);
export default withRouter(ComponentEditDrawer);
