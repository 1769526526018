import { useEffect, useState } from "react";
import {
  Button,
  Collapse,
  Dropdown,
  Empty,
  Icon,
  Input,
  Menu,
  Modal,
  notification,
  Radio,
  Select,
  Spin,
  Table,
  Tag,
} from "antd";

import * as texts from "../../../../../pages/Users/locales";
import { useFreire } from "../../../../../utils/freireContext";
import api from "../../../../../services/api";
import { Copy } from "../../../../../components/Copy";
import moment from "moment";
import {
  ProfileAdd,
  ProfileIcon,
  ProfileRemove,
} from "../../../../../assets/icons/users.svg";
import apiOcpi from "../../../../../services/apiOcpi";
import { TicketTag } from "../../../../../assets/icons/ticket.svg";

const { Panel } = Collapse;

const defaultModalData = {
  isVisible: false,
  data: null,
};

export const Tags = ({ userData, loadingModal, getUserDetails, opened = false }) => {
  const { freire } = useFreire();

  const [loadingStations, setLoadingStations] = useState(false);
  const [loadingLinkTag, setLoadingLinkTag] = useState(false);
  const [loadingRefreshTokens, setLoadingRefreshTokens] = useState(false);
  const [userTags, setUserTags] = useState(null);
  const [clickedActionIndex, setClickedActionIndex] = useState(null);
  const [clickedAddTag, setClickedAddTag] = useState(false);
  const [selectedAddTagOption, setSelectedAddTagOption] = useState("station");
  const [stations, setStations] = useState([]);
  const [updatedDate, setUpdatedDate] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null);
  const [tagInput, setTagInput] = useState(null);
  const [tokenLogs, setTokenLogs] = useState(null);

  const [currentModal, setCurrentModal] = useState(null);
  const [linkModal, setLinkModal] = useState(defaultModalData);
  const [unlinkModal, setUnlinkModal] = useState(defaultModalData);

  const ADD_TAG_OPTIONS = [
    {
      key: "station",
      title: freire(texts.BY_STATION),
    },
    {
      key: "cod",
      title: freire(texts.BY_CODE),
    },
  ];

  async function getStations() {
    try {
      setLoadingStations(true);
      setStations([]);

      const { data } = await api.get(`select/connected_stations`);

      setLoadingStations(false);
      setStations(data);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_GET_STATIONS),
      });
      setLoadingStations(false);
    }
  }

  const showModal = (data, type) => {
    setCurrentModal(type);

    if (type === "link") {
      setLinkModal({ isVisible: true, data: data });
    } else {
      setUnlinkModal({ isVisible: true, data: data });
    }
  };

  const handleCancel = () => {
    if (currentModal === "link") {
      setLinkModal({ ...linkModal, isVisible: false });
    } else {
      setUnlinkModal({ ...unlinkModal, isVisible: false });
    }
  };

  const handleLinkTag = async (token) => {
    if (!token) return;

    try {
      setLoadingLinkTag(true);
      const trimmedToken = token.trim();
      const payload = {
        cod: trimmedToken,
        userID: userData.userID,
      };

      await api.post("/rfid-tags", payload);
      notification.success({
        message: freire(texts.ALL_RIGHT),
        description: freire(texts.TAG_LINK_SUCCESS),
      });
      if (selectedAddTagOption === "cod") setTagInput(null);
      setLinkModal({ isVisible: false, data: null });
      getUserDetails();
      if (selectedAddTagOption === "station") setSelectedStation(null);
    } catch (error) {
      const message = error?.response?.data?.message;

      notification.error({
        message: freire(texts.OOPS),
        description: message || error.message,
      });
      setLoadingStations(false);
    } finally {
      setLoadingLinkTag(false);
    }
  };

  const handleUnLinkTag = async (id) => {
    try {
      await api.delete(`/rfid-tag/${id}`);
      notification.success({
        message: freire(texts.ALL_RIGHT),
        description: freire(texts.TAG_UNLINK_SUCCESS),
      });
      setUnlinkModal({ isVisible: false, data: null });
      getUserDetails();
    } catch (error) {
      const message = error?.response?.data?.message;

      notification.error({
        message: freire(texts.OOPS),
        description: message || error.message,
      });
    }
  };

  const handleTagsAlreadyLinked = (data, token = null) => {
    setTokenLogs(null);
    const filteredData = data.map((element) => ({
      ...element,
      alreadyLinked: userData.rfidTags.some(
        (tag) => tag.cod === element.token || tag.cod === token
      )
        ? true
        : false,
    }));

    setTokenLogs(filteredData);
  };

  const handleSelectStation = async (value) => {
    try {
      setSelectedStation(value);
      setUpdatedDate(new Date());
      setLoadingRefreshTokens(true);

      const response = await apiOcpi.get("/tokens", {
        params: { stationId: value },
      });
      const { data } = response.data;

      if (data.length === 0) setTokenLogs(data);

      handleTagsAlreadyLinked(data);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_GET_TOKENS),
      });
    } finally {
      setLoadingRefreshTokens(false);
    }
  };

  useEffect(() => {
    if (userData) {
      setUserTags(userData.rfidTags);
    }
  }, [userData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setClickedActionIndex(null);
    }, 500);
    return () => clearTimeout(timer);
  }, [clickedActionIndex]);

  return (
    <Collapse
      bordered={false}
      expandIconPosition="right"
      style={{ marginBottom: 30 }}
      defaultActiveKey={opened ? ["personalData"] : []}
    >
      <Panel
        key="personalData"
        id="panel"
        header={
          <span className="drawerInfoTitle">{freire(texts.TAGS_TITLE)}</span>
        }
      >
        {loadingModal && (
          <Spin
            style={{ display: "block", marginTop: 25, marginBottom: 25 }}
            tip={freire(texts.TAGS_LOADING)}
          />
        )}
        {!loadingModal && userTags && userTags.length === 0 && (
          <div style={{ marginBottom: 25 }}>
            <Empty description={freire(texts.USER_EMPTY_TAGS)} />
          </div>
        )}
        {!loadingModal && userTags && (
          <>
            {userTags.length > 0 && (
              <Table
                rowKey="_id"
                pagination={false}
                dataSource={userTags}
                columns={[
                  {
                    title: freire(texts.TAGS_COLUMN_ACTIVE),
                    dataIndex: "enabled",
                    key: "enabled",
                    render: (text, data, index) => (
                      <div>
                        {data.enabled ? (
                          <Icon type="check" />
                        ) : (
                          <Icon type="close" />
                        )}
                      </div>
                    ),
                  },
                  {
                    title: freire(texts.TAGS_COLUMN_COD),
                    dataIndex: "cod",
                    key: "cod",
                    render: (text, data, index) => (
                      <div>
                        <strong>
                          <Copy>{data.cod}</Copy>
                        </strong>
                      </div>
                    ),
                  },
                  {
                    title: freire(texts.TAGS_COLUMN_QUANTITY),
                    dataIndex: "amountUsage",
                    key: "amountUsage",
                    render: (text, data, index) => (
                      <div>
                        <strong>{data.amountUsage}</strong>
                      </div>
                    ),
                  },
                  {
                    title: freire(texts.TAGS_COLUMN_LAST_USAGE),
                    dataIndex: "lastUse",
                    key: "lastUse",
                    render: (text, data, index) => (
                      <div>
                        <Copy>
                          {data.lastUse &&
                            moment(data.lastUse).format("DD/MM/YYYY HH:mm")}
                        </Copy>
                      </div>
                    ),
                  },
                  {
                    title: freire(texts.COUPON_ACTIONS),
                    key: "actions",
                    render: (_, record, dataIndex) => (
                      <div>
                        <Dropdown
                          overlay={
                            <Menu onClick={() => showModal(record, "unlink")}>
                              <Menu.Item
                                key="unlink"
                                style={{ padding: "8px 24px" }}
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    minWidth: "135px",
                                    minHeight: "30px",
                                  }}
                                >
                                  <ProfileRemove
                                    size={{ width: "1.5em", height: "1.5em" }}
                                  />
                                  <span style={{ color: "#1890FF" }}>
                                    {freire(texts.TAG_UNLINK)}
                                  </span>
                                </span>
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <Button
                            style={{
                              padding: "0px 8px",
                              lineHeight: "20px",
                              height: "20px",
                              fontSize: "26px",
                              border: "none",
                              backgroundColor: "transparent",
                              color: "#000000",
                            }}
                          >
                            <div>...</div>
                          </Button>
                        </Dropdown>
                      </div>
                    ),
                  },
                ]}
              />
            )}

            <div style={{ marginTop: 25, marginBottom: 25 }}>
              {!clickedAddTag && (
                <>
                  <Icon
                    type="plus"
                    style={{ color: "#1890FF", paddingRight: 2 }}
                  />
                  <span
                    style={{
                      color: "#1890FF",
                      borderBottom: "1px solid",
                      display: "inline-block",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setClickedAddTag(true);
                      getStations();
                    }}
                  >
                    {freire(texts.ADD_TAG)}
                  </span>
                </>
              )}
              {clickedAddTag && (
                <>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <strong>{freire(texts.ADD_TAG)}</strong>

                    <Radio.Group
                      onChange={(event) =>
                        setSelectedAddTagOption(event?.target?.value)
                      }
                      buttonStyle="outline"
                      defaultValue="station"
                      style={{
                        paddingTop: 20,
                        paddingBottom: 20,
                      }}
                    >
                      {ADD_TAG_OPTIONS.map((method) => (
                        <Radio.Button key={method.key} value={method.key}>
                          {method.title}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </div>

                  {loadingStations && (
                    <Spin
                      style={{
                        display: "block",
                        marginTop: 25,
                        marginBottom: 25,
                      }}
                      tip={freire(texts.TAGS_LOADING)}
                    />
                  )}
                  {!loadingStations && selectedAddTagOption === "station" && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            gap: 6,
                          }}
                        >
                          <span style={{ color: "#595959" }}>
                            {freire(texts.STATION)}:
                          </span>
                          <Select
                            placeholder={freire(texts.SELECT_STATION)}
                            style={!selectedStation ? { width: "50%" } : {}}
                            onChange={(value) => handleSelectStation(value)}
                            showSearch
                            optionFilterProp="children"
                          >
                            {stations &&
                              stations.map((station) => (
                                <Select.Option
                                  key={station._id}
                                  value={station.stationID}
                                >
                                  {station.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </div>
                        {selectedStation && updatedDate && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              gap: 6,
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            <span style={{ color: "#9C9C9B", fontSize: 12 }}>
                              {freire(texts.TAG_UPDATED_ON)}{" "}
                              {moment(updatedDate).format("DD/MM/YYYY")}{" "}
                              {freire(texts.TAG_AT)}{" "}
                              {moment(updatedDate).format("HH:mm:ss")}
                            </span>
                            {loadingRefreshTokens ? (
                              <Spin />
                            ) : (
                              <span
                                style={{
                                  color: "#1890FF",
                                  fontSize: 12,
                                  borderBottom: "1px solid",
                                  display: "inline-block",
                                  cursor: "pointer",
                                  lineHeight: "12px",
                                }}
                                onClick={() =>
                                  handleSelectStation(selectedStation)
                                }
                              >
                                {freire(texts.TAG_REFRESH_NOW)}
                              </span>
                            )}
                          </div>
                        )}
                      </div>

                      {selectedStation && (
                        <div style={{ marginTop: 25 }}>
                          <Table
                            rowKey="uniqueId"
                            pagination={false}
                            dataSource={tokenLogs}
                            size="small"
                            columns={[
                              {
                                title: freire(texts.TAGS_LOGS_COLUMN_COD),
                                dataIndex: "token",
                                key: "token",
                                render: (text, data, index) => (
                                  <div>
                                    <strong>
                                      <Copy>{data.token}</Copy>
                                    </strong>
                                  </div>
                                ),
                              },
                              {
                                title: freire(texts.TAGS_LOGS_COLUMN_DATE),
                                dataIndex: "createdAt",
                                key: "createdAt",
                                render: (text, data, index) => (
                                  <div>
                                    {moment(data.createdAt).format(
                                      "DD/MM/YYYY - HH:mm:ss"
                                    )}
                                  </div>
                                ),
                              },
                              {
                                title: freire(texts.COUPON_ACTIONS),
                                key: "actions",
                                render: (_, record, dataIndex) => (
                                  <div>
                                    {record.alreadyLinked ? (
                                      <span
                                        style={{
                                          color: "#595959",
                                          fontSize: 12,
                                          alignSelf: "center",
                                        }}
                                      >
                                        {freire(texts.LINKED)}
                                      </span>
                                    ) : (
                                      <Dropdown
                                        overlay={
                                          <Menu
                                            onClick={() =>
                                              showModal(record, "link")
                                            }
                                          >
                                            <Menu.Item
                                              key="link"
                                              style={{ padding: "4px 16px" }}
                                            >
                                              <span
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent:
                                                    "space-between",
                                                  minWidth: "110px",
                                                  minHeight: "30px",
                                                }}
                                              >
                                                <ProfileAdd
                                                  size={{
                                                    width: "1.5em",
                                                    height: "1.5em",
                                                  }}
                                                />
                                                <span
                                                  style={{ color: "#1890FF" }}
                                                >
                                                  {freire(texts.TAG_LINK)}
                                                </span>
                                              </span>
                                            </Menu.Item>
                                          </Menu>
                                        }
                                      >
                                        <Button
                                          style={{
                                            padding: "0px 8px",
                                            lineHeight: "20px",
                                            height: "20px",
                                            fontSize: "26px",
                                            border: "none",
                                            backgroundColor: "transparent",
                                            color: "#000000",
                                          }}
                                        >
                                          <div>...</div>
                                        </Button>
                                      </Dropdown>
                                    )}
                                  </div>
                                ),
                              },
                            ]}
                          />
                        </div>
                      )}
                    </>
                  )}
                  {!loadingStations && selectedAddTagOption === "cod" && (
                    <div style={{ width: "50%", display: "flex" }}>
                      <Input
                        placeholder={freire(texts.TAGS_INSERT_COD)}
                        style={{ width: "100%" }}
                        value={tagInput}
                        onChange={(e) => setTagInput(e.target.value)}
                      />
                      <Button
                        type="primary"
                        style={{
                          padding: "0px 8px",
                          backgroundColor: "#1890FF",
                          color: "#FFFFFF",
                          marginLeft: 8,
                        }}
                        onClick={() => handleLinkTag(tagInput)}
                      >
                        <div>{freire(texts.LINK)}</div>
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}
        <Modal
          centered
          width={"50%"}
          style={{ maxWidth: 400 }}
          footer={null}
          closable={false}
          visible={linkModal.isVisible}
          maskClosable={true}
          maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          onCancel={handleCancel}
        >
          <div
            className="addCouponLabel"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <ProfileAdd />
            <span
              style={{
                textTransform: "uppercase",
                marginLeft: "7px",
                fontSize: "14px",
                color: "#1890FF",
              }}
            >
              {freire(texts.TAG_LINK)}
            </span>
          </div>
          {linkModal.isVisible && (
            <p
              style={{
                margin: "10px",
                fontSize: "15px",
                flexWrap: "wrap",
              }}
            >
              {freire(texts.LINK_TAG_PART)}
              <strong>{linkModal.data?.token}</strong>{" "}
              {freire(texts.TO_USER(userData.email))}
            </p>
          )}
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Button
              onClick={handleCancel}
              style={{
                width: "160px",
                height: "35px",
                borderRadius: "6px",
                border: "1px solid #CECECD",
                color: "#565559",
                fontSize: "16px",
                margin: "10px",
              }}
            >
              {freire(texts.CANCEL)}
            </Button>
            {linkModal.data && (
              <>
                {!loadingLinkTag ? (
                  <Button
                    type="primary"
                    onClick={() => handleLinkTag(linkModal.data.token)}
                    style={{
                      width: "160px",
                      height: "35px",
                      borderRadius: "6px",
                      backgroundColor: "#1890FF",
                      color: "#FFFFFF",
                      fontSize: "16px",
                      margin: "10px",
                      border: "none",
                    }}
                  >
                    {freire(texts.LINK)}
                  </Button>
                ) : (
                  <div
                    style={{
                      width: "160px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Spin
                      style={{
                        display: "block",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </Modal>
        <Modal
          centered
          width={"50%"}
          style={{ maxWidth: 400 }}
          footer={null}
          closable={false}
          visible={unlinkModal.isVisible}
          maskClosable={true}
          maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          onCancel={handleCancel}
        >
          <div
            className="addCouponLabel"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <ProfileRemove size={{ width: "1.7em", height: "1.7em" }} />
            <span
              style={{
                textTransform: "uppercase",
                marginLeft: "7px",
                fontSize: "16px",
                color: "#1890FF",
              }}
            >
              {freire(texts.TAG_UNLINK)}
            </span>
          </div>
          <Tag
            style={{
              borderRadius: "6px",
              display: "inline-flex",
              flexDirection: "column",
              fontSize: "16px",
              backgroundColor: "#F3F2F3",
              border: "none",
              color: "#747277",
              padding: "5px 10px",
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginBottom: "5px",
                marginTop: "5px",
              }}
            >
              <div style={{ marginRight: "4px", marginLeft: "4px" }}>
                <TicketTag />
              </div>
              <span>{unlinkModal.data && unlinkModal.data.cod}</span>
            </span>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div style={{ marginRight: "4px" }}>
                <ProfileIcon
                  color="#1890FF"
                  size={{ width: "1.5rem", height: "1.5rem" }}
                />
              </div>
              <span>{userData && userData.email}</span>
            </span>
          </Tag>
          <p style={{ margin: "10px", fontSize: "16px" }}>
            {freire(texts.ARE_YOU_SURE_UNLINK_TAG)}
          </p>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {unlinkModal.data && (
              <Button
                type="primary"
                onClick={() => handleUnLinkTag(unlinkModal.data?._id)}
                style={{
                  width: "145px",
                  height: "40px",
                  borderRadius: "6px",
                  backgroundColor: "#F40000",
                  color: "#FFFFFF",
                  fontSize: "17px",
                  margin: "10px",
                  border: "none",
                }}
              >
                {freire(texts.UNLINK)}
              </Button>
            )}
            <Button
              onClick={handleCancel}
              style={{
                width: "145px",
                height: "40px",
                borderRadius: "6px",
                border: "1px solid ##CECECD",
                color: "#565559",
                fontSize: "17px",
                margin: "10px",
              }}
            >
              {freire(texts.CANCEL)}
            </Button>
          </div>
        </Modal>
      </Panel>
    </Collapse>
  );
};
