import { BusinessHourProps, IDay, IWeek } from "./interfaces";
import { notification } from "antd";
import { useFreire } from "../../utils/freireContext";
import { useState, createContext } from "react";
import * as texts from "./locales";
import moment from "moment";
import "./index.css";
import Timezone from "./components/Timezone";
import Week from "./components/Week";
import Footer from "./components/Footer";
import Options from "./components/Options";

declare namespace Intl {
  type Key =
    | "calendar"
    | "collation"
    | "currency"
    | "numberingSystem"
    | "timeZone"
    | "unit";

  function supportedValuesOf(input: Key): string[];
}

const WEEK_DAYS = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const FIRST_HOUR_OF_THE_DAY = "00:00";

const LAST_HOUR_OF_THE_DAY = "23:59";

const DEFAULT_CLOSING_TIME = "22:00";

const EMPTY_HOUR = "";

export const BusinessHourContext = createContext({
  disabled: false,
  loading: true,
  isSemiPublic: true,
  onChangeTimezone: (value: string) => {},
  onChangeOpenDay: (day: string) => {},
  onCopyDayInWeek: (copyDay: IDay) => {},
  onChangeDayInTwentyFourHours: (value: boolean, weekDay: string) => {},
  onNewHourInDay: (weekDay: string) => {},
  onDeleteHourInDay: (weekDay: string, hourIndex: number) => {},
  onChangeInitialHour: (
    value: moment.Moment,
    weekDay: string,
    hourIndex: number
  ) => {},
  onChangeFinalHour: (
    value: moment.Moment,
    weekDay: string,
    hourIndex: number,
    hourLength: number
  ) => {},
  onChangeWeekToSemiPublic: (value: boolean) => {},
  onChangeInitialOnOptionalHour: (
    value: moment.Moment,
    weekDay: string,
    optionalHourIndex: number
  ) => {},
  onChangeFinalOnOptionalHour: (
    value: moment.Moment,
    weekDay: string,
    optionalHourIndex: number
  ) => {},
  onAddNewOptionalHourInDay: (weekDay: string, optionalHourIndex: number) => {},
  onDeleteOptionalHourInDay: (weekDay: string, optionalHourIndex: number) => {},
  onSaveBusinessHourPeriod: () => {},
});

export default function BusinessHour({
  loading,
  station,
  isSaved,
  setIsSaved,
  form,
  disabled,
  fieldName = "businessHoursPeriod",
  initialWeek = undefined,
}: BusinessHourProps) {
  const { freire } = useFreire();

  const initialWeekSchedule =
    station?.businessHoursPeriod?.week ?? initialWeek ?? initWeekSchedule();

  const timezones = Intl.supportedValuesOf("timeZone");

  const [timezone, setTimezone] = useState<string>(
    station?.businessHoursPeriod?.timezone ?? ""
  );

  const [week, setWeek] = useState<IWeek>(initialWeekSchedule);

  const [hourVariant, setHourVariant] = useState<string>(
    station?.businessHoursPeriod?.optional_hour_variant ?? ""
  );

  const [isSemiPublic, setIsSemiPublic] = useState<boolean>(
    station?.businessHoursPeriod?.optional_hour_variant === "PRIVATE"
  );

  const showStationBusinessHoursSettings = station ?? false;

  const onChangeTimezone = (value: string) => {
    setIsSaved(false);
    setTimezone(value);
  };

  const onChangeOpenDay = (day: string) => {
    setIsSaved(false);

    if (week[day].open) {
      setWeek((prevState) => {
        return {
          ...prevState,
          [day]: {
            ...prevState[day],
            open: false,
            hour: [{ initial: EMPTY_HOUR, final: EMPTY_HOUR }],
          },
        };
      });
    } else {
      setWeek((prevState) => {
        return {
          ...prevState,
          [day]: {
            ...prevState[day],
            open: true,
            hour: [
              { initial: FIRST_HOUR_OF_THE_DAY, final: DEFAULT_CLOSING_TIME },
            ],
          },
        };
      });
    }
  };

  const onCopyDayInWeek = (copyDay: IDay) => {
    setIsSaved(false);

    const copyWeek: IWeek = WEEK_DAYS.reduce((acc, day: string) => {
      acc[day] = week[day].open
        ? {
            ...week[day],
            hour: copyDay.hour,
            optional_hour: copyDay.optional_hour,
          }
        : {
            ...week[day],
          };
      return acc;
    }, {} as IWeek);
    setWeek(copyWeek);
  };

  const onChangeDayInTwentyFourHours = (value: boolean, weekDay: string) => {
    setIsSaved(false);

    if (value) {
      setWeek((prevState) => {
        return {
          ...prevState,
          [weekDay]: {
            ...prevState[weekDay],
            hour: [
              { initial: FIRST_HOUR_OF_THE_DAY, final: LAST_HOUR_OF_THE_DAY },
            ],
          },
        };
      });
    } else {
      setWeek((prevState) => {
        return {
          ...prevState,
          [weekDay]: {
            ...prevState[weekDay],
            hour: [
              { initial: FIRST_HOUR_OF_THE_DAY, final: DEFAULT_CLOSING_TIME },
            ],
          },
        };
      });
    }
  };

  const onNewHourInDay = (weekDay: string) => {
    const { hour } = week[weekDay];

    const isFinalHourDefaultClosingTime =
      hour[0].final === DEFAULT_CLOSING_TIME;

    setIsSaved(false);
    setWeek((prevState) => {
      return {
        ...prevState,
        [weekDay]: {
          ...prevState[weekDay],
          hour: [
            ...hour,
            {
              initial: hour[0].final,
              final: isFinalHourDefaultClosingTime
                ? LAST_HOUR_OF_THE_DAY
                : DEFAULT_CLOSING_TIME,
            },
          ],
        },
      };
    });
  };

  const onDeleteHourInDay = (weekDay: string, hourIndex: number) => {
    setIsSaved(false);

    const newHours = week[weekDay].hour.filter(
      (hour, index) => index !== hourIndex
    );
    setWeek((prevState) => {
      return {
        ...prevState,
        [weekDay]: {
          ...prevState[weekDay],
          hour: newHours,
        },
      };
    });
  };

  const onChangeInitialHour = (
    value: moment.Moment,
    weekDay: string,
    hourIndex: number
  ) => {
    const { hour } = week[weekDay];
    const newValue = value.format("HH:mm");
    const final = hour[0].final;
    const isIndexZero = hourIndex === 0;

    const valid = isIndexZero
      ? isValidPeriod(newValue, final)
      : isValidPeriod(final, newValue) &&
        isValidPeriod(newValue, hour[hourIndex].final);

    if (valid) {
      setIsSaved(false);

      const newHour = [
        ...hour.map((hour, index) => {
          if (index === hourIndex) {
            return { initial: value.format("HH:mm"), final: hour.final };
          }
          return hour;
        }),
      ];

      setWeek((prevState) => {
        return {
          ...prevState,
          [weekDay]: {
            ...prevState[weekDay],
            hour: newHour,
          },
        };
      });
    } else {
      showWarning(freire(texts.WARNING_VALID_HOUR));
    }
  };

  const onChangeFinalHour = (
    value: moment.Moment,
    weekDay: string,
    hourIndex: number,
    hourLength: number
  ) => {
    const { hour } = week[weekDay];
    const newValue = value.format("HH:mm");
    const isLengthTwo = hourLength === 2;
    const isIndexZero = hourIndex === 0;
    const firstInitial = hour[0].initial;
    const secondInitial = isLengthTwo ? hour[1].initial : "";

    const valid = !isLengthTwo
      ? isValidPeriod(firstInitial, newValue)
      : isIndexZero
      ? isValidPeriod(firstInitial, newValue) &&
        isValidPeriod(newValue, secondInitial)
      : isValidPeriod(secondInitial, newValue);

    if (valid) {
      setIsSaved(false);

      const newHour = [
        ...hour.map((hour, index) => {
          if (index === hourIndex) {
            return { initial: hour.initial, final: value.format("HH:mm") };
          }
          return hour;
        }),
      ];

      setWeek((prevState) => {
        return {
          ...prevState,
          [weekDay]: {
            ...prevState[weekDay],
            hour: newHour,
          },
        };
      });
    } else {
      showWarning(freire(texts.WARNING_VALID_HOUR));
    }
  };

  const onChangeWeekToSemiPublic = (value: boolean) => {
    setIsSaved(false);

    if (value) {
      setIsSemiPublic(true);

      const semiPublicWeek: IWeek = WEEK_DAYS.reduce((acc, day) => {
        const hourLength = week[day].hour.length;
        const hourClose = week[day].hour[hourLength - 1].final;
        const hourOpen = week[day].hour[0].initial;

        const hourOpenIsInvalid =
          hourOpen === FIRST_HOUR_OF_THE_DAY || hourOpen === "";
        const hourCloseIsInvalid =
          hourClose === LAST_HOUR_OF_THE_DAY || hourClose === "";
        const hourCloseIsLargerThanTwentyTwo =
          hourClose === DEFAULT_CLOSING_TIME ||
          isValidPeriod(DEFAULT_CLOSING_TIME, hourClose);

        const INDEX_ZERO_INITIAL_OPTIONAL_HOUR = hourOpenIsInvalid
          ? ""
          : FIRST_HOUR_OF_THE_DAY;
        const INDEX_ZERO_FINAL_OPTIONAL_HOUR = hourOpenIsInvalid
          ? ""
          : hourOpen;
        const INDEX_ONE_INITIAL_OPTIONAL_HOUR = hourCloseIsInvalid
          ? ""
          : hourCloseIsLargerThanTwentyTwo
          ? hourClose
          : addTwoHours(hourClose);
        const INDEX_ONE_FINAL_OPTIONAL_HOUR = hourCloseIsInvalid
          ? ""
          : LAST_HOUR_OF_THE_DAY;

        acc[day] = {
          ...week[day],
          optional_hour: [
            {
              initial: INDEX_ZERO_INITIAL_OPTIONAL_HOUR,
              final: INDEX_ZERO_FINAL_OPTIONAL_HOUR,
            },
            {
              initial: INDEX_ONE_INITIAL_OPTIONAL_HOUR,
              final: INDEX_ONE_FINAL_OPTIONAL_HOUR,
            },
          ],
        };

        return acc;
      }, {} as IWeek);

      setWeek(semiPublicWeek);
      setHourVariant("PRIVATE");
    } else {
      setIsSemiPublic(false);

      const normalWeek: IWeek = WEEK_DAYS.reduce((acc, day) => {
        acc[day] = week[day].open
          ? {
              ...week[day],
              optional_hour: null,
            }
          : { ...week[day] };

        return acc;
      }, {} as IWeek);

      setWeek(normalWeek);
      setHourVariant("");
    }
  };

  const onChangeInitialOnOptionalHour = (
    value: moment.Moment,
    weekDay: string,
    optionalHourIndex: number
  ) => {
    const { hour } = week[weekDay];
    const { optional_hour } = week[weekDay];
    const newValue = value.format("HH:mm");
    const closeFinalHour = hour[hour.length - 1].final;
    const firstInitalHour = hour[0].initial;
    const isIndexZero = optionalHourIndex === 0;

    const valid = isIndexZero
      ? isValidPeriod(newValue, firstInitalHour)
      : isValidPeriod(closeFinalHour, newValue);

    if (valid) {
      setIsSaved(false);

      const newOptionalHour = [
        ...optional_hour!.map((hour, index) => {
          if (index === optionalHourIndex) {
            return { initial: value.format("HH:mm"), final: hour.final };
          }
          return hour;
        }),
      ];

      setWeek((prevState) => {
        return {
          ...prevState,
          [weekDay]: {
            ...prevState[weekDay],
            optional_hour: newOptionalHour,
          },
        };
      });
    } else {
      showWarning(freire(texts.WARNING_VALID_HOUR));
    }
  };

  const onChangeFinalOnOptionalHour = (
    value: moment.Moment,
    weekDay: string,
    optionalHourIndex: number
  ) => {
    const { hour } = week[weekDay];
    const { optional_hour } = week[weekDay];
    const newValue = value.format("HH:mm");
    const firstInitialHour = hour[0].initial;
    const firstOptionalInitalHour = optional_hour![0].initial;
    const optionalInitialHour = optional_hour![optionalHourIndex].initial;
    const isIndexZero = optionalHourIndex === 0;

    const valid = isIndexZero
      ? isValidPeriod(newValue, firstInitialHour) &&
        isValidPeriod(firstOptionalInitalHour, newValue)
      : isValidPeriod(optionalInitialHour, newValue);

    if (valid) {
      setIsSaved(false);

      const newOptionalHour = [
        ...optional_hour!.map((hour, index) => {
          if (index === optionalHourIndex) {
            return { initial: hour.initial, final: value.format("HH:mm") };
          }
          return hour;
        }),
      ];

      setWeek((prevState) => {
        return {
          ...prevState,
          [weekDay]: {
            ...prevState[weekDay],
            optional_hour: newOptionalHour,
          },
        };
      });
    } else {
      showWarning(freire(texts.WARNING_VALID_HOUR));
    }
  };

  const onAddNewOptionalHourInDay = (
    weekDay: string,
    optionalHourIndex: number
  ) => {
    setIsSaved(false);

    const { optional_hour } = week[weekDay];
    const hourLength = week[weekDay].hour.length;
    const hourClose = week[weekDay].hour[hourLength - 1].final;
    const hourOpen = week[weekDay].hour[0].initial;

    const INITIAL_OPTIONAL_HOUR = addTwoHours(hourClose);

    const newOptionalHour =
      optionalHourIndex === 0
        ? { initial: FIRST_HOUR_OF_THE_DAY, final: hourOpen }
        : { initial: INITIAL_OPTIONAL_HOUR, final: LAST_HOUR_OF_THE_DAY };

    const newOptionalHourArray =
      optionalHourIndex === 0
        ? [newOptionalHour, optional_hour![1]]
        : [optional_hour![0], newOptionalHour];

    setWeek((prevState) => {
      return {
        ...prevState,
        [weekDay]: {
          ...prevState[weekDay],
          optional_hour: newOptionalHourArray,
        },
      };
    });
  };

  const onDeleteOptionalHourInDay = (
    weekDay: string,
    optionalHourIndex: number
  ) => {
    setIsSaved(false);
    const { optional_hour } = week[weekDay];

    const newOptionalHour = optional_hour?.map((hour, index) => {
      if (index === optionalHourIndex) {
        return { initial: "", final: "" };
      }
      return hour;
    });

    setWeek((prevState) => {
      return {
        ...prevState,
        [weekDay]: {
          ...prevState[weekDay],
          optional_hour: newOptionalHour,
        },
      };
    });
  };

  const onSaveBusinessHourPeriod = () => {
    const businesshoursPeriod =
      hourVariant !== ""
        ? {
            week: week,
            timezone: timezone,
            optional_hour_variant: hourVariant,
          }
        : {
            week: week,
            timezone: timezone,
          };

    form.setFieldsValue({
      [fieldName]: businesshoursPeriod,
    });
    setIsSaved(true);
    notification.success({
      message: freire(texts.ALL_RIGHT),
      description: freire(texts.BUSINESS_HOUR_EDITED),
    });
  };

  return (
    <BusinessHourContext.Provider
      value={{
        disabled,
        loading,
        isSemiPublic,
        onChangeTimezone,
        onChangeOpenDay,
        onCopyDayInWeek,
        onChangeDayInTwentyFourHours,
        onNewHourInDay,
        onDeleteHourInDay,
        onChangeInitialHour,
        onChangeFinalHour,
        onChangeWeekToSemiPublic,
        onChangeInitialOnOptionalHour,
        onChangeFinalOnOptionalHour,
        onAddNewOptionalHourInDay,
        onDeleteOptionalHourInDay,
        onSaveBusinessHourPeriod,
      }}
    >
      {showStationBusinessHoursSettings ? (
        <div className="businessHourContainer">
          <span className="titleBusinessHour">
            {freire(texts.BUSINESS_HOURS_TITLE)}
          </span>
          <Timezone timezones={timezones} timezone={timezone} />
          <Options />
          <Week week={week} />
          <Footer isSaved={isSaved} />
        </div>
      ) : (
        <>
          <Week week={week} />
          <Footer isSaved={isSaved} />
        </>
      )}
    </BusinessHourContext.Provider>
  );
}

// Declaração de Funções
const initWeekSchedule = () => {
  const initialWeek: IWeek = WEEK_DAYS.reduce((acc, day: string) => {
    acc[day] = {
      open: false,
      hour: [{ initial: "", final: "" }],
      optional_hour: null,
    };
    return acc;
  }, {} as IWeek);

  return initialWeek;
};

export const is24Hours = (weekday: IDay) => {
  return (
    weekday.hour[0].initial === FIRST_HOUR_OF_THE_DAY &&
    weekday.hour[0].final === LAST_HOUR_OF_THE_DAY
  );
};

const isValidPeriod = (initial: string, final: string) => {
  const momentInitial = moment(`2000-01-01T${initial}:00.000Z`);
  const momentFinal = moment(`2000-01-01T${final}:00.000Z`);

  return momentInitial.isBefore(momentFinal);
};

const showWarning = (description: string) => {
  notification.warning({
    message: "Ops...",
    description: description,
  });
};

const addTwoHours = (time: string) => {
  const momentTime = moment(`2000-01-01T${time}:00.000Z`).utc();

  const newHour = momentTime.add(2, "h");

  return newHour.format("HH:mm");
};
