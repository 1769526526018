import React, { useState } from "react";
import axios from "axios";
import { Button, Form, Col, Row, Icon, Steps, notification, Alert } from "antd";
import { withRouter } from "react-router-dom";
import api from "../../../services/api";
import { getCompanyCurrentUser } from "../../../services/auth";
import {
  COUPON_CODE_TYPE,
  COUPON_TYPE,
  COUPON_VALUE_TYPE,
  COUPONS_USAGE_AMOUNT_RULES,
} from "../../../services/utils";
import { useFreire } from "../../../utils/freireContext";
import * as texts from "../locales";
import { useOnMount } from "../../../hooks";
import {
  formatAvailableCompanies,
  formatDuration,
  formatUsageLimitPerPeriod,
} from "../utils";
import "./index.css";
import CampaignInfo from "./Forms/CampaignInfo";
import ValidPlaces from "./Forms/ValidPlaces";
import Notification from "./Forms/Notification";
import { NewCampaignContext } from "./context";
import CampaignQuota from "./Forms/CampaignQuota";
import CouponCampaignRules from "./Forms/CouponCampaignRules";
import EligibleUsers, {
  ELIGIBLE_USERS_TO_USE_COUPON_TYPE,
  autoLinkCouponType,
} from "./Forms/EligibleUsers";
import * as XLSX from "xlsx";
import { wordsToExcludeFromCouponSheet } from "../utils";

const { Step } = Steps;

const { PERCENTAGE, FIXED_VALUE, CREDIT } = COUPON_TYPE;

export const COUPON_LINK_RULES = {
  ONLY_USERS_WITHOUT_CHARGE: "onlyUsersWithoutCharge",
  ALL_USERS: "allUsers",
};

const NewCoupons = ({ form, history }) => {
  const { freire } = useFreire();
  const { state } = history.location;

  const setFormFieldIniitalValue = () => {
    const fieldsToFillIn = [
      "benefit.value",
      "benefit.type",
      "campaignPartners",
      "company",
      "couponDuration.enabled",
      "couponDuration.value",
      "couponDuration.unit",
      "message",
      "quota.enabled",
      "quota.type",
      "quota.totalBalance",
      "quota.accumulateToNextPeriod",
      "quota.couponsExpireOnTotalUsage",
      "theme.enabled",
      "theme.alias",
      "usageLimitPerPeriod",
      "usageAmountRule",
      "validPlace",
      "amountOfCoupons",
      "title",
      "codeType",
      "cod",
    ];

    fieldsToFillIn.forEach((field) => {
      const value = field
        .split(".")
        .reduce(
          (o, i) => (o && o[i] !== undefined ? o[i] : undefined),
          state.campaign
        );
      form.getFieldDecorator(field, { initialValue: value ?? undefined });
    });
  };

  if (state?.campaign) setFormFieldIniitalValue();

  const [loadingSave, setLoadingSave] = useState(false);
  const [userCompany, setUserCompany] = useState("");
  const [allCompanies, setAllCompanies] = useState([]);
  const [allStations, setAllStations] = useState([]);

  const getAllCompanies = async () => {
    const { data } = await api.get("company");

    setAllCompanies(data);
  };

  const getAllStations = async () => {
    const { data } = await api.get(`select/connected_stations`);

    setAllStations(data);
  };

  const getUserCompany = async () => {
    const company = await getCompanyCurrentUser();

    setUserCompany(company);
  };

  useOnMount(() => {
    getUserCompany();
    getAllCompanies();
    getAllStations();
  });

  const validateAndTransformParams = async () => {
    return new Promise((resolve, reject) => {
      form.validateFieldsAndScroll(async (error, params) => {
        if (error) {
          reject(error);
          return;
        }

        if (params.benefit.type === PERCENTAGE) {
          params.benefit.value = (
            parseFloat(params.benefit.value.replace(/[,]/g, ".")) / 100
          ).toFixed(4);
        } else if (params.benefit.type === FIXED_VALUE) {
          params.benefit.value = parseInt(
            params.benefit.value.replace(/[.,]/g, "")
          );
        } else if (params.benefit.type === CREDIT) {
          let totalBalance = parseInt(
            params.benefit.value.replace(/[.,]/g, ""),
            10
          );

          if (params.benefit.valueType === COUPON_VALUE_TYPE.KWH) {
            totalBalance = totalBalance / 100;
          }

          params.codeType = COUPON_CODE_TYPE.DIFFERENT;
          params.totalBalance = totalBalance;
          params.benefit.value = totalBalance;
        }

        params.expireDate = params.expireDate.toISOString();

        if (params.usageAmountRule === COUPONS_USAGE_AMOUNT_RULES.UNLIMITED) {
          params.usageLimitPerCoupon = -1;
        }

        if (
          params.usageAmountRule === COUPONS_USAGE_AMOUNT_RULES.FIRST_CHARGE
        ) {
          params.usageLimitPerCoupon = -1;
          params.isFirstChargeCoupon = true;
        }

        if (params.onlyUsersWithoutCharge) {
          params.couponLinkRule = COUPON_LINK_RULES.ONLY_USERS_WITHOUT_CHARGE;
        }

        if (!params.company) {
          params.company = userCompany.id;
        }

        if (!params.usageLimitPerPeriod?.enabled) {
          params.usageLimitPerPeriod = undefined;
        } else {
          params.usageLimitPerPeriod = formatUsageLimitPerPeriod(
            params.usageLimitPerPeriod
          );
        }

        if (!params.quota?.enabled) {
          params.quota = undefined;
        } else {
          delete params.quota.enabled;
          const formattedTotalBalance = parseInt(
            params.quota.totalBalance.replace(/[.,]/g, "")
          );

          params.quota.totalBalance = formattedTotalBalance;
          params.quota.accumulateToNextPeriod =
            params.quota?.accumulateToNextPeriod || false;
          params.quota.couponsExpireOnTotalUsage =
            params.quota?.couponsExpireOnTotalUsage || false;
        }

        if (
          params.eligibleUsersType ===
          ELIGIBLE_USERS_TO_USE_COUPON_TYPE.ELIGIBLE_USERS
        ) {
          params.codeType = COUPON_CODE_TYPE.AUTOLINKING;
        }

        if (params.hasPercentageBalanceLimitPerCharge) {
          params.percentageBalanceLimitPerCharge = Number(
            params.percentageBalanceLimitPerCharge
          );
        }

        delete params.eligibleUsersType;
        delete params.hasPercentageBalanceLimitPerCharge;
        delete params.usageAmountRule;

        resolve(params);
      });
    });
  };

  const createCampaignCoupon = async () => {
    try {
      const params = await validateAndTransformParams();

      if (!params) return;

      const availableCompanies = formatAvailableCompanies(params, userCompany);

      if (!availableCompanies) {
        notification.warning({
          message: freire(texts.ATTENTION),
          description: freire(texts.SPECIFIC_STATIONS_ERROR_MESSAGE),
        });
        return;
      }

      params.availableCompanies = availableCompanies;

      params.couponDuration = formatDuration(params.couponDuration);

      params.renewalTime = formatDuration(params.renewalTime);

      params.accumulateOnRenewal = params.accumulateOnRenewal || false;

      if (params.couponsSheet) {
        const file = params.couponsSheet.fileList[0].originFileObj;

        const data = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsBinaryString(file);
          reader.onload = (e) => resolve(e.target.result);
          reader.onerror = (e) => reject(e.target.error);
        });

        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const coupons = [
          ...new Set(
            sheetData
              .map((row) => {
                if (params.autoLinkCouponType === autoLinkCouponType.CHASSI) {
                  return String(row[0])
                    .toUpperCase()
                    .replace(/[^a-zA-Z0-9]/g, "")
                    .trim();
                } else {
                  return String(row[0]).toUpperCase().trim();
                }
              })
              .filter((cod) => !cod.match(new RegExp("undefined", "i")))
              .filter((cod) => {
                if (params.autoLinkCouponType === autoLinkCouponType.CHASSI) {
                  return !new RegExp(
                    wordsToExcludeFromCouponSheet.join("|"),
                    "gi"
                  ).test(cod);
                }

                if (params.autoLinkCouponType === autoLinkCouponType.EMAIL) {
                  return new RegExp("@", "gi").test(cod);
                }

                return true;
              })
          ),
        ];

        params.autoLinkedCoupons = coupons;
        params.amountOfCoupons = coupons.length;
        delete params.couponsSheet;
      }

      setLoadingSave(true);

      await api.post("campaigns", params);

      notification.success({
        message: freire(texts.ALL_RIGHT),
        description: freire(texts.CREATED_CAMPAIGN_SUCCESSFULLY),
      });

      setLoadingSave(false);

      history.push("/dashboard/coupons");
    } catch (error) {
      if (!axios.isAxiosError(error)) return;

      setLoadingSave(false);

      notification.error({
        message: freire(texts.WENT_WRONG),
        description: error.response?.data?.message || error.message,
      });
    }
  };

  const { company: companyId, benefit } = form.getFieldsValue();

  const selectedCompany = allCompanies.find(
    (company) => company._id === companyId
  );

  return (
    <NewCampaignContext.Provider
      value={{
        form,
        userCompany,
        allCompanies,
        allStations,
        selectedCompany,
      }}
    >
      <div className="container">
        <div className="content" style={{ height: "auto" }}>
          <Row>
            <Col span={6}>
              <Steps current={1} size="small" direction="vertical">
                <Step
                  icon={<Icon type="profile" />}
                  title={freire(texts.NEW_CAMPAIGN)}
                  description={freire(texts.NEW_CAMPAIGN_DESCRIPTION)}
                />
              </Steps>
            </Col>

            <Col span={18} style={{ maxWidth: 1000 }}>
              <Form wrapperCol={{ span: 24 }}>
                <CampaignInfo />

                <EligibleUsers />

                <CouponCampaignRules />

                <CampaignQuota showDivider={true} />

                <span style={{ color: "#fd5876", fontWeight: "bold" }}>
                  {freire(texts.VALID_PLACES)}
                </span>
                <p style={{ color: "#747277" }}>
                  {freire(texts.VALID_PLACES_DESCRIPTION)}
                </p>

                {benefit?.valueType === COUPON_VALUE_TYPE.KWH && (
                  <Alert
                    description={
                      <span style={{ whiteSpace: "pre-line" }}>
                        {freire(texts.COUPON_CREDIT_KWH_LOCATION_ALERT)}
                      </span>
                    }
                    type="warning"
                    showIcon
                    style={{ marginBottom: 25 }}
                  />
                )}

                <ValidPlaces />

                <Notification showDivider={false} />
              </Form>

              <div className="footer">
                <Button
                  style={{ marginRight: 20 }}
                  onClick={() => history.push("/dashboard/coupons")}
                  type="default"
                >
                  {freire(texts.CANCEL)}
                </Button>
                <Button
                  loading={loadingSave}
                  onClick={createCampaignCoupon}
                  type="primary"
                >
                  {freire(texts.SAVE)}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </NewCampaignContext.Provider>
  );
};

const PageNewCoupons = Form.create({ name: "inputs" })(NewCoupons);
export default withRouter(PageNewCoupons);
