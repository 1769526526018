import React from "react";

export const Ticket2 = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.75 18.75C29.75 16.68 31.43 15 33.5 15V13.5C33.5 7.5 32 6 26 6H11C5 6 3.5 7.5 3.5 13.5V14.25C5.57 14.25 7.25 15.93 7.25 18C7.25 20.07 5.57 21.75 3.5 21.75V22.5C3.5 28.5 5 30 11 30H26C32 30 33.5 28.5 33.5 22.5C31.43 22.5 29.75 20.82 29.75 18.75Z"
      stroke="currentColor"
      strokeWidth="2.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 6L15.5 30"
      stroke="currentColor"
      strokeWidth="2.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="7.5 7.5"
    />
  </svg>
);
