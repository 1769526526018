import React, { useEffect, useMemo, useState } from "react";
import { Button, Dropdown, Icon, Menu, notification, Switch } from "antd";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import PDFDocument from "@react-pdf/pdfkit";
import SVGtoPDF from "svg-to-pdfkit";
import BlobStream from "blob-stream";
import StickerModal from "../StickerModal";
import { getCompanyCurrentUser } from "../../services/auth";
import {
  MELI_MOOCA,
  MOBILIZE,
  NEO,
  NEOCHARGE,
  SHELL,
  BYD,
  BYD_TEST,
  BRS,
  VOLTZ,
  ION,
  BRAND,
  ANDRADESALES,
  CHARGERPRO,
  ELESOL,
  PLUGBRA,
  EVELETROPOSTO,
  CIACHARGE,
  LINKPLUSENERGY,
} from "../../config";
import "./styles.css";
import { useFreire } from "../../utils/freireContext";
import * as texts from "./locales";
import {
  QRCODE_MOBILIZE,
  QRCODE_NEO,
  QRCODE_SHELL,
  QRCODE_TUPI,
  QRCODE_BYD,
  QRCODE_BRS,
  QRCODE_VOLTZ,
  QRCODE_ION,
  QRCODE_ANDRADESALES,
  QRCODE_CHARGERPRO,
  QRCODE_ELESOL,
  QRCODE_PLUGBRA,
  QRCODE_EVELETROPOSTO,
  QRCODE_CIACHARGE,
  QRCODE_LINKPLUSENERGY,
} from "./configs";

const ButtonGroup = Button.Group;

function QrCode({ station = {}, size = 424 }) {
  const [loading, setLoading] = useState(true);
  const [hasLogo, setHasLogo] = useState(true);
  const [companyID, setCompanyID] = useState("");
  const [parentCompanyID, setParentCompanyID] = useState("");
  const [visibleSticker, setVisibleSticker] = useState(false);
  const [fileNameSticker, setFileNameSticker] = useState("");
  const [qrcodeSticker, setQrcodeSticker] = useState("");
  const { freire } = useFreire();

  async function getCompanyID() {
    try {
      const result = await getCompanyCurrentUser();
      if (result) {
        setCompanyID(result.id);
        setParentCompanyID(result.parentCompanyID);
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getCompanyID();
  }, []);

  const downloadPNG = () => {
    var container = document.querySelector(".qrcode_capture");
    var svg = container.getElementsByTagName("svg")[0];
    var image = container.getElementsByTagName("image")[0];
    svg.removeAttribute("xmlns");
    image.removeAttribute("xmlns:xlink");

    html2canvas(container).then((canvas) => {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${station.name} (${station.stationID}) - QrCode.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  };

  const downloadSVG = () => {
    var container = document.querySelector(".qrcode_capture");
    var svg = container.getElementsByTagName("svg")[0];
    // svg.style.padding = 12;

    var image = container.getElementsByTagName("image")[0];
    svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    image.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");

    var file = new File(
      [container.innerHTML],
      `${station.name} (${station.stationID}) - QrCode.svg`,
      {
        type: "image/svg+xml",
      }
    );

    // svg.style.padding = 0;
    saveAs(file);
  };

  const downloadPDF = () => {
    try {
      const sticker = document.getElementById("sticker");
      const stickerSvg = sticker
        .getSVGDocument()
        .getElementsByTagName("svg")[0];

      const viewBox = stickerSvg.getAttribute("viewBox").split(" ");
      const withBorder = stickerSvg.getAttribute("type") === "border";
      const width = Number(viewBox[2]);
      const height = Number(viewBox[3]);

      const doc = new PDFDocument({
        size: [width + 40, height + 40],
      });

      const space = withBorder ? 20 : 0;
      SVGtoPDF(doc, stickerSvg.outerHTML, space, space, {
        assumePt: true,
      });

      const stream = doc.pipe(BlobStream());

      stream.on("finish", () => {
        let blob = stream.toBlob("application/pdf");
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${station.name} (${station.stationID}) - ${fileNameSticker}.pdf`;
        link.click();
      });

      doc.end();
    } catch (error) {
      console.log("ERROR downloadStickerPDF ->", error.message);
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_SAVE_STICKER),
      });
    }
  };

  const getQrCodeSvg = () => {
    var container = document.querySelector(".qrcode_capture").cloneNode(true);

    var qrcode = container.getElementsByTagName("svg")[0];

    var logo = container.getElementsByTagName("image")[0];
    qrcode.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    logo.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");

    return qrcode;
  };

  const showStickerModal = (fileNameSticker) => {
    let qrcode = getQrCodeSvg();
    setQrcodeSticker(qrcode);

    setFileNameSticker(fileNameSticker);
    setVisibleSticker(true);
  };

  // const removeSwitchTag = (stickerSvg) => {
  //   var sticker = stickerSvg.cloneNode(true);
  //   var switchTag = sticker.getElementsByTagName("switch")[0];
  //   var parent = switchTag.parentNode;
  //   while (switchTag.firstChild) {
  //     parent.insertBefore(switchTag.firstChild, switchTag);
  //   }
  //   parent.removeChild(switchTag);

  //   return sticker;
  // };

  const getQrCodeLogoInfo = () => {
    if (
      [NEO, NEOCHARGE, MELI_MOOCA].includes(companyID) ||
      [NEO, NEOCHARGE, MELI_MOOCA].includes(parentCompanyID)
    ) {
      return QRCODE_NEO;
    } else if (companyID === MOBILIZE || parentCompanyID === MOBILIZE) {
      return QRCODE_MOBILIZE;
    } else if (companyID === SHELL || parentCompanyID === SHELL) {
      return QRCODE_SHELL;
    } else if (
      [BYD, BYD_TEST].includes(companyID) ||
      [BYD, BYD_TEST].includes(parentCompanyID)
    ) {
      return QRCODE_BYD;
    } else if (companyID === BRS || parentCompanyID === BRS) {
      return QRCODE_BRS;
    } else if (companyID === VOLTZ || parentCompanyID === VOLTZ) {
      return QRCODE_VOLTZ;
    } else if (companyID === ION || parentCompanyID === ION) {
      return QRCODE_ION;
    } else if (companyID === ANDRADESALES || parentCompanyID === ANDRADESALES) {
      return QRCODE_ANDRADESALES;
    } else if (companyID === CHARGERPRO || parentCompanyID === CHARGERPRO) {
      return QRCODE_CHARGERPRO;
    } else if (companyID === ELESOL || parentCompanyID === ELESOL) {
      return QRCODE_ELESOL;
    } else if (companyID === PLUGBRA || parentCompanyID === PLUGBRA) {
      return QRCODE_PLUGBRA;
    } else if (
      companyID === EVELETROPOSTO ||
      parentCompanyID === EVELETROPOSTO
    ) {
      return QRCODE_EVELETROPOSTO;
    } else if (companyID === CIACHARGE || parentCompanyID === CIACHARGE) {
      return QRCODE_CIACHARGE;
    } else if (
      companyID === LINKPLUSENERGY ||
      parentCompanyID === LINKPLUSENERGY
    ) {
      return QRCODE_LINKPLUSENERGY;
    }

    return QRCODE_TUPI;
  };

  const { logo, sizeLogo, urlPrefix } = getQrCodeLogoInfo();

  const isTupi = companyID === BRAND;
  const isIon = companyID === ION || parentCompanyID === ION;
  const isVoltz = companyID === VOLTZ || parentCompanyID === VOLTZ;
  const isAndradeSales =
    companyID === ANDRADESALES || parentCompanyID === ANDRADESALES;
  const isChargerPro =
    companyID === CHARGERPRO || parentCompanyID === CHARGERPRO;
  const isElesol = companyID === ELESOL || parentCompanyID === ELESOL;
  const isPlugBra = companyID === PLUGBRA || parentCompanyID === PLUGBRA;
  const isEvEletroposto =
    companyID === EVELETROPOSTO || parentCompanyID === EVELETROPOSTO;
  const isCiacharge = companyID === CIACHARGE || parentCompanyID === CIACHARGE;
  const isLinkPlusEnergy =
    companyID === LINKPLUSENERGY || parentCompanyID === LINKPLUSENERGY;

  const menuStickers = useMemo(
    () => [
      {
        title: "Tupi App",
        items: [
          {
            label: "Simples",
            fileName: "sticker_tupi_app",
          },
          {
            label: "Completo",
            fileName: "sticker_tupi_app_full",
          },
        ],
      },
      ...(isTupi || isIon
        ? [
            {
              title: "Ion App",
              items: [
                {
                  label: "Simples",
                  fileName: "sticker_simple_ion",
                },
                {
                  label: "Completo",
                  fileName: "sticker_full_ion",
                },
              ],
            },
          ]
        : []),
      ...(isTupi || isVoltz
        ? [
            {
              title: "Voltz App",
              items: [
                {
                  label: "Simples",
                  fileName: "sticker_simple_voltz",
                },
                {
                  label: "Completo",
                  fileName: "sticker_full_voltz",
                },
              ],
            },
          ]
        : []),
      ...(isTupi || isAndradeSales
        ? [
            {
              title: "Andrade & Sales App",
              items: [
                {
                  label: "Simples",
                  fileName: "sticker_simple_andradesales",
                },
                {
                  label: "Completo",
                  fileName: "sticker_full_andradesales",
                },
              ],
            },
          ]
        : []),
      ...(isTupi || isElesol
        ? [
            {
              title: "Elesol App",
              items: [
                {
                  label: "Simples",
                  fileName: "sticker_simple_elesol",
                },
                {
                  label: "Completo",
                  fileName: "sticker_full_elesol",
                },
              ],
            },
          ]
        : []),
      ...(isTupi || isChargerPro
        ? [
            {
              title: "Charger Pro App",
              items: [
                {
                  label: "Simples",
                  fileName: "sticker_simple_chargerpro",
                },
                {
                  label: "Completo",
                  fileName: "sticker_full_chargerpro",
                },
              ],
            },
          ]
        : []),
      ...(isTupi || isPlugBra
        ? [
            {
              title: "PlugBra App",
              items: [
                {
                  label: "Simples",
                  fileName: "sticker_simple_plugbra",
                },
                {
                  label: "Completo",
                  fileName: "sticker_full_plugbra",
                },
              ],
            },
          ]
        : []),
      ...(isTupi || isEvEletroposto
        ? [
            {
              title: "EV Eletroposto App",
              items: [
                {
                  label: "Simples",
                  fileName: "sticker_simple_eveletroposto",
                },
                {
                  label: "Completo",
                  fileName: "sticker_full_eveletroposto",
                },
              ],
            },
          ]
        : []),
      ...(isTupi || isCiacharge
        ? [
            {
              title: "Cia Charge App",
              items: [
                {
                  label: "Simples",
                  fileName: "sticker_simple_ciacharge",
                },
                {
                  label: "Completo",
                  fileName: "sticker_full_ciacharge",
                },
              ],
            },
          ]
        : []),
      ...(isTupi || isLinkPlusEnergy
        ? [
            {
              title: "Link+ Energy App",
              items: [
                {
                  label: "Simples",
                  fileName: "sticker_simple_linkplusenergy",
                },
                {
                  label: "Completo",
                  fileName: "sticker_full_linkplusenergy",
                },
              ],
            },
          ]
        : []),
    ],
    [
      isTupi,
      isIon,
      isVoltz,
      isAndradeSales,
      isChargerPro,
      isElesol,
      isPlugBra,
      isEvEletroposto,
      isCiacharge,
      isLinkPlusEnergy,
    ]
  );

  return (
    <div className="containerQrCode">
      <StickerModal
        visible={visibleSticker}
        fileNameSticker={fileNameSticker}
        station={station}
        qrcode={qrcodeSticker}
        onClose={() => setVisibleSticker(false)}
        onDownload={() => downloadPDF()}
      />

      <div style={{ marginBottom: 25 }}>
        <Switch
          disabled={loading}
          checkedChildren={<Icon type="check" />}
          unCheckedChildren={<Icon type="close" />}
          defaultChecked={hasLogo}
          onChange={(checked) => {
            setHasLogo(checked);
          }}
        />
        <span className="textSwitch">{freire(texts.LOGO)}</span>
      </div>
      <div className="qrcode_capture">
        {loading && <div className="my-skeleton" />}

        <QRCode
          size={size}
          level={"H"}
          renderAs="svg"
          value={`${urlPrefix}/${station.stationID}`}
          imageSettings={{
            src: hasLogo ? logo : "",
            excavate: hasLogo,
            ...sizeLogo,
          }}
        />
      </div>

      <div style={{ marginTop: 25, alignItems: "center" }}>
        <span
          style={{
            fontSize: 16,
            fontWeight: 500,
            color: "#1890ff",
            marginRight: 15,
          }}
        >
          {freire(texts.DOWNLOAD)}:
        </span>
        <ButtonGroup>
          <Button disabled={loading} onClick={downloadPNG} type="primary">
            PNG
          </Button>
          <Button disabled={loading} onClick={downloadSVG} type="primary">
            SVG
          </Button>
          <Dropdown
            disabled={loading}
            overlay={
              <Menu>
                {menuStickers.map((item) => (
                  <Menu.ItemGroup title={item.title} key={item.title}>
                    {item.items.map((subItem) => (
                      <Menu.Item
                        onClick={() => showStickerModal(subItem.fileName)}
                        key={subItem.fileName}
                      >
                        {subItem.label}
                      </Menu.Item>
                    ))}
                  </Menu.ItemGroup>
                ))}
              </Menu>
            }
            trigger={["click"]}
          >
            <Button type="primary">
              {freire(texts.STICKER)}
              <Icon type="down" />
            </Button>
          </Dropdown>
        </ButtonGroup>
      </div>
    </div>
  );
}

export default QrCode;
