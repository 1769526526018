import logoTupi from "../../assets/img/logo2.png";
import logoNeo from "../../assets/img/logo-neo2.png";
import logoShell from "../../assets/img/iconShell.png";
import logoMobilize from "../../assets/img/logo-mobilize2.png";
import logoBYD from "../../assets/img/logo-byd-qrcode.png";
import logoBrs from "../../assets/img/logo-brs-qrcode.png";
import logoVoltz from "../../assets/img/logo-voltz-collapsed.png";
import logoIon from "../../assets/img/logo-ion-collapsed.png";
import logoAndradesales from "../../assets/img/logo-andradesales-collapsed.png";
import logoChargerPro from "../../assets/img/logo-chargerpro-collapsed.png";
import logoElesol from "../../assets/img/logo-elesol-collapsed.png";
import logoPlugBra from "../../assets/img/logo-plugbra-collapsed.png";
import logoEvEletroposto from "../../assets/img/logo-eveletroposto-collapsed.png";
import logoCiaCharge from "../../assets/img/logo-ciacharge-collapsed.png";
import logoLinkPlusEnergy from "../../assets/img/logo-linkplusenergy-collapsed.png";

const QRCODE_URL_PREFIX = process.env.REACT_APP_QRCODE_API_URL;

export const QRCODE_TUPI = {
  logo: logoTupi,
  sizeLogo: {
    width: 92,
    height: 92,
  },
  urlPrefix: QRCODE_URL_PREFIX,
};
export const QRCODE_NEO = {
  logo: logoNeo,
  sizeLogo: {
    width: 76.8,
    height: 76.8,
  },
  urlPrefix: `${QRCODE_URL_PREFIX}/neocharge`,
};
export const QRCODE_MOBILIZE = {
  logo: logoMobilize,
  sizeLogo: {
    width: 76.8,
    height: 76.8,
  },
  urlPrefix: `${QRCODE_URL_PREFIX}/mobilize`,
};
export const QRCODE_SHELL = {
  logo: logoShell,
  sizeLogo: {
    width: 76,
    height: 76,
  },
  urlPrefix: `${QRCODE_URL_PREFIX}/shell`,
};
export const QRCODE_BYD = {
  logo: logoBYD,
  sizeLogo: {
    width: 92,
    height: 92,
  },
  urlPrefix: `${QRCODE_URL_PREFIX}/byd`,
};
export const QRCODE_BRS = {
  logo: logoBrs,
  sizeLogo: {
    width: 76,
    height: 76,
  },
  urlPrefix: `${QRCODE_URL_PREFIX}/brs`,
};
export const QRCODE_VOLTZ = {
  logo: logoVoltz,
  sizeLogo: {
    width: 76,
    height: 76,
  },
  urlPrefix: `${QRCODE_URL_PREFIX}/voltz`,
};
export const QRCODE_ION = {
  logo: logoIon,
  sizeLogo: {
    width: 76,
    height: 76,
  },
  urlPrefix: `${QRCODE_URL_PREFIX}/ion`,
};
export const QRCODE_ANDRADESALES = {
  logo: logoAndradesales,
  sizeLogo: {
    width: 76,
    height: 76,
  },
  urlPrefix: `${QRCODE_URL_PREFIX}/andradesales`,
};
export const QRCODE_CHARGERPRO = {
  logo: logoChargerPro,
  sizeLogo: {
    width: 76,
    height: 76,
  },
  urlPrefix: `${QRCODE_URL_PREFIX}/chargerpro`,
};
export const QRCODE_ELESOL = {
  logo: logoElesol,
  sizeLogo: {
    width: 76,
    height: 76,
  },
  urlPrefix: `${QRCODE_URL_PREFIX}/elesol`,
};
export const QRCODE_PLUGBRA = {
  logo: logoPlugBra,
  sizeLogo: {
    width: 76,
    height: 76,
  },
  urlPrefix: `${QRCODE_URL_PREFIX}/plugbra`,
};
export const QRCODE_EVELETROPOSTO = {
  logo: logoEvEletroposto,
  sizeLogo: {
    width: 76,
    height: 76,
  },
  urlPrefix: `${QRCODE_URL_PREFIX}/eveletroposto`,
};
export const QRCODE_CIACHARGE = {
  logo: logoCiaCharge,
  sizeLogo: {
    width: 76,
    height: 76,
  },
  urlPrefix: `${QRCODE_URL_PREFIX}/ciacharge`,
};
export const QRCODE_LINKPLUSENERGY = {
  logo: logoLinkPlusEnergy,
  sizeLogo: {
    width: 76,
    height: 76,
  },
  urlPrefix: `${QRCODE_URL_PREFIX}/linkplusenergy`,
};
