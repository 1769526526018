import React, { useState, useEffect } from "react";
import { Layout, Menu, Icon, Avatar, Dropdown, notification } from "antd";
import { BrowserRouter, Route, Link, useLocation } from "react-router-dom";
import "antd/dist/antd.less";
import "./index.css";
import "./index.scss";

import api from "../../services/api";
import firebase from "../../services/firebaseConfig";

import logoCollapsed from "../../assets/img/logo2.png";
import logoNotCollapsed from "../../assets/img/logo.png";
import logoNeoCollapsed from "../../assets/img/logo-neo2.png";
import logoNeoNotCollapsed from "../../assets/img/logo-neo.png";
import logoMobilizeCollapsed from "../../assets/img/logo-mobilize2.png";
import logoMobilizeNotCollapsed from "../../assets/img/logo-mobilize.png";
import logoShellCollapsed from "../../assets/img/logo-shell2.png";
import logoShellNotCollapsed from "../../assets/img/logo-shell.png";
import logoBydCollapsed from "../../assets/img/logo-byd2.png";
import logoBydNotCollapsed from "../../assets/img/logo-byd.png";
import logoBrsCollapsed from "../../assets/img/logo-br-supercarga2.png";
import logoBrsNotCollapsed from "../../assets/img/logo-br-supercarga.png";
import logoVoltzCollapsed from "../../assets/img/logo-voltz-collapsed.png";
import logoVoltzNotCollapsed from "../../assets/img/logo-voltz.png";
import logoIonCollapsed from "../../assets/img/logo-ion-collapsed.png";
import logoIonNotCollapsed from "../../assets/img/logo-ion.png";
import logoAndradesalesCollapsed from "../../assets/img/logo-andradesales-collapsed.png";
import logoAndradesalesNotCollapsed from "../../assets/img/logo-andradesales.png";
import logoChargerProCollapsed from "../../assets/img/logo-chargerpro-collapsed.png";
import logoChargerProNotCollapsed from "../../assets/img/logo-chargerpro.png";
import logoElesolCollapsed from "../../assets/img/logo-elesol-collapsed.png";
import logoElesolNotCollapsed from "../../assets/img/logo-elesol.png";
import logoPlugBraCollapsed from "../../assets/img/logo-plugbra-collapsed.png";
import logoPlugBraNotCollapsed from "../../assets/img/logo-plugbra.png";
import logoEveletroPostoCollapsed from "../../assets/img/logo-eveletroposto-collapsed.png";
import logoEveletroPostoNotCollapsed from "../../assets/img/logo-eveletroposto.png";
import logoCiaChargeCollapsed from "../../assets/img/logo-ciacharge-collapsed.png";
import logoCiaChargeNotCollapsed from "../../assets/img/logo-ciacharge.png";
import logoLinkPlusEnergyCollapsed from "../../assets/img/logo-linkplusenergy-collapsed.png";
import logoLinkPlusEnergyNotCollapsed from "../../assets/img/logo-linkplusenergy.png";

import {
  MELI_MOOCA,
  NEO,
  NEOCHARGE,
  MOBILIZE,
  SHELL,
  BYD,
  BYD_TEST,
  BRS,
  VOLTZ,
  ION,
  ANDRADESALES,
  CHARGERPRO,
  ELESOL,
  PLUGBRA,
  EVELETROPOSTO,
  CIACHARGE,
  LINKPLUSENERGY,
} from "../../config";
import { getCompanyCurrentUser, getUserPermissions } from "../../services/auth";
import { Show, useAccess } from "../../contexts/PermissionContext";
import ModalMakeAdmin from "./ModalMakeAdmin";

import MenuConfig from "./MenuConfig";
import RoutesDashboardConfig from "./RoutesDashboardConfig";
import { useFreire } from "../../utils/freireContext";
import * as texts from "./locales";
import { LANGUAGES } from "../../config/languages";
import { useOnUpdate } from "../../hooks";

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const menuList = {
  list_stations: "stations",
  connected_stations: "stations",
  audit_stations: "stations",
  batch_operation: "stations",
  visibility_group: "visibility",
  new_visibility_group: "visibility",
  admin_users: "admin",
  companys: "admin",
  recipients: "admin",
  plugs: "admin",
  powerbi: "admin",
  permission_groups: "admin",
  report_groups: "report",
  new_report_group: "report",
  charges_history: "history",
  payment_history: "history",
  availability_stations: "availability",
  availability_system: "availability",
  stations: "ocpp",
  transactions: "ocpp",
  tags: "ocpp",
  actions: "ocpp",
  alert_groups: "ocpp",
  logs: "ocpp",
  monitoring: "ocpp",
};

const COMPANY_CLASS_NAMES = {
  [NEO]: "neo",
  [NEOCHARGE]: "neo",
  [MELI_MOOCA]: "neo",
  [MOBILIZE]: "mobilize",
  [SHELL]: "shell",
  [BYD]: "byd",
  [BYD_TEST]: "byd",
  [BRS]: "brs",
  [VOLTZ]: "voltz",
  [ION]: "ion",
  [ANDRADESALES]: "andradesales",
  [CHARGERPRO]: "chargerpro",
  [ELESOL]: "elesol",
  [PLUGBRA]: "plugbra",
  [EVELETROPOSTO]: "eveletroposto",
  [CIACHARGE]: "ciacharge",
  [LINKPLUSENERGY]: "linkplusenergy",
};

const LOGOS = {
  neo: {
    collapsed: logoNeoCollapsed,
    notCollapsed: logoNeoNotCollapsed,
  },
  mobilize: {
    collapsed: logoMobilizeCollapsed,
    notCollapsed: logoMobilizeNotCollapsed,
  },
  shell: {
    collapsed: logoShellCollapsed,
    notCollapsed: logoShellNotCollapsed,
  },
  byd: {
    collapsed: logoBydCollapsed,
    notCollapsed: logoBydNotCollapsed,
  },
  brs: {
    collapsed: logoBrsCollapsed,
    notCollapsed: logoBrsNotCollapsed,
  },
  voltz: {
    collapsed: logoVoltzCollapsed,
    notCollapsed: logoVoltzNotCollapsed,
  },
  ion: {
    collapsed: logoIonCollapsed,
    notCollapsed: logoIonNotCollapsed,
  },
  andradesales: {
    collapsed: logoAndradesalesCollapsed,
    notCollapsed: logoAndradesalesNotCollapsed,
  },
  chargerpro: {
    collapsed: logoChargerProCollapsed,
    notCollapsed: logoChargerProNotCollapsed,
  },
  elesol: {
    collapsed: logoElesolCollapsed,
    notCollapsed: logoElesolNotCollapsed,
  },
  plugbra: {
    collapsed: logoPlugBraCollapsed,
    notCollapsed: logoPlugBraNotCollapsed,
  },
  eveletroposto: {
    collapsed: logoEveletroPostoCollapsed,
    notCollapsed: logoEveletroPostoNotCollapsed,
  },
  ciacharge: {
    collapsed: logoCiaChargeCollapsed,
    notCollapsed: logoCiaChargeNotCollapsed,
  },
  linkplusenergy: {
    collapsed: logoLinkPlusEnergyCollapsed,
    notCollapsed: logoLinkPlusEnergyNotCollapsed,
  },
  default: {
    collapsed: logoCollapsed,
    notCollapsed: logoNotCollapsed,
  },
};

export default function Dashboard({ match, history }) {
  const [collapsed, setCollapsed] = useState(false);
  const [companyID, setCompanyID] = useState("");
  const [parentCompanyID, setParentCompanyID] = useState("");
  const [username, setUsername] = useState("");
  const [foto, setFoto] = useState("");
  const [selectedKey, setSelectedKey] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);

  const { define, isLoaded, hasPermission } = useAccess();

  const [visibleModalAdmin, setVisibleModalAdmin] = useState(false);
  const [loadingSaveAdmin, setLoadingSaveAdmin] = useState(false);

  const { freire } = useFreire();

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const setFaviconTupi = () => {
    document.title = "Admin | Tupi";
    document.getElementById("favicon").href = "/favicon.png";
  };

  const setFavicon = () => {
    if (!companyID || !parentCompanyID) return;

    let favicon = "/favicon.png";
    let title = "";

    if (companyID === MOBILIZE || parentCompanyID === MOBILIZE) {
      title = "Admin | Mobilize";
    }

    if (companyID === SHELL || parentCompanyID === SHELL) {
      title = "Admin | Shell Recharge";
    }

    if (
      [BYD, BYD_TEST].includes(companyID) ||
      [BYD, BYD_TEST].includes(parentCompanyID)
    ) {
      title = "Admin | BYD";
    }

    if (companyID === BRS || parentCompanyID === BRS) {
      favicon = "/favicon-brs.png";
      title = "Admin | BR Super Carga";
    }

    if (title) {
      document.title = title;
      document.getElementById("favicon").href = favicon;
    } else {
      setFaviconTupi();
    }
  };

  const logout = async () => {
    try {
      await firebase.auth().signOut();
      await api.post("/auth/session-logout");
    } catch (error) {
      console.log("Error on logout:", error);
    } finally {
      history.push("/");
    }

    setFaviconTupi();
  };

  const saveAdmin = async (data, callbackSuccess) => {
    setLoadingSaveAdmin(true);

    try {
      await api.post(`/user/company`, data);

      notification.success({
        message: freire(texts.ALL_RIGHT),
        description: freire(texts.OPERATION_SUCCESSFULLY),
      });

      callbackSuccess();
      setVisibleModalAdmin(false);
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: error.response.error || freire(texts.SOMETHING_WRONG),
      });
    } finally {
      setLoadingSaveAdmin(false);
    }
  };

  useEffect(() => {
    const currentUser = firebase.auth().currentUser.toJSON();

    async function fetchData() {
      const result = await getCompanyCurrentUser();
      if (result) {
        setCompanyID(result.id);
        setParentCompanyID(result.parentCompanyID);
      }

      const permissions = await getUserPermissions();
      define({ permissions });
    }

    if (currentUser) {
      if (currentUser.displayName) {
        setUsername(currentUser.displayName);
      } else {
        setUsername(currentUser.email);
      }

      if (currentUser.photoURL) {
        setFoto(currentUser.photoURL);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOnUpdate(setFavicon, [companyID, parentCompanyID]);

  const { pathname } = useLocation();

  useEffect(() => {
    const split = pathname.split("/");
    const key = split[split.length - 1];

    setSelectedKey([key]);
    setOpenKeys([menuList[key]]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menu = (setLanguage) => (
    <Menu style={{ marginTop: 10 }}>
      {hasPermission("make-user-admin-dropdown") && (
        <Menu.Item onClick={() => setVisibleModalAdmin(true)}>
          {freire(texts.MAKE_USER_ADMIN)}
        </Menu.Item>
      )}
      <SubMenu title={freire(texts.LANGUAGE)}>
        {Object.entries(LANGUAGES).map(([languageCode, language]) => (
          <Menu.Item key={languageCode} onClick={setLanguage(languageCode)}>
            {freire(language.text)}
          </Menu.Item>
        ))}
      </SubMenu>
      <Menu.Item onClick={logout}>{freire(texts.EXIT)}</Menu.Item>
    </Menu>
  );

  const companyClassName =
    COMPANY_CLASS_NAMES[companyID] ||
    COMPANY_CLASS_NAMES[parentCompanyID] ||
    "tupi";

  const getLogo = () => {
    const logo = LOGOS[companyClassName] || LOGOS.default;
    return collapsed ? logo.collapsed : logo.notCollapsed;
  };

  const setLanguage = (languageCode) => () => {
    freire.setUserLanguage(languageCode);
  };

  if (!isLoaded) {
    return (
      <div
        style={{
          display: "flex",
          width: "100vw",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Icon type="loading" style={{ fontSize: "48px" }} />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Layout
        className={companyClassName}
        style={{ minHeight: "100vh", background: "red" }}
      >
        <Sider theme="dark" trigger={null} collapsible collapsed={collapsed}>
          <div className="logo">
            {hasPermission("menu-dashboard") ? (
              <Link to={"/dashboard"}>
                <img className="img-logo" alt="Logo" src={getLogo()} />
              </Link>
            ) : (
              <img className="img-logo" alt="Logo" src={getLogo()} />
            )}
          </div>
          <Menu
            openKeys={openKeys}
            selectedKeys={selectedKey}
            onOpenChange={(keys) => setOpenKeys(keys)}
            onSelect={(params) => setSelectedKey([params.key])}
            mode="inline"
            theme="dark"
          >
            {MenuConfig(match.url).map((item) => {
              if (hasPermission(item.permission)) {
                if (item.type === "submenu") {
                  return (
                    <SubMenu
                      key={item.key}
                      title={
                        <span>
                          <Icon type={item.icon} />
                          <span>{freire(texts[item.title])}</span>
                        </span>
                      }
                    >
                      {item.submenus.map((submenu) => {
                        if (hasPermission(submenu.permission)) {
                          if (submenu.redirect) {
                            return (
                              <a
                                className="redirect-link"
                                target="_blank"
                                href={submenu.redirect}
                                rel="noopener noreferrer"
                              >
                                <span>{freire(texts[submenu.title])}</span>
                                <span className="tag">{submenu.tag}</span>
                              </a>
                            );
                          }
                          return (
                            <Menu.Item key={submenu.key}>
                              <Link to={submenu.linkTo}>
                                <Icon type={submenu.icon} />
                                <span>{freire(texts[submenu.title])}</span>
                              </Link>
                            </Menu.Item>
                          );
                        }

                        return null;
                      })}
                    </SubMenu>
                  );
                }

                if (item.type === "menu") {
                  return (
                    <Menu.Item key={item.key}>
                      <Link to={item.linkTo}>
                        <Icon type={item.icon} />
                        <span>{freire(texts[item.title])}</span>
                      </Link>
                    </Menu.Item>
                  );
                }
              }

              return null;
            })}
          </Menu>
        </Sider>
        <Layout>
          <Header
            style={{
              background: "#fff",
              padding: 0,
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Icon
              className="trigger"
              type={collapsed ? "menu-unfold" : "menu-fold"}
              onClick={toggle}
            />

            {process.env.REACT_APP_SHOW_ENV_NAME === "true" && (
              <span
                style={{
                  position: "absolute",
                  top: -2,
                  marginLeft: 10,
                  color: "red",
                  fontWeight: "bold",
                  fontSize: 30,
                }}
              >
                {process.env.REACT_APP_ENV_NAME.toUpperCase()}
              </span>
            )}
            <div className="perfil">
              <Dropdown overlay={menu(setLanguage)}>
                <span style={{ color: "#5c00f0" }}>
                  {foto ? <Avatar src={foto} /> : <Avatar icon="user" />}
                  <span style={{ marginLeft: 8 }}>
                    {username || freire(texts.USER)}
                  </span>
                </span>
              </Dropdown>
            </div>
          </Header>
          <Content>
            {RoutesDashboardConfig(match.url).map((route) => (
              <Show key={route.path + route.permission} when={route.permission}>
                <Route
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              </Show>
            ))}
          </Content>
        </Layout>
        <ModalMakeAdmin
          visible={visibleModalAdmin}
          loading={loadingSaveAdmin}
          onClose={() => setVisibleModalAdmin(false)}
          onSave={saveAdmin}
        />
      </Layout>
    </BrowserRouter>
  );
}
