import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Form, Icon, Input, Button, Card, Row, Col, notification } from "antd";
import firebase from "../../services/firebaseConfig";
import { useFreire } from "../../utils/freireContext";
import * as texts from "./locales";
import { getUserAdminData } from "../../services/adminData";

const { LOCAL } = firebase.auth.Auth.Persistence;

const Login = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { freire } = useFreire();

  const handlerLogin = async (event) => {
    event.preventDefault();

    if (email && password) {
      setLoading(true);

      try {
        await firebase.auth().setPersistence(LOCAL);

        await firebase.auth().signInWithEmailAndPassword(email, password);

        const tokenInfo = await firebase
          .auth()
          .currentUser.getIdTokenResult(true);

        const adminData = await getUserAdminData(tokenInfo.claims.email);

        if (tokenInfo && adminData?.is_admin) {
          notification.success({
            message: freire(texts.ALL_RIGHT),
            description: freire(texts.LOGIN_DONE),
          });

          history.push("/dashboard/connected_stations");
        } else {
          notification.error({
            message: "Ops...",
            description: freire(texts.ONLY_ADMIN_CAN),
          });
        }

        setLoading(false);
      } catch (error) {
        console.log(error);

        notification.error({
          message: freire(texts.WENT_WONG),
          description: freire(texts.CHECK_AUTH),
        });

        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <Col xs={20} sm={16} md={12} lg={8} xl={5}>
          <Card
            headStyle={{
              textAlign: "center",
              backgroundColor: "#001529",
              color: "white",
            }}
            title={freire(texts.MAKE_LOGIN)}
          >
            <Form onSubmit={handlerLogin}>
              <Form.Item>
                <Input
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  size="large"
                  type="email"
                  placeholder={freire(texts.EMAIL)}
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  size="large"
                  type="password"
                  placeholder={freire(texts.PASSWORD)}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  onClick={handlerLogin}
                  loading={loading}
                  style={{ width: "100%", backgroundColor: "#001529" }}
                  type="primary"
                >
                  {freire(texts.ENTER)}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(Login);
