export const BRAND =
  process.env.REACT_APP_TUPI_COMPANY_ID || "5fcf83cb29d326670e91bf90";

export const NEO = "600197c5f950233332be931b";
export const NEOCHARGE = "627573bdfa4c1c18db6395f6";
export const MELI_MOOCA = "61562d6855fe750ac2fec423";

export const EFFIX = "610172319e61c5679e296aa7";

export const SHELL =
  process.env.REACT_APP_SHELL_COMPANY_ID || "6276c8ea2794d940d2cd2426";

export const MOBILIZE = "627437b7fa4c1c18db453cd7";

export const BYD = "66217edeedb2c20009cb244d";
export const BYD_TEST = "65d8ef81d524e60008380924";

export const BRS = "66fadfcdb6c1c7001e6a0eee";

export const VOLTZ = "67a3c7506b3bf8001fb08a07";

export const ION = "67ac9fcec848c5001f0051cc";

export const ANDRADESALES = "67d04a019543aa001fa9398a";

export const CHARGERPRO = "67d1ced5df0c65001fe95cf9";

export const ELESOL = "67d1cf2edf0c65001fe97bca";

export const PLUGBRA = "67daffc293e234001fe6f906";

export const LINKPLUSENERGY = "67ec57099cff68001fb0853d";

export const EVELETROPOSTO = "67c20651e11a26001f333413";

export const CIACHARGE = "661fcf87f45ea3000acf3d6d";

export const TEMP_PHB = "65dcde20ca5442000830540d";

export const TEMP_CIA_CHARGE = "661fcf87f45ea3000acf3d6d";

export const TEMP_ENERGISA = "63dd0b1374a71959c820942c";
