import React, { Component } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import firebase from "./services/firebaseConfig";
import api from "./services/api";
import { getUserAdminData } from "./services/adminData";

class PrivateRoute extends Component {
  state = {
    haveAcces: false,
    loaded: false,
  };

  componentDidMount() {
    this.checkAcces();
  }

  componentWillUnmount() {
    if (this.unsubscribeIdTokenChange) this.unsubscribeIdTokenChange();
    if (this.unsubscribeAuthStateChange) this.unsubscribeAuthStateChange();
  }

  postIdTokenToSessionLogin = async (idToken, csrfToken) => {
    try {
      await api.post("/auth/session-login", { idToken, csrfToken });
    } catch (error) {
      console.error("Error on postIdTokenToSessionLogin:", error);
    }
  };

  checkAcces = () => {
    const { history } = this.props;

    this.unsubscribeIdTokenChange = firebase
      .auth()
      .onIdTokenChanged(async (user) => {
        if (user) {
          const userTokenInfo = await firebase
            .auth()
            .currentUser?.getIdTokenResult();

          const adminData = await getUserAdminData(userTokenInfo?.claims?.email);

          if (userTokenInfo && adminData.is_admin) {
            const idToken = userTokenInfo.token;

            const csrfToken = Cookies.get("csrfToken");

            await this.postIdTokenToSessionLogin(idToken, csrfToken);
          }
        }
      });

    this.unsubscribeAuthStateChange = firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          this.setState({ haveAcces: true, loaded: true });
        } else {
          history.push("/");
        }
      });
  };

  render() {
    const { component: Component, ...rest } = this.props;
    const { loaded, haveAcces } = this.state;
    if (!loaded) return null;
    return (
      <Route
        {...rest}
        render={(props) => {
          return haveAcces ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }}
      />
    );
  }
}

export default withRouter(PrivateRoute);
