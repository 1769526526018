import api from "./api";

export const getUserAdminData = async (email) => {
  try {
    const { data } = await api.get(`/admin/users/${email}/admin-data`);

    return data;
  } catch (error) {
    return null;
  }
};
