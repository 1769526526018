import { Fragment } from "react";
import CopyText from "../../../../../components/CopyText";
import DropdownActions from "../../../../../components/DropdownActions";
import * as texts from "../../../locales";
import CampaignBalance from "../../../../../components/CampaignBalance";
import { getCouponStatusText } from "../../../utils";
import { COUPON_STATUS, COUPON_TYPE } from "../../../../../services/utils";
import { autoLinkCouponType } from "../../../NewCoupons/Forms/EligibleUsers";

const { CREDIT } = COUPON_TYPE;

const getColumns = (freire, campaignResume, actions) => ({
  // Ambos
  cod: {
    title: freire(texts.COD),
    dataIndex: "cod",
    key: "cod",
    render: (cod, data) => (
      <CopyText
        text={cod}
        textStyle={{ fontWeight: 600 }}
        disabled={[COUPON_STATUS.EXPIRED, COUPON_STATUS.INACTIVE].includes(
          data.status
        )}
      />
    ),
  },
  ...(campaignResume?.autoLinkCouponType && {
    autoLinkCouponType: {
      title:
        campaignResume.autoLinkCouponType === autoLinkCouponType.CHASSI
          ? freire(texts.CHASSI)
          : freire(texts.EMAIL),
      dataIndex: "cod",
      key: "autoLinkCouponCode",
      render: (cod) => {
        const autoLinkCouponCodeWithoutCampaignHash =
          campaignResume.autoLinkCouponType === autoLinkCouponType.EMAIL
            ? cod
                .split("@")
                .reduce(
                  (acc, part, index) =>
                    index === 0 ? part : `${acc}@${part.split("-")[0]}`,
                  ""
                )
            : cod.split("-")[0];
        return (
          <CopyText
            text={
              campaignResume.autoLinkCouponType === autoLinkCouponType.EMAIL
                ? autoLinkCouponCodeWithoutCampaignHash.toLowerCase()
                : autoLinkCouponCodeWithoutCampaignHash
            }
            textStyle={{ fontWeight: 600 }}
          />
        );
      },
    },
  }),
  status: {
    title: freire(texts.STATUS),
    dataIndex: "status",
    key: "status",
    render: (status) => {
      const couponStatus = getCouponStatusText(status);

      if (couponStatus) {
        return (
          <span style={{ color: couponStatus.color }}>
            {freire(couponStatus.text)}
          </span>
        );
      }

      return "----";
    },
  },
  user: {
    title: freire(texts.USER),
    dataIndex: "user",
    key: "user",
    render: (user) =>
      user ? (
        <Fragment>
          {user.displayName && (
            <span style={{ fontWeight: 600, color: "#565559" }}>
              {user.displayName} <br />
            </span>
          )}
          <span style={{ color: "#747277" }}>{user.email || ""}</span>
        </Fragment>
      ) : (
        "----"
      ),
  },
  actions: {
    title: freire(texts.ACTIONS),
    dataIndex: "actions",
    key: "actions",
    align: "center",
    render: (_, coupon) => {
      const hasUser = coupon.user !== "";
      const hasUnlimitedUsage = coupon.usageLimit === -1;
      const isCreditBenefit = coupon.campaignDetails?.benefit?.type === CREDIT;
      const isExpiredCoupon = coupon.status === COUPON_STATUS.EXPIRED;

      const canRemoveUser = hasUser && (hasUnlimitedUsage || isCreditBenefit);
      const canAssignUser = !hasUser && !isExpiredCoupon;
      const canExpire = !isExpiredCoupon;

      return (
        <DropdownActions
          actions={[
            canRemoveUser && {
              permission: "remove-user-cupons",
              icon: "user-delete",
              title: freire(texts.REMOVE_USER_COUPON),
              onClick: () => actions.onRemoveUser(coupon),
            },
            canAssignUser && {
              permission: "assign-user-coupons",
              icon: "user-add",
              title: freire(texts.ADD_USER_TO_FREE_COUPON),
              onClick: () => actions.onCouponUser(coupon),
            },
            canExpire && {
              permission: "disable-cupons",
              icon: "minus-circle",
              title: freire(texts.DISABLE_COUPON),
              onClick: () => actions.onDisable(coupon),
            },
          ]}
        />
      );
    },
  },

  // Simple
  usageLimit: {
    title: freire(texts.LIMIT_USE),
    dataIndex: "usageLimit",
    key: "usageLimit",
    align: "center",
    render: (usageLimit) => {
      if (campaignResume?.isFirstChargeCoupon)
        return freire(texts.FIRST_CHARGE);

      if (usageLimit === -1) return freire(texts.NO_LIMIT);

      return usageLimit;
    },
  },
  usageQty: {
    title: freire(texts.QTD_USED),
    dataIndex: "usageQty",
    key: "usageQty",
    align: "center",
  },

  // Credit
  balance: {
    title: freire(texts.BENEFIT_USED),
    dataIndex: "balance",
    key: "balance",
    align: "center",
    render: (_, coupon) => (
      <CampaignBalance
        textLeft={freire(texts.CONSUMED_VALUE)}
        textRight={freire(texts.AMOUNT_REMAINING)}
        currency={campaignResume?.companyCurrency}
        total={coupon.campaignDetails.benefit.value}
        remaining={coupon.balance}
        valueType={coupon.campaignDetails.benefit.valueType}
      />
    ),
  },
});

const CouponsColumns = { getColumns };

export default CouponsColumns;
